import { useCollectionListing } from 'hooks/use-collection-listing';
import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTable } from 'components/common/table/hooks';

export const withPayment = (WrappedComponent) => {
  return (props) => {
    const { setInputs, data } = props;
    const { paymentData } = usePaymentData();
    const [record, setRecord] = useState({});

    const [payment, setPayment] = useState({});
    const [description, setDescription] = useState(data?.payment_term_description);

    const options = useMemo(() => {
      if (!paymentData) return [];
      return paymentData.map((row) => ({
        _id: row?._id,
        label: row?.code
      }));
    }, [paymentData]);

    useEffect(() => {
      const input = reduce(
        record,
        (prev, curr, key) => {
          if (curr.touched) {
            prev[key] = curr;
          }
          return prev;
        },
        {}
      );

      if (!isEmpty(input)) {
        setInputs((inputs) => ({
          ...inputs,
          ...input
        }));
      }
    }, [JSON.stringify(record)]);

    useEffect(() => {
      if (payment?.payment_term_code?.touched) {
        setInputs((inputs) => ({
          ...inputs,
          ...payment
        }));
        const obj = find(paymentData, { _id: payment?.payment_term_code?.value });
        if (obj) {
          const { description } = obj;
          setRecord({
            payment_term_description: {
              value: description,
              error: null,
              touched: true
            }
          });
          setDescription(description);
        }
      }
    }, [JSON.stringify(payment), JSON.stringify(paymentData)]);

    return (
      <WrappedComponent
        {...props}
        options={options}
        setRecord={setRecord}
        description={description}
        record={record}
        setPayment={setPayment}
      />
    );
  };
};

export const usePaymentData = () => {
  const { data } = useTable({
    url: '/api/collection/payment/listing',
    paramsPreset: {
      pageSize: 10000
    }
  });

  return {
    paymentData: data
  };
};
