import React from 'react';
import { useTable } from 'components/common/table/hooks';
import Td from './components/td';
import { redirect } from 'lib/location';

export const useEntityLogs = (config) => {
  const { collection, _id } = config;

  const { data, isLoading, Component, controllers } = useTable({
    collection: 'log',
    url: _id ? `/api/collection/log/document-logs/${_id}` : null,
    setting: {
      fieldsToDisplay: ['action', 'fields', 'created_at', 'created_by'],
      schema: [
        {
          field: 'action',
          title: '操作'
        },
        {
          field: 'fields',
          title: '欄位'
        },
        {
          field: 'created_at',
          title: '操作時間'
        },
        {
          field: 'created_by',
          title: '操作者'
        }
      ]
    },
    Components: {
      Td,
      Filter: null,
      NewEntry: null
    },
    componentProps: {
      Td: {
        _id
      },
      destination: {
        onClick: (row) => {
          redirect(`/log/profile/${row?._id}`, { newTab: true });
        }
      }
    }
  });

  return { data, isLoading, Component, refetch: controllers.refetch };
};
