import { isBoolean } from 'lodash';

import Boolean from './boolean';
import Money from './money';
import Numeral from './numeral';
import Date from './date';
import Tags from './tags';

export const getDataDisplayComponent = (props) => {
  const { value, config } = props;
  const { is_boolean, is_date, is_integer, is_money, is_number, is_array_of_string } = config;

  if (is_boolean || isBoolean(value)) {
    return <Boolean {...props} />;
  } else if (is_money) {
    return <Money {...props} />;
  } else if (is_number || is_integer) {
    return <Numeral {...props} />;
  } else if (is_date) {
    return <Date {...props} />;
  } else if (is_array_of_string) {
    return <Tags {...props} />;
  }

  return (
    <p className="text-wrap">
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </p>
  );
};
