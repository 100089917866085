import React from 'react';
import { Divider } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';
import Currency from './components/currency';
import Contact from './components/contact';

function General(props) {
  const { data, schema, setInputs, mode, body } = props;

  const inputEditProps = {
    mode,
    schema,
    setInputs
  };

  return (
    <Section title="General">
      <div className="flex flex-col gap-2">
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="code" value={data?.code} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="short_name" value={data?.short_name} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="chin_name" value={data?.chin_name} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="eng_name" value={data?.eng_name} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="chin_address" value={data?.chin_address} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="eng_address" value={data?.eng_address} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="email" value={data?.email} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="is_approve" value={data?.is_approve} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <Currency {...inputEditProps} field="currency" value={data?.currency} className="basis-1/3" />
          <InputEdit {...inputEditProps} field="payment_terms" value={data?.payment_terms} className="basis-1/3" />
          {/* <InputEdit {...inputEditProps} field="is_active" value={data?.is_active} className="basis-1/3" /> */}
        </div>
        <div className="my-2">
          <Divider />
        </div>
        <div className="flex w-full">
          <Contact {...inputEditProps} value={data?.contacts} body={body} />
        </div>
      </div>
    </Section>
  );
}

export default General;
