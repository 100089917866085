import React from 'react';
import { useProfile } from './hooks';
import { withProfilePage } from 'hooks/with-profile-page';

import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';
import { useTabs } from 'components/common/tabs/hooks';
import Metadata from 'components/common/metadata';
import ContractIssue from './tabs/issue';
import ContractSubcontractor from './tabs/subcontractor';

function ContractProfilePage(pageProps) {
  const { data, metadata, schema, setInputs, EntityLogs, userDataIsLoading, userData, contractUserData } = useProfile({
    pageProps
  });

  const { _id, isEditMode, mode } = pageProps;

  const { Component, tabProps } = useTabs();
  const { Tabs, Tab } = Component;

  const inputEditProps = {
    mode,
    schema,
    setInputs
  };

  return (
    <div className="flex flex-col gap-4">
      <Metadata metadata={metadata} />
      <Tabs {...tabProps}>
        <Tab title="General" id="info">
          <Section title="General">
            <div className="flex flex-col gap-2">
              <div className="flex w-full gap-4">
                <InputEdit {...inputEditProps} field="code" value={data?.code} className="basis-1/2" />
                <InputEdit {...inputEditProps} field="title" value={data?.title} className="basis-1/2" />
              </div>
              <div className="flex w-full gap-4">
                <InputEdit {...inputEditProps} field="full_name" value={data?.full_name} className="w-full" />
              </div>
              <div className="flex w-full gap-4">
                <InputEdit {...inputEditProps} field="contact" value={data?.contact} className="basis-1/2" />
                <InputEdit
                  {...inputEditProps}
                  field="contact_number"
                  value={data?.contact_number}
                  className="basis-1/2"
                />
              </div>
              <div className="flex w-full gap-4">
                <InputEdit {...inputEditProps} field="start_date" value={data?.start_date} className="basis-1/2" />
                <InputEdit {...inputEditProps} field="end_date" value={data?.end_date} className="basis-1/2" />
              </div>
            </div>
          </Section>
        </Tab>
        <Tab title="Issue" id="issue">
          <Section title="Issue">
            <ContractIssue
              setInputs={setInputs}
              contractUserData={contractUserData}
              userData={userData}
              userDataIsLoading={userDataIsLoading}
            />
          </Section>
        </Tab>
        {/* <Tab title="判頭" id="subcontractor">
          <Section title="判頭">
            <ContractSubcontractor />
          </Section>
        </Tab> */}
        {/* {isEditMode ? (
          <Tab title="Logs" id="logs">
            <Section title="Logs">{EntityLogs}</Section>
          </Tab>
        ) : null} */}
      </Tabs>
    </div>
  );
}

export default withProfilePage(ContractProfilePage);
