import React from 'react';
import InputEdit from 'components/common/input-edit';
import { useContract, withJobType } from './hooks';
import ImpendingNumber from './components/impending-number';
import EoNumber from './components/eo-number';
import WorkorderNumber from './components/worksorder-number';
import Reason from './components/reason';

function JobType(props) {
  const { body, data, defaultValue, field, schema, title, options, readonly, setInputs, jobType } = props;
  return (
    <>
      <InputEdit {...props} value={data?.[field]} className="basis-1/3" />

      {jobType === 'eo' ? (
        <EoNumber schema={schema} setInputs={setInputs} value={data?.eo_number} />
      ) : jobType === 'impending_no' ? (
        <ImpendingNumber schema={schema} setInputs={setInputs} value={data?.impending_number} />
      ) : jobType === 'worksorder' || jobType === 'm_and_m' ? (
        <WorkorderNumber
          schema={schema}
          setInputs={setInputs}
          value={data?.wo_number}
          body={body}
          data={data}
          jobType={jobType}
        />
      ) : (
        <div className="basis-1/3" />
      )}

      {jobType === 'm_and_m' || jobType === 'eo' ? (
        <Reason schema={schema} setInputs={setInputs} value={data?.reason} />
      ) : (
        <div className="basis-1/3" />
      )}
    </>
  );
}

export default withJobType(JobType);
