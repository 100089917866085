import { useFetch } from 'lib/fetch';
import { isBoolean } from 'lodash';
import { isEmpty, isNull, isUndefined, reduce } from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
export const useDataEditSave = ({ body, canSave, mode, url, refetch, success }) => {
  const { fetch, result: saveResult, status } = useFetch();
  const method = mode === 'edit' ? 'PUT' : 'POST';
  const message = mode === 'edit' ? '成功更新' : '成功建立';

  const save = useCallback(() => {
    console.log(`User pressed save button`, body);
    if (canSave && url) {
      fetch(
        method,
        url,
        {
          params: body
        },
        {
          message
        }
      );
    }
  }, [body, canSave, url, fetch, message, method]);

  useEffect(() => {
    if (saveResult && !isEmpty(saveResult)) {
      if (success) {
        success(saveResult);
      } else {
        refetch();
      }
    }
  }, [saveResult, success]);

  return { save, saveResult };
};

export const useDataEdit = ({ mode, value, config, url, refetch, success }) => {
  const [inputs, setInputs] = useState({});
  const { data, metadata } = value || {};
  const { editable, fieldsToDisplay, schema } = config;

  const isEdit = mode === 'edit';
  const isCopy = mode === 'copy' || mode === 'amendment';
  const isNew = mode === 'new';

  const hasError = useMemo(() => {
    return reduce(
      inputs,
      (v, value) => {
        if (value?.error) {
          v = true;
        }
        return v;
      },
      false
    );
  }, [inputs]);

  const body = useMemo(() => {
    return reduce(
      inputs,
      (v, value, key) => {
        const newValue = value?.value;
        const oldValue = data?.[key];
        const touched = value?.touched;

        if (!touched && isEdit) {
          return v;
        }

        const isNewValueEmpty = isNull(newValue) || isUndefined(newValue) || newValue === '' || isEmpty(newValue);
        const isOldValueEmpty = isNull(oldValue) || isUndefined(oldValue) || oldValue === '' || isEmpty(oldValue);

        if (
          (newValue !== oldValue && !(isNewValueEmpty && isOldValueEmpty)) ||
          (isBoolean(newValue) && !!newValue !== !!oldValue) ||
          newValue instanceof File ||
          key.startsWith('$edit.')
        ) {
          v[key] = value?.value;
        }
        return v;
      },
      {}
    );
  }, [data, inputs, isEdit]);

  const canSave = useMemo(() => {
    if (hasError) {
      return false;
    }
    if (isCopy) {
      return true;
    }
    return !isEmpty(body);
  }, [hasError, body, isCopy]);

  const { save } = useDataEditSave({
    body,
    canSave,
    mode,
    url,
    refetch: () => {
      refetch();
      setInputs({});
    },
    success
  });

  useEffect(() => {
    console.log(inputs, hasError, 'body: ', body);
  }, [inputs, hasError, body]);

  const setting = {
    mode,
    isCopy,
    isEdit,
    isNew
  };

  const controller = {
    setInputs
  };

  return {
    data,
    metadata,
    save,
    canSave,
    hasError,
    controller,
    inputs,
    body,
    config: {
      ...config,
      ...setting
    }
  };
};
