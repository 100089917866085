import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/pr/index';
import ProfilePage from 'pages/pr/profile';
import AwaitQuoteListingPage from 'pages/pr/await-quote-listing';
import AwaitQuoteVerifyListingPage from 'pages/pr/await-quote-verify-listing';
import AwaitApproveListPage from 'pages/pr/await-approve-listing';
import AwaitMyApproveListPage from 'pages/pr/await-my-approve-listing';
import AwaitWONumberListPage from 'pages/pr/await-wo-number-listing';
import StatusPage from 'pages/pr/status';

const collection = 'pr';
const menuItem = ['PR', 'PR1'];

const routes = [
  <Route path={`/${collection}`} element={<IndexPage menuItem={menuItem} title="申請" collection={collection} />} />,
  <Route
    path={`/${collection}/await-quote`}
    element={<AwaitQuoteListingPage menuItem={['PR', 'PRAQ']} title="等待報價" collection={collection} />}
  />,
  <Route
    path={`/${collection}/await-quote-verify`}
    element={<AwaitQuoteVerifyListingPage menuItem={['PR', 'PRAQV']} title="待批報價" collection={collection} />}
  />,
  <Route
    path={`/${collection}/await-my-approve`}
    element={<AwaitMyApproveListPage menuItem={['PR', 'PRMAA']} title="待批採購單" collection={collection} />}
  />,
  <Route
    path={`/${collection}/await-approve`}
    element={<AwaitApproveListPage menuItem={['PR', 'PRAA']} title="批准狀況" collection={collection} />}
  />,
  <Route
    path={`/${collection}/await-wo-number`}
    element={<AwaitWONumberListPage menuItem={['PR', 'PRAWO']} title="待補WO No." collection={collection} />}
  />,
  <Route
    path={`/${collection}/status`}
    element={<StatusPage menuItem={['PR', 'PR_STATUS']} title="採購申請 統計" collection={collection} />}
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="採購申請 管理" collection={collection} editMode="edit" />}
  />,
  <Route
    path={`/${collection}/copy/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增 採購申請" collection={collection} editMode="copy" />}
  />,
  <Route
    path={`/${collection}/amendment/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增 採購申請" collection={collection} editMode="amendment" />}
  />,
  <Route
    path={`/${collection}/new`}
    element={<ProfilePage menuItem={menuItem} title="新增 採購申請" collection={collection} editMode="new" />}
  />
];

export default routes;
