import { useMemo } from 'react';
import { isArray, includes, uniq, map } from 'lodash';

export const withFileDetail = (WrappedComponent) => {
  return (props) => {
    const { value, newFiles, setFiles, setRemovedFiles, removedFiles } = props;

    const files = useMemo(() => {
      const arr = [];
      if (value) {
        if (isArray(value)) {
          arr.push(...value);
        } else {
          arr.push(value);
        }
      }

      if (newFiles) {
        if (isArray(newFiles)) {
          arr.push(...map(newFiles, (file) => ({ filename: file.name })));
        } else {
          arr.push({
            filename: newFiles?.name
          });
        }
      }

      return arr;
    }, [value, JSON.stringify(newFiles), removedFiles]);

    const removeFile = (params) => {
      const { _id, filename } = params;

      if (_id) {
        setRemovedFiles((v) => uniq([...v, _id]));
      } else if (filename) {
        setFiles((v) => {
          return v.filter((file) => file.name !== filename);
        });
      }
    };
    return <WrappedComponent {...props} files={files} removeFile={removeFile} setRemovedFiles={setRemovedFiles} />;
  };
};
