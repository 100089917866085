import React from 'react';
import { parseISODateString } from 'lib/date';
import { compact } from 'lodash';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';
import { host } from 'lib/config';

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: 12
  },
  signatureView: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    fontSize: 12
  },
  signature: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // alignItems: 'flex-end',
    marginTop: 20,
    width: 250
  },
  signatureTitle: {
    paddingRight: 10
  },
  signatureImage: {
    width: '50%',
    objectFit: 'contain',
    marginHorizontal: '1%',
    objectPosition: '100%'
  },
  signatureBox: {
    width: 140,
    marginTop: 10,
    marginLeft: 10
  },
  signatureBoxTable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    // borderBottom: '1px solid #000',
    fontSize: 10
  },
  signatureBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    // borderTop: '1px solid #000',
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center'
  },
  signatureBoxCell: {
    // borderRight: '1px solid #000',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  signatureBoxCol1: {
    // borderLeft: '1px solid #000'
    // borderTop: '1px solid #000'
  },
  signatureBoxEmpty: {
    height: 80
  },
  signatureBoxDate: {
    // paddingTop: 60
    borderTop: '1px solid #000'
  }
});

function PrSignature(props) {
  const { data } = props;
  const filename = data?.signature?.filename;

  return (
    <View style={[styles.signatureView]}>
      {/* <View style={styles.signature} wrap={false}>
        {filename && (
          <>
            <Text style={styles.signatureTitle}>簽名 :</Text>
            <Image style={styles.signatureImage} src={`${host}/api/collection/file/file/${filename}`} />
          </>
        )}
      </View> */}

      {data?.pr__subcontractors?.map((d, index) => {
        return (
          <View style={styles.signatureBox} wrap={false} key={index}>
            <View style={styles.signatureBoxTable}>
              <View style={[styles.signatureBoxRow]}>
                <View style={[styles.signatureBoxCell, styles.signatureBoxCol1]}>
                  <Text style={{ flex: 1 }}>{d?.subcontractor?.chin_name} 簽名:</Text>
                </View>
              </View>
              <View style={styles.signatureBoxRow} wrap={false}>
                <View style={[styles.signatureBoxCell, styles.signatureBoxCol1, styles.signatureBoxEmpty]}></View>
              </View>
              <View style={styles.signatureBoxRow} wrap={false}>
                <View style={[styles.signatureBoxCell, styles.signatureBoxCol1]}>
                  <Text style={styles.signatureBoxDate}>日期:</Text>
                </View>
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
}

export default PrSignature;
