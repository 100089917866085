import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withCurrency } from './hooks';

function Currency(props) {
  const { options } = props;
  return <InputEdit {...props} overrideSchema={{ selectOptions: options }} />;
}

export default withCurrency(Currency);
