import { Search } from '@mui/icons-material';
import { useTable } from 'components/common/table/hooks';
import To from 'components/common/to';
import { useFetch } from 'lib/fetch';
import { filter, find, isEmpty, isNumber, isNaN, map, reduce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isPriceFormat } from 'lib/price';

export const withPoItems = (WrappedComponent) => (props) => {
  const { field, schema, setInputs, data, readonly } = props;

  const [hideOptions, setHideOptions] = useState(true);
  const [selected, setSelected] = useState({
    items: [],
    contract: data?.contract,
    supplier: data?.supplier
  });
  const { itemsToSelect } = useReadyPoItems({ selected, hideOptions, readonly });

  const totalPrice = useMemo(() => {
    if (data?.total_price) return data?.total_price;
    if (!isEmpty(selected.items)) {
      return selected.items.reduce((acc, item) => acc + (parseFloat(item.subtotal) || 0), 0).toString();
    }
    return '';
  }, [selected.items, data?.total_price]);

  const poItems = useMemo(() => {
    return data?.poItems.map((item) => ({
      ...item,
      key: item?._id
    }));
  }, [data?.poItems]);

  const getCheckboxProps = useCallback(
    (record) => ({
      name: `${record._id}_${record.columnIndex}`,
      disabled:
        (selected.contract ? record.contract._id !== selected.contract._id : false) ||
        (selected.supplier ? record.supplier._id !== selected.supplier._id : false) ||
        readonly
    }),
    [selected.contract, selected.supplier, readonly]
  );

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const [firstRow] = selectedRows;

    if (firstRow) {
      setSelected({
        items: selectedRows,
        contract: firstRow.contract,
        supplier: firstRow.supplier
      });
    } else {
      setSelected({
        items: [],
        contract: null,
        supplier: null
      });
    }
  };

  const push = useMemo(() => {
    if (readonly) return {};
    return reduce(
      selected.items,
      (prev, curr, key) => {
        prev[`$push.po__items[${key}]`] = {
          value: curr?._id,
          touched: true
        };

        return prev;
      },
      {}
    );
  }, [selected.items, readonly]);

  const items = useMemo(() => {
    if (!readonly) return itemsToSelect;
    return poItems || [];
  }, [itemsToSelect, poItems, readonly]);

  useEffect(() => {
    if (poItems && !isEmpty(poItems)) {
      setSelected((v) => ({
        ...v,
        items: poItems,
        contract: data.contract,
        supplier: data.supplier
      }));
    }
  }, [poItems, data?.contract, data?.supplier]);

  useEffect(() => {
    setInputs((v) => {
      return {
        ...reduce(
          v,
          (prev, curr, key) => {
            if (!key.startsWith('$push.po__items')) {
              prev[key] = curr;
            }
            return prev;
          },
          {}
        ),
        contract: {
          value: selected?.contract?._id,
          isTouched: true
        },
        supplier: {
          value: selected?.supplier?._id,
          isTouched: true
        },
        total_price: {
          value: totalPrice,
          isTouched: isNumber(totalPrice) && !isNaN(totalPrice)
        },
        ...push
      };
    });
  }, [selected, setInputs, push, totalPrice]);

  return (
    <WrappedComponent
      {...props}
      items={items}
      columns={columns}
      onSelectChange={onSelectChange}
      getCheckboxProps={getCheckboxProps}
      setHideOptions={setHideOptions}
      hideOptions={hideOptions}
      selected={selected}
      totalPrice={totalPrice}
    />
  );
};

export const useReadyPoItems = (props) => {
  const { selected, hideOptions, readonly } = props;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (!readonly) {
      fetch('GET', '/api/collection/po-item/ready-for-po-items');
    }
  }, []);

  const itemsToSelect = useMemo(() => {
    const list_items = filter(
      map(result, (v) => ({ ...v, key: v._id })),
      (record) => {
        if (hideOptions) {
          return !(
            (selected.contract ? record.contract._id !== selected.contract._id : false) ||
            (selected.supplier ? record.supplier._id !== selected.supplier._id : false)
          );
        }
        return true;
      }
    );

    return filter(list_items, (item) => {
      return Object.keys(item.supplier).length > 0;
    });
  }, [result, selected, hideOptions]);

  return {
    itemsToSelect
  };
};

const columns = [
  {
    title: '物料',
    fixed: 'left',
    width: 100,
    dataIndex: ['item', 'label'],
    key: 'item',
    searchable: false,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.item.label.localeCompare(b.item.label),
    render: (_, item) => (
      <div className="flex items-center justify-between gap-1">
        <div className="">{item.item.label}</div>
        <To className="flex justify-center items-center" href={`/pr/profile/${item?.pr?._id}#items`} toNew>
          <Search sx={{ fontSize: 16 }} />
        </To>
      </div>
    )
  },
  {
    title: '申請表編號',
    width: 100,
    dataIndex: ['pr', 'label'],
    key: 'pr',
    searchable: false,
    sorter: (a, b) => {
      const labelA = a?.pr?.label || '';
      const labelB = b?.pr?.label || '';

      return labelA.localeCompare(labelB);
    }
  },
  {
    title: '開單人',
    width: 100,
    dataIndex: ['pr', 'created_by', 'nickname'],
    key: 'nickname',
    searchable: false,
    sorter: (a, b) => {
      const labelA = a?.pr?.created_by?.nickname || '';
      const labelB = b?.pr?.created_by?.nickname || '';

      return labelA.localeCompare(labelB);
    }
  },
  {
    title: '供應商',
    width: 100,
    dataIndex: ['supplier', 'label'],
    key: 'supplier',
    searchable: false,
    sorter: (a, b) => {
      const labelA = a?.supplier?.label || '';
      const labelB = b?.supplier?.label || '';

      return labelA.localeCompare(labelB);
    }
  },
  {
    title: '合約',
    width: 100,
    dataIndex: ['contract', 'label'],
    key: 'contract',
    searchable: false,
    sorter: (a, b) => {
      const labelA = a?.contract?.label || '';
      const labelB = b?.contract?.label || '';

      return labelA.localeCompare(labelB);
    }
  },
  {
    title: '數量',
    dataIndex: 'quantity',
    key: 'quantity',
    searchable: false
  },
  {
    title: '總價(HKD)',
    dataIndex: 'subtotal',
    key: 'subtotal',
    searchable: false,
    render: (_, item) => <div>{isPriceFormat(item.subtotal)}</div>
  },
  {
    title: '貨幣',
    dataIndex: 'currency',
    key: 'currency',
    searchable: false
  }
];
