import InputEdit from 'components/common/input-edit';
import React from 'react';
import { withPrice } from './hooks';

function Price(props) {
  const { value, setInputs, readonly } = props;
  return (
    <InputEdit
      field="price"
      value={value}
      className="flex w-full"
      setInputs={setInputs}
      schema={{ type: 'number', is_positive: true, input_variant: 'slim-text', readonly }}
    />
  );
}

export default withPrice(Price);
