import {
  compact,
  filter,
  isInteger,
  isNaN,
  isNumber,
  map,
  multiply,
  reduce,
  round,
  toInteger,
  toNumber,
  uniq
} from 'lodash';
import { useMemo } from 'react';

export const withPrItemsStats = (WrappedComponent) => {
  return (props) => {
    const { config, rows, data } = props;
    const { currencyData } = config || {};

    const length = rows.length;
    const price = useMemo(() => {
      return reduce(
        rows,
        (prev, curr) => {
          if (curr?.subtotal && data?.record_status >= 30) {
            prev += toNumber(curr.subtotal);
          } else {
            const quantity = toInteger(curr.quantity);
            const price = toNumber(curr.price);
            const currency = curr.currency;

            if (isInteger(quantity) && isNumber(price) && !isNaN(price)) {
              const count = multiply(quantity, price);
              if (!isNaN(count) && isNumber(count)) {
                if (currencyData?.[currency]) {
                  const rate = toNumber(currencyData[currency]);
                  if (isNumber(rate)) {
                    prev += count / rate;
                  }
                } else {
                  prev += count;
                }
              }
            }
          }
          return prev;
        },
        0
      );
    }, [rows, currencyData]);

    const suppliers = useMemo(() => {
      return compact(uniq(map(rows, 'supplier')))?.length;
    }, [rows]);

    return <WrappedComponent {...props} length={length} price={round(price, 2)} suppliers={suppliers} />;
  };
};
