import { atom, useAtom, useSetAtom } from 'jotai';
import { difference, filter, findKey, forEach, intersection, isEmpty, map, reduce, set } from 'lodash';
import { useAtomValue } from 'jotai';

import { useUserPagePermission } from 'hooks/use-user-page-permission';
import { items } from './setting';

export const sideMenuOpen = atom(true);
export const sideMenuItems = atom(items);
export const sideMenuActiveItem = atom(null);

export const useSideMenu = () => {
  const [_sideMenuOpen, setSideMenuOpen] = useAtom(sideMenuOpen);
  const [_sideMenuActiveItem, setSideMenuActiveItem] = useAtom(sideMenuActiveItem);
  const setSideMenuItems = useSetAtom(sideMenuItems);
  const hideSideMenuItems = (arr) => {
    setSideMenuItems((v) => {
      forEach(arr, ([parent, child]) => {
        const parentKey = findKey(v, { key: parent });
        if (parentKey > -1) {
          const childKey = findKey(v[parentKey]?.children, { key: child });
          if (childKey > -1) {
            set(v, `[${parentKey}].children[${childKey}].hidden`, true);
          }
        }
      });

      return v;
    });
  };
  return {
    open: _sideMenuOpen,
    activeItem: _sideMenuActiveItem,
    setSideMenuActiveItem: (items) => {
      setSideMenuActiveItem(items);
    },
    setSideMenuOpen: (open) => {
      setSideMenuOpen(open);
    },
    hideSideMenuItems
  };
};

export const withMenuItems = (WrappedComponent) => {
  return (props) => {
    const items = useAtomValue(sideMenuItems);
    const { permissions_read, is_admin } = useUserPagePermission();

    const itemsWithPermissions = reduce(
      items,
      (prev, item) => {
        if (permissions_read.includes('*') || permissions_read.includes(item?.key) || is_admin) {
          return [...prev, item];
        }
        const { children } = item;

        if (children && !isEmpty(children)) {
          const childrenWithPermissions = intersection(map(children, 'key'), permissions_read);

          if (!isEmpty(childrenWithPermissions)) {
            return [
              ...prev,
              {
                ...item,
                children: filter(children, ({ key }) => childrenWithPermissions.includes(key))
              }
            ];
          }
        }

        return prev;
      },
      []
    );

    return <WrappedComponent {...props} items={itemsWithPermissions} />;
  };
};
