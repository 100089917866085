import { usePrAmendment } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withAmendmentButton = (WrappedComponent) => {
  return (props) => {
    const { body, className, data } = props;
    const [open, setOpen] = useState(false);
    const { onSubmit, isLoading, result } = usePrAmendment({ _id: data?._id });

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const currentUser = useAtomValue(user);
    const currentUserId = currentUser?._id;

    useEffect(() => {
      if (result && result?._id) {
        redirect(`/pr/profile/${result._id}`);
      }
    }, [result, data]);

    if (
      data &&
      data?.record_status &&
      data?.record_status > 10 &&
      data?.record_status !== 90 &&
      data?.created_by?._id == currentUserId &&
      isEmpty(body)
    ) {
      return (
        <WrappedComponent
          {...props}
          open={open}
          onSubmit={onSubmit}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          submitted={isLoading || result}
        />
      );
    }
    return null;
  };
};
