import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import { Select } from 'antd';
import { ClickAwayListener } from '@mui/base';
import { isEmpty } from 'lodash';
import React from 'react';
import { AddCircle, AddCircleOutline, Check, Search } from '@mui/icons-material';
import { usePopup } from './hooks';
import To from 'components/common/to';
import CsrTable from 'components/common/csr-table';

function Popup(props) {
  const { config, controllers } = props;
  const {
    selected,
    clearSelected,
    pushSelected,
    pullSelected,
    confirm,
    primaryCategoryItems,
    setSelectedPrimaryItem,
    secondaryCategoryItems,
    setSelectedSecondaryItem,
    rows
  } = usePopup(props);

  const { modal } = config;
  const { closeModal } = controllers;

  const cancel = () => {
    closeModal();
    clearSelected();
  };

  const columns = [
    {
      title: '',
      key: 'operation',
      fixed: 'left',
      width: 30,
      sortable: false,
      searchable: false,
      render: (_, item, index) => {
        if (item?.merge_to) {
          return null;
        }
        return (
          <div className="flex justify-center w-full">
            {
              <Checkbox
                icon={<AddCircleOutline />}
                checkedIcon={<AddCircle />}
                sx={{
                  padding: 0
                }}
                onChange={(v) => {
                  const { checked } = v.target;
                  if (checked) {
                    pushSelected(item._id);
                  } else {
                    pullSelected(item._id);
                  }
                }}
              />
            }
          </div>
        );
      }
    },
    {
      title: '名稱',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => (
        <To href={`/item/profile/${item._id}`} target="_blank">
          {item.name}
        </To>
      )
    },
    {
      title: '代號',
      width: 100,
      dataIndex: 'code',
      key: 'code',
      render: (_, item) => {
        if (item.merge_to) {
          return (
            <div className="flex gap-2">
              <To href={`/item/profile/${item._id}`} target="_blank">
                {item.code}
              </To>
              <div className="">
                (被
                {
                  <To href={`/item/profile/${item.merge_to._id}`} target="_blank">
                    {item.merge_to.code}
                  </To>
                }
                取替)
              </div>
            </div>
          );
        }
        return (
          <To href={`/item/profile/${item._id}`} target="_blank">
            {item.code}
          </To>
        );
      }
    },
    {
      title: '單位',
      width: 50,
      dataIndex: 'unit',
      key: 'unit',
      sortable: false,
      searchable: false
    },
    {
      title: '單價 (最後批核)',
      width: 60,
      sortable: false,
      searchable: false,
      dataIndex: 'latest_quote_price',
      key: 'latest_quote_price'
    },
    {
      title: 'MOQ',
      width: 40,
      sortable: false,
      searchable: false,
      dataIndex: 'moq',
      key: 'moq'
    },
    {
      title: '服務',
      dataIndex: 'is_service',
      key: 'is_service',
      width: 30,
      sortable: false,
      searchable: false,
      render: (_, item) => <td>{item.is_service && <Check fontSize="small" />}</td>
    }
  ];
  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault();
      }}
    >
      <Dialog
        open={modal}
        onClose={cancel}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        sx={{
          '.MuiDialogContent-root': {
            backgroundColor: '#f7f7f9',
            padding: 2
          },
          zIndex: 1300
        }}
      >
        <DialogTitle sx={{ padding: '12px', fontSize: 16 }}>新增物料</DialogTitle>
        <DialogContent dividers>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <div className="flex _w-12 items-center">
                <span className="mr-2">Category:</span>
                <Select
                  showSearch
                  allowClear
                  style={{
                    width: 200
                  }}
                  onChange={(value) => setSelectedPrimaryItem(value)}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={primaryCategoryItems}
                  className="mr-2"
                />
                <Select
                  showSearch
                  allowClear
                  style={{
                    width: 200
                  }}
                  onChange={(value) => setSelectedSecondaryItem(value)}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={secondaryCategoryItems}
                />
              </div>
            </div>
            <div className="bg-white border rounded-lg overflow-hidden">
              <CsrTable
                columns={columns}
                data={rows}
                size="small"
                pagination={{ pageSize: 50, showSizeChanger: false }}
                scroll={{
                  x: 800,
                  y: 600
                }}
                bordered
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="w-full flex gap-4 items-center">
            <div className="text-zinc-500 grow ml-4 text-sm">已選 {selected.length} 個物料</div>
            <Button onClick={cancel}>取消</Button>
            <Button onClick={confirm} variant="contained" disabled={isEmpty(selected)}>
              確定
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </ClickAwayListener>
  );
}

export default Popup;
