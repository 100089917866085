import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const withAttachments = (WrappedComponent) => {
  return (props) => {
    const { _id, value, field, setInputs, schema, inputs } = props;

    const [files, setFiles] = useState({ attachments: [] });

    useEffect(() => {
      const { attachments, ...rest } = files;
      setInputs((v) => {
        return {
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!(key.startsWith(`$push.attachments`) || key.startsWith(`$pull.attachments`))) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          ),
          ...reduce(
            attachments?.value || [],
            (prev, curr, index) => {
              prev[`$push.attachments[${index}]`] = {
                value: curr,
                touched: true
              };
              return prev;
            },
            {}
          ),
          ...rest
        };
      });
    }, [JSON.stringify(files)]);

    return <WrappedComponent {...props} defaultValue={value} schema={schema} setInputs={setFiles} />;
  };
};
