import React from 'react';
import { Switch } from '@mui/material';

function BooleanSwitch(props) {
  const { isOn, setIsOn, readonly } = props;
  return (
    <Switch
      checked={isOn}
      disabled={readonly}
      onChange={(e) => {
        const on = e.target.checked;
        setIsOn(on);
      }}
    />
  );
}

export default BooleanSwitch;
