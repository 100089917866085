import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withWorksorderNumber } from './hooks';

function WorksorderNumber(props) {
  const { field, value, setInputs, schema, errorMessage } = props;
  return (
    <div className="basis-1/3 flex flex-col">
      <InputEdit
        field={field}
        schema={schema}
        setInputs={setInputs}
        value={value}
        overrideSchema={{ is_required: true }}
      />
      {errorMessage && <div className="mt-[4px] ml-[14px] text-xs text-[#d32f2f]">{errorMessage}</div>}
    </div>
  );
}

export default withWorksorderNumber(WorksorderNumber);
