import React from 'react';

function FourZeroFourPage(props) {
  return (
    <div class="container mx-auto px-4 py-16">
      <div class="text-center">
        <h1 class="text-5xl font-bold text-red-500">403</h1>
        <p class="text-2xl font-medium mb-8">Forbidden</p>
        <p class="text-lg text-gray-600">You don't have permission to access this page.</p>
      </div>
    </div>
  );
}

export default FourZeroFourPage;
