import { useFetch } from 'lib/fetch';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export const withPrStatusPage = (WrappedComponent) => {
  return (props) => {
    const { statusCount } = useStatusCount();
    const { selectedTier, setSelectedTier } = useSelectTier({ statusCount });
    return (
      <WrappedComponent
        {...props}
        statusCount={statusCount}
        selectedTier={selectedTier}
        setSelectedTier={setSelectedTier}
      />
    );
  };
};

export const useStatusCount = () => {
  const { fetch, result } = useFetch();

  useEffect(() => {
    fetch('GET', '/api/collection/pr/status-count/');
  }, []);

  return {
    statusCount: result
  };
};

export const useSelectTier = ({ statusCount }) => {
  const [selectedTier, setSelectedTier] = useState();
  useEffect(() => {
    if (statusCount && !isEmpty(statusCount)) {
      setSelectedTier(statusCount[0]?.tier);
    }
  }, [statusCount]);
  return {
    selectedTier,
    setSelectedTier
  };
};
