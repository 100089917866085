import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, TextField, Switch } from '@mui/material';
import { find, findIndex, isEmpty, map } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Add } from '@mui/icons-material';
import { useTableFilter } from './hooks';
import dayjs from 'dayjs';

function FilterInput({ context }) {
  const { fieldsToDisplay, schema, controllers } = useContext(context);
  const { addFilter } = controllers;
  const { filter, opStringMap, submit, updateField, valueComponent } = useTableFilter({
    fieldsToDisplay,
    schema,
    addFilter
  });

  const selectProps = {
    size: 'small',
    sx: {
      '.MuiInputBase-input': {
        fontSize: '12px',
        minWidth: '60px'
      }
    }
  };

  if (!fieldsToDisplay || !schema || isEmpty(fieldsToDisplay)) return null;

  return (
    <div className="flex gap-1 items-center">
      <Select
        {...selectProps}
        value={filter.field}
        popupMatchSelectWidth={false}
        style={{ minWidth: '120px', minHeight: '30px' }}
        onChange={(value) => {
          updateField('field', value);
        }}
      >
        {fieldsToDisplay?.map((field) => {
          const title = find(schema, { field })?.title || field;
          return (
            <MenuItem value={field} key={title}>
              {title}
            </MenuItem>
          );
        })}
      </Select>
      <Select
        {...selectProps}
        value={filter.op}
        popupMatchSelectWidth
        style={{ minWidth: '120px', minHeight: '30px' }}
        onChange={(value) => {
          updateField('op', value);
        }}
      >
        {map(opStringMap, (title, op) => {
          return (
            <MenuItem value={op} key={title}>
              {title}
            </MenuItem>
          );
        })}
      </Select>

      {(valueComponent === 'text' || valueComponent === 'relation') && (
        <Input
          size="small"
          value={filter.value || ''}
          style={{ minWidth: '120px', minHeight: '30px' }}
          onChange={(e) => {
            updateField('value', e.target.value);
          }}
        />
      )}

      {valueComponent === 'boolean' && (
        <Select
          {...selectProps}
          value={filter.value || true}
          onChange={(e) => {
            updateField('value', e);
          }}
        >
          <MenuItem value="[[true]]">是</MenuItem>
          <MenuItem value="[[false]]">否</MenuItem>
        </Select>
      )}

      {valueComponent === 'number' && (
        <TextField
          size="small"
          type="number"
          value={filter.value || ''}
          onChange={(e) => {
            updateField('value', e.target.value);
          }}
        />
      )}

      {valueComponent === 'date' && (
        <DatePicker
          value={filter.value ? dayjs(filter.value, 'YYYY-MM-DD') : null}
          onChange={(v) => {
            const value = v ? dayjs(v).format('YYYY-MM-DD') : '';
            updateField('value', value ? `[[${value}]]` : '');
          }}
          format="YYYY-MM-DD"
          slotProps={{
            actionBar: { actions: ['clear', 'today'] }
          }}
          sx={{
            '.MuiOutlinedInput-input': {
              height: '40px',
              padding: 0,
              paddingLeft: '14px',
              fontSize: '14px',
              width: 100
            }
          }}
        />
      )}

      <Button icon={<SearchOutlined />} disabled={!filter?.value} onClick={submit}>
        Search
      </Button>
    </div>
  );
}

export default FilterInput;
