import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withContact } from './hooks';
import ContactRow from './components/contact-row';

import { Button } from 'antd';
import { Add } from '@mui/icons-material';
import { range } from 'lodash';

function Contact(props) {
  const { length, rowCount, setInputs, addRowCount, value, showNewRow, body } = props;
  return (
    <div className="flex flex-col w-full gap-2">
      {range(showNewRow ? rowCount : length).map((index) => (
        <ContactRow
          setInputs={setInputs}
          key={index}
          index={index}
          value={value?.[index]}
          length={length}
          body={body}
        />
      ))}
      {showNewRow && (
        <div className="self-center">
          <Button icon={<Add />} onClick={addRowCount}>
            新增聯絡資料
          </Button>
        </div>
      )}
    </div>
  );
}

export default withContact(Contact);
