import { useFetch } from 'lib/fetch';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
export const withSummaryForm = (WrappedComponent) => {
  return (props) => {
    const { data } = useFetchPrData();
    return <WrappedComponent {...props} data={data} />;
  };
};

export const useFetchPrData = () => {
  const { _id } = useParams();
  const { fetch, result } = useFetch();

  useEffect(() => {
    fetch('GET', `/api/collection/pr/summary-form/${_id}`);
  }, [_id]);

  return {
    data: result?.data
  };
};
