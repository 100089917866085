import React from 'react';
import { withContactRow } from './hooks';
import InputEdit from 'components/common/input-edit';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

function ContactRow(props) {
  const { person, phone, email, setData, pullRow, showRow, isPull, index } = props;
  if (!showRow) return;
  return (
    <div className="flex gap-4 items-end">
      <InputEdit
        className="w-1/3"
        setInputs={setData}
        value={person?.value}
        field="person"
        schema={{
          title: '聯絡人',
          field: 'person',
          type: 'text'
        }}
      />
      <InputEdit
        className="w-1/3"
        setInputs={setData}
        value={phone?.value}
        field="phone"
        schema={{
          title: '電話',
          field: 'phone',
          type: 'text'
        }}
      />
      <InputEdit
        className="w-1/3"
        setInputs={setData}
        value={email?.value}
        field="email"
        schema={{
          title: '電郵',
          field: 'email',
          type: 'text'
        }}
      />
      <div className="mb-2">
        <Button shape="circle" size="small" icon={<CloseOutlined />} onClick={pullRow} />
      </div>
    </div>
  );
}

export default withContactRow(ContactRow);
