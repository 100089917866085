import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withProfilePage } from 'hooks/with-profile-page';
import Section from 'components/common/section';
import { useProfile } from './hooks';
import { useTabs } from 'components/common/tabs/hooks';
import Metadata from 'components/common/metadata';
import General from './tabs/general';
import Contract from './tabs/contract';
import Permission from './tabs/permission';

function UserProfilePage(pageProps) {
  const { data, contractData, contractUserData, contractDataIsLoading, metadata, schema, setInputs, EntityLogs } =
    useProfile({ pageProps });

  const { _id, isEditMode } = pageProps;

  const { Component, tabProps } = useTabs();
  const { Tabs, Tab } = Component;

  return (
    <div className="flex flex-col gap-4">
      <Metadata metadata={metadata} />
      <Tabs {...tabProps}>
        <Tab title="Basic Information" id="info">
          <General data={data} schema={schema} setInputs={setInputs} isEditMode={isEditMode} />
        </Tab>
        <Tab title="Contract" id="contract">
          <Contract
            data={data}
            schema={schema}
            setInputs={setInputs}
            _id={_id}
            contractData={contractData}
            contractUserData={contractUserData}
            contractDataIsLoading={contractDataIsLoading}
          />
        </Tab>
        {/* <Tab title="Item" id="user-item">
          <Section title="Item">
            <UserItems schema={schema} field="user__items" value={data?.user__items} setInputs={setInputs} />
          </Section>
        </Tab> */}
        <Tab title="Permission" id="permission">
          <Section title="Permission">
            <Permission schema={schema} data={data} setInputs={setInputs} />
          </Section>
        </Tab>
        {/* {isEditMode ? (
          <Tab title="Logs" id="logs">
            <Section title="Logs">{EntityLogs}</Section>
          </Tab>
        ) : null} */}
      </Tabs>
    </div>
  );
}

export default withProfilePage(UserProfilePage);
