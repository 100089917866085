import { getTimeAgo, parseISODateString } from 'lib/date';
import React from 'react';

function Date({ value, config }) {
  const { format, show_datetime, show_date_only, show_time_only, show_ago = true } = config;
  if (show_ago) {
    return <p>{getTimeAgo(value)}</p>;
  }
  return <p>{parseISODateString(value, { format, show_date_only, show_time_only, show_datetime })}</p>;
}

export default Date;
