import { usePrSubmitGetQuote } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export const withSubmit = (WrappedComponent) => {
  return (props) => {
    const { body, className, data, showSubmit } = props;
    const [open, setOpen] = useState(false);
    const { onSubmit, isLoading, result } = usePrSubmitGetQuote({ _id: data?._id });

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    useEffect(() => {
      if (result) {
        redirect(`/pr/profile/${data._id}`);
      }
    }, [result, data]);

    if (data && data?.record_status && data?.record_status === 10 && isEmpty(body)) {
      return (
        <WrappedComponent
          {...props}
          open={open}
          onSubmit={onSubmit}
          showSubmit={showSubmit}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          submitted={isLoading || result}
        />
      );
    }
    return null;
  };
};
