import { isUndefined, reduce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { getErrorMessage } from 'lib/input';

export const useInputFile = ({ defaultValue, config, setInputs, field }) => {
  const { customErrorHandler, accept: defaultAccept, file_type, readonly, is_multiple } = config;
  const isTouched = useRef(null);
  const [files, setFiles] = useState(null);
  const [removedFiles, setRemovedFiles] = useState([]);

  const errorMessage = useMemo(() => {
    if (customErrorHandler && files) {
      return customErrorHandler(files);
    }
    return getErrorMessage({ schema: config, value: files });
  }, [config, files, customErrorHandler]);

  const accept = useMemo(() => {
    if (defaultAccept) {
      return defaultAccept;
    } else {
      if (file_type === 'image') {
        return '.jpg, .jpeg, .png';
      } else if (file_type === 'pdf') {
        return '.pdf';
      }
    }
  }, [defaultAccept, file_type]);

  useEffect(() => {
    setRemovedFiles([]);
    setFiles([]);
  }, [defaultValue]);

  useEffect(() => {
    if (readonly) return;
    setInputs((v) => {
      return {
        ...v,
        [field]: {
          value: files,
          error: !!errorMessage,
          touched: !!isTouched.current
        }
      };
    });
  }, [errorMessage, field, setInputs, files, readonly]);

  useEffect(() => {
    if (is_multiple) {
      setInputs((v) => {
        return {
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!key.startsWith(`$pull.${field}`)) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          ),
          ...reduce(
            removedFiles,
            (prev, curr, key) => {
              prev[`$pull.${field}[${key}]`] = {
                value: curr,
                touched: true
              };
              return prev;
            },
            {}
          )
        };
      });
    } else {
      setInputs((v) => {
        return {
          ...v,
          [field]: {
            value: '',
            touched: true
          }
        };
      });
    }
  }, [removedFiles, field, is_multiple]);

  return {
    accept,
    errorMessage,
    files,
    setFiles: (files) => {
      if (!isTouched.current) {
        isTouched.current = true;
      }
      setFiles(files);
    },
    setRemovedFiles,
    removedFiles
  };
};

export const convertFileSize = (sizeInBytes) => {
  if (!sizeInBytes) return '';
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = sizeInBytes;
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  return size.toFixed(2) + ' ' + units[unitIndex];
};
