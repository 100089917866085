import React from 'react';
import { useInputBoolean } from './hooks';
import BooleanButton from './variant/button';
import BooleanCheckbox from './variant/checkbox';
import BooleanSwitch from './variant/switch';
import BooleanToggleButton from './variant/toggle-button';

function InputBoolean({ field, value, setInputs, config }) {
  const { isOn, setIsOn } = useInputBoolean({ defaultValue: value, setInputs, field, config });

  const { readonly, input_variant = 'SWITCH' } = config;
  const variantProps = {
    isOn,
    setIsOn,
    readonly
  };

  switch (input_variant) {
    case 'BUTTON':
      return <BooleanButton {...variantProps} />;
    case 'CHECKBOX':
      return <BooleanCheckbox {...variantProps} />;
    case 'TOGGLE_BUTTON':
      return <BooleanToggleButton {...variantProps} />;
    default:
      return <BooleanSwitch {...variantProps} />;
  }
}

export default InputBoolean;
