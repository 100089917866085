import React, { useContext, useEffect, useMemo, useState } from 'react';
import { find, findIndex, first, isEmpty, map, omit } from 'lodash';

export const opMap = {
  eq: '等於',
  ne: '不等於',
  gt: '大於',
  lt: '小於',
  gte: '大於或等於',
  lte: '小於或等於',
  regex: '中含有',
  regexstart: '字首是'
};

export const useTableFilter = (props) => {
  const { fieldsToDisplay, schema, addFilter } = props || {};

  const [filter, setFilter] = useState({
    field: first(fieldsToDisplay) || '',
    op: 'eq',
    value: ''
  });

  const updateField = (field, value) => {
    setFilter((v) => ({
      ...v,
      [field]: value
    }));
  };

  const submit = () => {
    if (!isEmpty(filter.value)) {
      addFilter({
        field: filter.field,
        op: filter.op,
        value: filter.value
      });
    }
  };

  const valueComponent = useMemo(() => {
    const { field } = filter;

    if (
      field === 'latest_quote_price' ||
      field === 'total_price' ||
      field === 'item_total_price' ||
      field === 'budget'
    ) {
      return 'number';
    }
    const setting = find(schema, { field });
    const { type } = setting;
    if (setting && type) {
      return type;
    }
    return null;
  }, [filter, schema]);

  useEffect(() => {
    setFilter((v) => ({
      ...v,
      value: ''
    }));
  }, [filter.field]);

  const opStringMap = useMemo(() => {
    switch (valueComponent) {
      case 'boolean':
        return omit(opMap, ['gt', 'lt', 'gte', 'lte', 'regex', 'regexstart', 'ne']);
      case 'text':
      case 'relation':
        return omit(opMap, ['gte', 'lte', 'gt', 'lt']);
      case 'number':
        return omit(opMap, ['regex', 'regexstart']);
      case 'date':
        return omit(opMap, ['regex', 'regexstart']);
      default:
        return opMap;
    }
  }, [valueComponent]);

  return {
    filter,
    opStringMap,
    submit,
    updateField,
    valueComponent
  };
};
