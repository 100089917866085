import React from 'react';
import { Skeleton } from '@mui/material';

function Section({ children, title, size, isLoading, className = '', id }) {
  if (isLoading) {
    return (
      <div className="flex flex-col">
        {Array.from({ length: 10 }).map((i) => (
          <div key={i}>
            <Skeleton animation="wave" sx={{ height: 60 }} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div
      className={`flex flex-col gap-8 p-4 rounded-xl shadow-md bg-white overflow-auto ${className}`}
      id={id || title || undefined}
    >
      {title && <div className="text-md uppercase text-zinc-800 tracking-wide">{title}</div>}
      <div>{children}</div>
    </div>
  );
}

export default Section;
