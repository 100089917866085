import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withMergeTo } from './hooks';

function MergeTo(props) {
  const { schema, value, selectOptions } = props;
  return <InputEdit {...props} schema={schema} overrideSchema={{ selectOptions }} value={value} />;
}

export default withMergeTo(MergeTo);
