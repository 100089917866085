import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import LoginPage from 'pages/login';
import RegisterPage from 'pages/register';

import ContractRoutes from './pages/contract';
import CurrencyRoutes from './pages/currency';
import DeliveryRoutes from './pages/delivery';
import PaymentRoutes from './pages/payment';
import ItemRoutes from './pages/item';
import UserRoutes from './pages/user';
import PrRoutes from './pages/pr';
import PoRoutes from './pages/po';
import EmailTemplateRoutes from './pages/email-template';
import SupplierRoutes from './pages/supplier';
import SubcontractorRoutes from './pages/subcontractor';
import LogRoutes from './pages/log';
import DevIndexPage from 'pages/dev/index';
import PrSummaryForm from 'pages/pr/summary-form';
import PrPrintForm from 'pages/pr/print-form';
import PoPrintForm from 'pages/po/print-form';
import FourZeroFourPage from 'pages/403/index';

export default function routes() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        <Route path="/dev" element={<DevIndexPage />} />
        <Route path="/403" element={<FourZeroFourPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/pr/summary-form/:_id" element={<PrSummaryForm />} />
        <Route path="/pr/print-form/:_id" element={<PrPrintForm />} />
        <Route path="/po/print-form/:_id" element={<PoPrintForm />} />
        {ContractRoutes}
        {CurrencyRoutes}
        {DeliveryRoutes}
        {PaymentRoutes}
        {UserRoutes}
        {ItemRoutes}
        {PoRoutes}
        {PrRoutes}
        {SupplierRoutes}
        {SubcontractorRoutes}
        {LogRoutes}
        {EmailTemplateRoutes}
      </Routes>
    </LocalizationProvider>
  );
}
