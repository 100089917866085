import { useCollectionFieldDistinct } from 'hooks/use-collection-field-distinct';
import { find, isEmpty, reduce } from 'lodash';
import { useMemo } from 'react';

export const useDepartment = ({ value, field, setInputs, schema }) => {
  const { data = [] } = useCollectionFieldDistinct({ collection: 'user', field: 'department' });
  const title = useMemo(() => {
    const a = find(schema, { field });
    return a?.title || '部門';
  }, [schema, field]);

  const options = useMemo(() => {
    const preDefined = [
      {
        _id: 'user',
        label: '合約限權用戶'
      },
      {
        _id: 'PD',
        label: '採購'
      },
      {
        _id: 'PM',
        label: '採購經理'
      }
      // {
      //   _id: 'QS',
      //   label: '工程測量'
      // }
    ];

    if (isEmpty(data)) return preDefined;

    const ids = preDefined.map((k) => k._id);
    return reduce(
      data,
      (prev, curr) => {
        if (!ids.includes(curr?._id)) {
          prev.push(curr);
        }
        return prev;
      },
      preDefined
    );
  }, [data]);

  const is_required = useMemo(() => {
    const a = find(schema, { field });
    return a?.is_required || false;
  }, [schema, field]);

  return {
    title,
    options,
    is_required
  };
};
