import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withQuoteReferences } from './hooks';
import Section from 'components/common/section';

function QuoteReferences(props) {
  const { defaultValue, schema, field } = props;
  return (
    <Section>
      <InputEdit {...props} field={field} schema={schema} value={defaultValue} />
    </Section>
  );
}

export default withQuoteReferences(QuoteReferences);
