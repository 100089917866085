import React from 'react';
import { withBacktoQuote } from './hooks';
import { Done, Publish, Undo } from '@mui/icons-material';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

function BacktoQuote(props) {
  const { handleDialogOpen, handleDialogClose, open, onSubmit, disabled, submitted } = props;

  return (
    <div>
      <div className="flex gap-4 items-center">
        {disabled && <div className="text-zinc-600">更改物料資料後，儲存後才可提交</div>}
        <Button variant="outlined" endIcon={<Undo />} onClick={handleDialogOpen} size="large" disabled={disabled}>
          再索取報價
        </Button>
      </div>
      <div className={`flex justify-end items-center w-full`}>
        <Dialog
          open={open}
          onClose={handleDialogClose}
          sx={{
            '.MuiDialog-paper': {
              width: 500
            }
          }}
        >
          <DialogTitle>你確定要再索取報價嗎?</DialogTitle>
          <DialogContent>
            <DialogContentText>一旦提交後將無法修改</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>取消</Button>
            <Button onClick={onSubmit} variant="contained" autoFocus disabled={submitted}>
              確定
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default withBacktoQuote(BacktoQuote);
