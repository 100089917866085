import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withContract } from './hooks';

function Contract(props) {
  const { defaultValue, title, options, readonly } = props;
  const { mode } = props;
  return (
    <InputEdit
      {...props}
      schema={{ title, input_type: 'select', selectOptions: options, readonly, is_required: true }}
      value={defaultValue}
    />
  );
}

export default withContract(Contract);
