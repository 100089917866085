import dayjs from 'dayjs';
import React from 'react';

function Metadata(props) {
  const { metadata } = props || {};
  const { created_at, updated_at, created_by, updated_by, created_by_nickname, updated_by_nickname } = metadata || {};

  if (!created_at) {
    return null;
  }

  const created = dayjs(created_at).format('YYYY-MM-DD HH:mm');
  const updated = dayjs(updated_at).format('YYYY-MM-DD HH:mm');

  return (
    <div className="flex py-2 px-4 border bg-white w-fit self-center text-zinc-600 text-xs gap-2">
      <div>{`${created_by_nickname || created_by || ''} 於 ${created} 建立`}</div>
      {updated_at && created !== updated && (
        <div className="pl-1">{`${updated_by_nickname || updated_by || ''} 於 ${updated} 更新`}</div>
      )}
    </div>
  );
}

export default Metadata;
