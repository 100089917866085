import React from 'react';
import Section from 'components/common/section';
import ContractRow from './components/row';
import { withContract } from './hooks';
import { map } from 'lodash';
import TableLayout from 'components/common/table-layout';

function Contract(props) {
  const { data, schema, setInputs, contractData, contractUserData, contractDataIsLoading, readonly } = props;
  return (
    <Section>
      <div className="flex flex-col gap-2 overflow-auto">
        <TableLayout
          className="w-max-content mx-auto bg-white rounded-xl table-fixed"
          isLoading={contractDataIsLoading}
          showEmpty={!contractData?.length}
        >
          <thead>
            <tr className="text-center border-b-2 text-sm text-zinc-500">
              {/* <th className="px-4 w-12"></th> */}
              <th className="w-28 py-4 text-zinc-800 text-base text-center ">合約</th>
              <th className="px-2">Issue</th>
              <th className="px-2">A1</th>
              <th className="px-2">A2</th>
              <th className="px-2">A3</th>
              <th className="px-2">A4</th>
              <th className="px-2">A5</th>
              <th className="px-2">A6</th>
              <th className="px-2">A7</th>
            </tr>
          </thead>
          <tbody>
            {map(contractData, (contract, index) => {
              return (
                <ContractRow
                  {...props}
                  key={index}
                  readonly={readonly}
                  contract={contract}
                  userData={contractUserData}
                  className={`px-2 hover:bg-zinc-50`}
                />
              );
            })}
          </tbody>
        </TableLayout>
      </div>
    </Section>
  );
}

export default withContract(Contract);
