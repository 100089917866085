import React from 'react';
import PrHeader from './components/pr-header';
import PrInformation from './components/pr-information';
import PrItemsTable from './components/pr-items-table';
import ApprovalHistory from './components/approval-history';
import PrSignature from './components/pr-signature';
import { withPrintForm } from './hooks';

import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';
import NotoSansTC from '../../../fonts/NotoSansTC-Regular.otf';
import { ContentCopy, Print, Save } from '@mui/icons-material';
import { Button } from '@mui/material';

Font.register({
  family: 'Noto Sans TC',
  src: NotoSansTC
});
Font.registerHyphenationCallback((word) => {
  return [word];
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Noto Sans TC',
    paddingTop: 35,
    paddingBottom: 85,
    paddingHorizontal: 35,
    fontSize: 12
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

function PrPrintForm(props) {
  const { data } = props;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrHeader data={data} />
        <PrInformation data={data} />
        <PrItemsTable data={data} />
        {/* <ApprovalHistory data={data} /> */}
        <PrSignature data={data} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `頁數 ${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}

function PdfDownloadButton(props) {
  const { data, print } = props;

  return (
    <PDFDownloadLink document={<PrPrintForm data={data} />} fileName={`PR (${data?.code}).pdf`}>
      {({ blob, url, loading, error }) => (
        <Button onClick={print} endIcon={<Print />} variant="outlined">
          {loading ? '載入中' : '列印'}
        </Button>
      )}
    </PDFDownloadLink>
  );
}

function PdfReview(props) {
  const { data } = props;

  return (
    <PDFViewer width="100%" height="850px">
      <PrPrintForm data={data} />
    </PDFViewer>
  );
}

export default withPrintForm(PdfReview);
