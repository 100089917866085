import { user } from 'store';
import { useAtomValue } from 'jotai';

export const useUserPagePermission = (props) => {
  const { menuItem } = props || {};
  const [rootMenu, childMenu] = menuItem || [];
  const currentUser = useAtomValue(user);

  if (currentUser) {
    const { permissions_read, is_admin } = currentUser;
    const hasAllPermissions = is_admin || permissions_read.includes('*');

    return {
      hasPermission: hasAllPermissions
        ? true
        : menuItem
        ? (permissions_read && permissions_read.includes(rootMenu)) || permissions_read.includes(childMenu)
        : null,
      permissions_read,
      is_admin
    };
  }
  return {
    hasPermission: false,
    permissions_read: [],
    is_admin: false
  };
};
