import { useMemo } from 'react';
import { find } from 'lodash';
import { tier_list } from 'lib/config';

export const withApprovalTimeline = (WrappedComponent) => {
  return (props) => {
    const { historyData = [], data } = props;
    const history = useMemo(() => {
      return tier_list.map((tier) => {
        const record = find(historyData, { tier });
        const skip = data?.[`skip_${tier}`];
        const active = !!record || skip;

        return {
          ...record,
          tier,
          active,
          color: active ? (record?.is_approve || skip ? '#4ade80' : '#f87171') : '#bdbdbd',
          skip
        };
      });
    }, [historyData]);
    return <WrappedComponent {...props} history={history} />;
  };
};
