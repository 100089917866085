import React from 'react';
import { withApprovalHistory } from './hooks';
import { parseISODateString } from 'lib/date';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  paragraph: {
    marginTop: 12,
    marginBottom: 12
  },
  title: {
    marginTop: 12,
    marginBottom: 12
  },
  table: {
    // width: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottom: '1px solid #000',
    fontSize: 10
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    borderTop: '1px solid #000',
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cell: {
    borderRight: '1px solid #000',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  cell1: {
    borderLeft: '1px solid #000',
    flexBasis: 10
    // textAlign: 'right'
  },
  cell2: {
    flexBasis: 15
    // textAlign: 'right'
  },
  cell3: {
    flexBasis: 15
    // textAlign: 'right'
  },
  cell4: {
    flexBasis: 10
    // textAlign: 'right'
  },
  cell5: {
    flexBasis: 50
    // textAlign: 'right'
  }
});

function ApprovalHistory(props) {
  const { rows } = props;
  return (
    <View>
      <View style={[styles.table, styles.paragraph, styles.pr_item]}>
        <View style={[styles.tableRow, styles.tableHeader]} wrap={false}>
          <Text style={[styles.cell, styles.cell1]}>批核紀錄</Text>
          <Text style={[styles.cell, styles.cell2]}>狀態</Text>
          <Text style={[styles.cell, styles.cell3]}>用戶</Text>
          <Text style={[styles.cell, styles.cell4]}>日期</Text>
          <Text style={[styles.cell, styles.cell5]}>備註</Text>
        </View>
        {rows.map((record, index) => (
          <View style={[styles.tableRow]} wrap={false} key={index}>
            <Text style={[styles.cell, styles.cell1]}>{record?.tier}</Text>
            <Text style={[styles.cell, styles.cell2]}>
              {record?.is_approve === true
                ? '批准'
                : record?.skipped
                ? '跳過'
                : record?.is_approve === false
                ? '拒批'
                : ''}
            </Text>
            <Text style={[styles.cell, styles.cell3]}>{record?.user?.nickname}</Text>
            <Text style={[styles.cell, styles.cell4]}>
              {parseISODateString(record?.created_at, { show_datetime: true })}
            </Text>
            <Text style={[styles.cell, styles.cell5]}>{record?.remarks}</Text>
          </View>
        ))}
      </View>
    </View>
  );
}

export default withApprovalHistory(ApprovalHistory);
