import InputEdit from 'components/common/input-edit';
import { useContractRow } from './hooks';
import To from 'components/common/to';

function ContractRow(props) {
  const { className } = props;
  const { contractId, contractTitle, inputEditProps, records } = useContractRow(props);
  return (
    <tr className={className}>
      <td className="px-4 text-center">
        <To href={`/contract/profile/${contractId}`} target="_blank">
          {contractTitle}
        </To>
      </td>
      <td className="py-1 px-2">
        <InputEdit {...inputEditProps} field="Issue" value={records?.Issue} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A1" value={records?.A1} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A2" value={records?.A2} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A3" value={records?.A3} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A4" value={records?.A4} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A5" value={records?.A5} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A6" value={records?.A6} />
      </td>
      <td className="px-2">
        <InputEdit {...inputEditProps} field="A7" value={records?.A7} />
      </td>
    </tr>
  );
}

export default ContractRow;
