import { redirect } from 'lib/location';
import { useState } from 'react';
import { useLogout } from 'store';

export const useLogoutButton = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { logout } = useLogout();

  const handleLogin = () => {
    logout();

    redirect('/login');
  };

  return {
    open,
    handleClickOpen,
    handleClose,
    handleLogin
  };
};
