import React from 'react';
import { JsonViewer } from '@textea/json-viewer';

function Json({ value }) {
  return (
    <div className="bg-zinc-50 rounded border border-gray-300 p-2">
      {value ? <JsonViewer value={JSON.parse(value)} /> : <div className="h-6" />}
    </div>
  );
}

export default Json;
