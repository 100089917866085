import React from 'react';
import { Button, Divider } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import { FileUpload } from '@mui/icons-material';
import CsvUploadTable from './components/table';

function CsvUpload(props) {
  const { data, controllers, config } = props;
  const { file } = data;
  const { onFileChange, save } = controllers;
  return (
    <div className="w-full h-full flex flex-col justify-center bg-white shadow-md rounded-xl min-h-full">
      <div className="p-8 flex items-self gap-4 items-center">
        <div className="text-md text-zinc-700 shrink-0">選擇 CSV 檔</div>
        <MuiFileInput value={file} accept=".csv" color="primary" onChange={onFileChange} size="small" />
        <Button
          variant="contained"
          startIcon={<FileUpload />}
          onClick={save}
          disabled={!(file instanceof File)}
          className="shrink-0"
        >
          上載
        </Button>
      </div>
      <div>
        <Divider />
      </div>
      <div className="grow w-full p-2">
        <CsvUploadTable data={data} config={config} />
      </div>
    </div>
  );
}

export default CsvUpload;
