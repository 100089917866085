import React from 'react';
import { Chip } from '@mui/material';

function Fields(props) {
  const { value } = props;
  if (!value) return null;
  return (
    <div className="flex flex-wrap gap-1">
      {value.map((field) => (
        <Chip key={field} className="" label={field} />
      ))}
    </div>
  );
}

export default Fields;
