import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/item/index';
import ProfilePage from 'pages/item/profile';
import UploadPage from 'pages/item/upload';

const collection = 'item';
const menuItem = ['DATABASE', 'ITEM'];

const routes = [
  <Route
    path={`/${collection}`}
    element={<IndexPage menuItem={menuItem} title="物料管理" collection={collection} />}
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="物料" collection={collection} editMode="edit" />}
  />,
  <Route
    path={`/${collection}/copy/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增物料" collection={collection} editMode="copy" />}
  />,
  <Route
    path={`/${collection}/new`}
    element={<ProfilePage menuItem={menuItem} title="新增物料" collection={collection} editMode="new" />}
  />,
  <Route
    path={`/${collection}/import`}
    element={<UploadPage menuItem={menuItem} title="批量新增物料" collection={collection} />}
  />
];

export default routes;
