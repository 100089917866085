import { usePrSubmitSubcontractorVerified } from 'hooks/use-pr';
import { useEffect, useState } from 'react';

export const withApprovalButton = (WrappedComponent) => {
  return (props) => {
    const { _id } = props;
    const [open, setOpen] = useState(false);
    const { onSubmit, result } = usePrSubmitSubcontractorVerified({ _id });

    useEffect(() => {
      if (result) {
        window.location.reload(true);
      }
    }, [result]);

    return <WrappedComponent {...props} open={open} setOpen={setOpen} onSubmit={(state) => onSubmit(state)} />;
  };
};
