import React, { useContext } from 'react';
import Filter from './components/filter';
import Page from './components/page';
import Thead from './components/thead';
import Tbody from './components/tbody';
import DownloadData from './components/download-data';
import NewEntry from './components/new-entry';
import TableLayout from '../table-layout';

function Table({ context, ...props }) {
  const { destination } = props || {};
  const { isLoading, data } = useContext(context);
  return (
    <div className="flex flex-col shadow rounded-lg">
      <div className="flex flex-col bg-neutral-50 py-4 px-3 rounded-tr-lg rounded-tl-lg gap-2">
        <div className="flex justify-between w-full">
          <div className="">
            <Filter context={context} />
          </div>
          <div className="flex gap-2 h-fit">
            <DownloadData context={context} />
            <NewEntry context={context} />
          </div>
        </div>
        <Page context={context} />
      </div>
      <div className="pb-2 rounded-br-lg rounded-bl-lg">
        <TableLayout
          className="min-w-full w-auto table-fixed"
          isLoading={isLoading}
          showEmpty={!data?.length}
          thead={<Thead context={context} />}
        >
          <Tbody context={context} destination={destination} />
        </TableLayout>
      </div>
    </div>
  );
}

export default Table;
