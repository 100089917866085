import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataEdit } from 'hooks/use-data-edit';
import { useTopNav } from 'components/common/top-nav/hooks';
import { useCollectionConfig } from 'hooks/use-collection-config';
import { useFetch } from 'lib/fetch';
import { find, map } from 'lodash';

export const withEmailTemplateIndexPage = (Component) => (props) => {
  const { collection } = props;
  const collectionConfig = useCollectionConfig({ collection });
  const { schema } = collectionConfig || {};
  const { template, setTemplate, options } = useTemplateSelect({ schema });
  const pageData = useProfilePageData({ template });
  const url = `/api/collection/${collection}/edit-by-template/${template}`;
  const { setTopNav } = useTopNav();
  const { data, config, controller, save, canSave } = useDataEdit({
    mode: 'edit',
    value: pageData,
    config: { editable: false },
    url,
    refetch: () => {
      pageData?.fetchPageData(template);
    }
  });

  const { setInputs } = controller;

  useEffect(() => {
    setTopNav({ save, canSave, copy: null });
  }, [save, canSave]);

  if (pageData?.isLoading) return null;

  return (
    <Component
      {...props}
      options={options}
      setInputs={setInputs}
      template={template}
      setTemplate={setTemplate}
      schema={schema}
      data={data}
    />
  );
};

export const useTemplateSelect = (props) => {
  const { schema } = props;
  const [template, setTemplate] = useState('');

  const options = useMemo(() => {
    return schema ? find(schema, { field: 'template' })?.selectOptions : [];
  }, [schema]);

  useEffect(() => {
    const defaultOption = options?.[0]?._id;
    const optionIds = map(options, '_id');
    const { hash } = window.location;
    if (hash) {
      const id = hash.substring(1);
      const v = optionIds.indexOf(id);
      console.log(id);
      if (v !== -1) {
        setTemplate(id);
      }
    } else if (defaultOption) {
      setTemplate(defaultOption);
    }
  }, [schema, options]);

  return {
    template,
    setTemplate,
    options
  };
};

export const useProfilePageData = ({ template }) => {
  const { fetch, result, status, isLoading } = useFetch();

  const fetchPageData = (template) => {
    if (template) {
      fetch('GET', `/api/collection/email/get-by-template/${template}`);
    }
  };
  const refetch = useCallback(() => {
    fetchPageData(template);
  }, [template]);

  useEffect(() => {
    fetchPageData(template);
  }, [template]);

  return {
    ...(result || {}),
    fetchPageData,
    refetch,
    isLoading
  };
};

export const useEmailTemplateVariables = () => {
  return {
    variables: [
      'subcontractor_name',

      'view_pr_link',
      'issuer_name',
      'pr_number',
      'po_number',
      'pr_url',
      'po_url',

      'issuer_email',
      'pd_email',
      'pd_manager_email',
      'approver_email',
      'subcon_email',
      'po_supplier_email'
    ]
  };
};
