import { useFetch } from 'lib/fetch';

const useAddPrintLog = (params) => {
  const { collection, _id } = params;
  const { fetch, result } = useFetch();
  const print = () => {
    if (collection && _id) {
      fetch('POST', `/api/collection/print/new`, { params: { collection_name: collection, doc_id: _id } });
    }
  };
  return {
    print
  };
};

export const usePrintLog = (props) => {
  const { collection, _id } = props;
  const { print } = useAddPrintLog({ collection, _id });
  return {
    print
  };
};
