import React from 'react';
import DataRow from '../data-row';
import { parseISODateString } from 'lib/date';
import { compact } from 'lodash';

function PrDetail(props) {
  const { data } = props;
  return (
    <div className="mt-6 flex flex-col gap-1">
      <div className="flex gap-4">
        <div className="w-1/3">
          <DataRow title="1. 日期" data={parseISODateString(data?.application_date)} />
        </div>
        {data?.form === 'A' && (
          <>
            <div className="w-1/3">
              <DataRow title="6. Job Type" data={data?.jot_type_label || data?.job_type} size="large" />
            </div>
            <div className="w-1/3">
              <DataRow title="9. 發出日期" data={parseISODateString(data?.send_date)} size="mid" />
            </div>
          </>
        )}
      </div>
      <div className="flex gap-4">
        <div className="flex gap-2 w-1/3 text-sm">
          <div className="w-7/12 flex">
            <div className="w-[105px]">2. 申請人</div>
            <div className="flex border-b-2 border-zinc-200 grow pl-1 items-end">{data?.apply_by}</div>
          </div>
          <div className="w-5/12 flex">
            <div className="">聯絡電話: </div>
            <div className="flex border-b-2 border-zinc-200 grow pl-1 items-end ml-2">{data?.contact_number}</div>
          </div>
        </div>
        {data?.form === 'A' && (
          <>
            <div className="w-1/3">
              <DataRow title="/ No." data={data?.eo_number} size="large" />
            </div>
            <div className="w-1/3">
              <DataRow title="10. 預計開工日期" data={parseISODateString(data?.start_date)} size="mid" />
            </div>
          </>
        )}
      </div>
      <div className="flex gap-4">
        <div className="w-1/3">
          <DataRow
            title="3. 訂購(扣數)"
            data={compact(
              data?.pr__subcontractors?.map((row) => {
                if (row?.subcontractor?.code && row?.subcontractor?.chin_name) {
                  return `${row?.subcontractor?.code} - ${row?.subcontractor?.chin_name}`;
                }
                return null;
              })
            ).join(', ')}
          />
        </div>
        {data?.form === 'A' && (
          <>
            <div className="w-1/3">
              <DataRow title="7. 需註明原因" data={data?.reason} size="large" />
            </div>
            <div className="w-1/3">
              <DataRow title="11. 預計完工日期" data={parseISODateString(data?.end_date)} size="mid" />
            </div>
          </>
        )}
      </div>
      {data?.form === 'A' && (
        <>
          <div className="flex gap-4">
            <div className="w-1/3">
              <DataRow title="4. 區份" data={data?.district_code} />
            </div>
            <div className="w-1/3">
              <DataRow title="8. Works Order No." data={data?.wo_number} size="large" />
            </div>
            <div className="w-1/3">
              <DataRow title="12. 預算總額" data={data?.budget} size="mid" />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/3">
              <DataRow title="5. 工程性質" data={data?.pr__items_nature} />
            </div>
            <div className="w-2/3">
              <DataRow title="13. 備註:" data={data?.remark} size="mid" />
            </div>
          </div>
        </>
      )}
      {data?.form === 'C' && (
        <>
          <div className="flex gap-4">
            <div className="w-1/3">
              <DataRow title="4. 工程性質" data={data?.pr__items_nature} />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/3">
              <DataRow title="5. 備註" data={data?.remark} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PrDetail;
