import React, { useContext, useState } from 'react';
import { isUndefined } from 'lodash';
import { FilterAlt } from '@mui/icons-material';

import Tags from './tags';
import FilterInput from './input';

function TableFilter({ context }) {
  const { config } = useContext(context);
  const [showRow, setShowRow] = useState(false);

  if (config?.Components?.Filter?.active === false) return null;

  if (!isUndefined(config?.Components?.Filter)) {
    return config?.Components?.Filter ? <config.Components.Filter {...config?.componentProps?.Filter} /> : null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4 h-10 flex">
        <div
          className="shadow-md rounded-md p-2 text-grey-50 cursor-pointer hover:bg-gray-100"
          onClick={() => {
            setShowRow((v) => !v);
          }}
        >
          <FilterAlt style={{ fontSize: 16, fill: '#464F60' }} />
        </div>
        {showRow && <FilterInput context={context} />}
      </div>
      <Tags context={context} />
    </div>
  );
}

export default TableFilter;
