import { filter, find, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withIssue = (WrappedComponent) => {
  return (props) => {
    const { userData, contractUserData, setInputs } = props;
    const currentUser = useAtomValue(user);

    const readonly = !currentUser?.is_admin;

    const [rowInputs, setRowInputs] = useState({});
    const selectOptions = [
      { _id: -1, label: ' ' },
      { _id: 10, label: 'Yes' },
      { _id: 20, label: 'Sub' }
    ];

    const rows = useMemo(() => {
      return reduce(
        userData,
        (prev, curr) => {
          const contract = filter(contractUserData, { user: curr._id });
          return [
            ...prev,
            {
              username: curr.nickname || curr.username,
              user_id: curr._id,
              Issue: find(contract, { tier: 'Issue' })?.level,
              A1: find(contract, { tier: 'A1' })?.level,
              A2: find(contract, { tier: 'A2' })?.level,
              A3: find(contract, { tier: 'A3' })?.level,
              A4: find(contract, { tier: 'A4' })?.level,
              A5: find(contract, { tier: 'A5' })?.level,
              A6: find(contract, { tier: 'A6' })?.level,
              A7: find(contract, { tier: 'A7' })?.level
            }
          ];
        },
        []
      );
    }, [JSON.stringify(userData), JSON.stringify(contractUserData)]);

    const schema = {
      input_type: 'select',
      input_variant: 'slim-select',
      selectOptions,
      readonly
    };

    useEffect(() => {
      const prefix = `$edit.contract__users`;
      const data = reduce(
        rowInputs,
        (prev, curr, key) => {
          prev[`${prefix}.${key}`] = curr;
          return prev;
        },
        {}
      );
      setInputs((v) => {
        const prev = reduce(
          v,
          (prev, curr, key) => {
            if (!key.startsWith(prefix)) {
              prev[key] = curr;
            }
            return prev;
          },
          {}
        );
        return {
          ...prev,
          ...data
        };
      });
    }, [JSON.stringify(rowInputs)]);

    return (
      <WrappedComponent {...props} rows={rows} selectOptions={selectOptions} setInputs={setRowInputs} schema={schema} />
    );
  };
};
