import React from 'react';
import { withPage } from 'hooks/with-page';
import { useTable } from 'components/common/table/hooks';
import { withPrStatusPage } from './hooks';
import { map } from 'lodash';
import { approval_status_map } from 'lib/config';

function PrStatusPage(props) {
  const { collection, statusCount, selectedTier, setSelectedTier } = props;
  const paramsPreset = {
    filters: [
      {
        field: 'record_status',
        op: 'eq',
        value: selectedTier
      }
    ]
  };
  const { Component } = useTable({
    collection,
    url: `/api/collection/pr/listing`,
    paramsPreset,
    Components: {
      downloadButton: {
        active: false
      },
      NewEntry: {
        active: false
      },
      Filter: {
        active: false
      }
    },
    componentProps: {
      destination: {
        prefix: `/${collection}/profile/`
      }
    }
  });
  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="flex gap-4">
        {map(statusCount, (tier) => (
          <div
            key={tier}
            className={`flex flex-col justify-center items-center w-32 py-3 border-2 border-solid rounded-md cursor-pointer ${
              selectedTier === tier.tier ? 'bg-white border-blue-200' : 'border-zinc-200'
            }`}
            onClick={() => setSelectedTier(tier.tier)}
          >
            <div className="text-zinc-700 text-xl">{tier.count}</div>
            <div className="text-zinc-500 text-xs">{approval_status_map[tier.tier]}</div>
          </div>
        ))}
      </div>
      {Component}
    </div>
  );
}

export default withPrStatusPage(withPage(PrStatusPage));
