import { useFetch } from 'lib/fetch';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

export const withCode = (WrappedComponent) => {
  return (props) => {
    const { body, value, mode, data, inputs } = props;
    const { fetchRecommandCode, recommandCode } = useRecommandCode();
    const { isValidCode } = useCheckDuplicatedCode({ inputs });

    const { parent } = body;

    useEffect(() => {
      if (mode === 'copy' && data?.parent) {
        fetchRecommandCode(data?.parent);
      } else {
        if (!value && parent) {
          fetchRecommandCode(parent);
        }
      }
    }, [data?.parent, mode, parent, value]);

    const customValue = useMemo(() => {
      if (mode === 'copy') {
        return recommandCode;
      }
      return value;
    }, [value, recommandCode, mode]);

    return (
      <WrappedComponent
        {...props}
        overrideSchema={{
          default: recommandCode,
          forceUpdate: mode === 'copy',
          customErrorMessage: isValidCode ? null : '此Code已被使用, 請使用其他Code'
        }}
        value={customValue}
      />
    );
  };
};

export const useRecommandCode = () => {
  const { fetch, result } = useFetch();

  const fetchRecommandCode = useCallback((_id) => {
    fetch('GET', `/api/collection/item/recommand-code-by-parent/${_id}`);
  }, []);

  return {
    fetchRecommandCode,
    recommandCode: result?.code || undefined
  };
};

export const useCheckDuplicatedCode = (props) => {
  const { inputs } = props;
  const newInputValue = inputs?.code?.value;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (newInputValue) {
      const fn = debounce(() => {
        fetch('GET', `/api/collection/item/check-deplicated-code/${encodeURIComponent(newInputValue)}`);
      }, 500);

      fn();

      return () => {
        fn.cancel();
      };
    }
  }, [newInputValue]);

  return {
    isValidCode: result?.isValid
  };
};
