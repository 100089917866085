import Section from 'components/common/section';
import React from 'react';

import { List, Typography } from 'antd';
import { useLogin, withDevIndexPage } from './hooks';
import To from 'components/common/to';
import { Search } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const { Paragraph } = Typography;

function DevIndexPage(props) {
  const {
    users,
    itemCategoryUpdate,
    itemCategorySubmitted,
    itemParent1Update,
    itemParent1Submitted,
    itemParent2Update,
    itemParent2Submitted,
    itemParent3Update,
    itemParent3Submitted,
    itemParentRemove,
    itemParentRemoveSubmitted,
    userLoginUpdate,
    userLoginSubmitted
  } = props;
  const { login } = useLogin();
  return (
    <div>
      <Section title="User">
        <List
          dataSource={users}
          renderItem={(item, index) => {
            return (
              <List.Item
                key={index}
                actions={[
                  <Paragraph copyable>{item?._id}</Paragraph>,
                  <To key="preview" href={`/user/profile/${item?._id}`} target="_blank">
                    <Search />
                  </To>,
                  <div key="login" onClick={() => login(item?.username)} className="cursor-pointer">
                    Login
                  </div>
                ]}
              >
                <List.Item.Meta title={item?.username} description={`${item?.job_title} ${item?.department}`} />
              </List.Item>
            );
          }}
        />
      </Section>
      <Section title="Function" className="mt-2">
        <div className="mb-2">
          <Button onClick={itemCategoryUpdate} variant="contained" autoFocus disabled={itemCategorySubmitted}>
            Update item category true/false
          </Button>
        </div>
        <div className="mb-2">
          <Button onClick={itemParentRemove} variant="contained" autoFocus disabled={itemParentRemoveSubmitted}>
            Remove item parent
          </Button>
        </div>
        <div className="mb-2">
          <Button onClick={itemParent1Update} variant="contained" autoFocus disabled={itemParent1Submitted}>
            Update item parent step 1
          </Button>
        </div>
        <div className="mb-2">
          <Button onClick={itemParent2Update} variant="contained" autoFocus disabled={itemParent2Submitted}>
            Update item parent step 2
          </Button>
        </div>
        <div className="mb-2">
          <Button onClick={itemParent3Update} variant="contained" autoFocus disabled={itemParent3Submitted}>
            Update item parent step 3
          </Button>
        </div>
        <div className="mb-2">
          <Divider />
        </div>
        <div className="mb-2">
          <Button onClick={userLoginUpdate} variant="contained" autoFocus disabled={userLoginSubmitted}>
            Update user login username and password
          </Button>
        </div>
      </Section>
    </div>
  );
}

export default withDevIndexPage(DevIndexPage);
