import { useEffect, useRef, useState } from 'react';

export const useInputBoolean = ({ defaultValue, field, setInputs, config }) => {
  const [isOn, setIsOn] = useState(false);
  const isTouched = useRef(null);
  const { readonly } = config;

  useEffect(() => {
    setIsOn(defaultValue || false);
  }, [defaultValue]);

  useEffect(() => {
    if (readonly) return;
    setInputs((v) => {
      return {
        ...v,
        [field]: {
          value: isOn,
          touched: !!isTouched.current
        }
      };
    });
  }, [field, isOn, setInputs, readonly]);

  return {
    isOn,
    setIsOn: (value) => {
      if (!isTouched.current) {
        isTouched.current = true;
      }
      setIsOn(value);
    }
  };
};
