import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/po/index';
import ProfilePage from 'pages/po/profile';

const collection = 'po';
const menuItem = ['PO', 'PO1'];

const routes = [
  <Route path={`/${collection}`} element={<IndexPage menuItem={menuItem} title="PO" collection={collection} />} />,
  <Route
    path={`/${collection}/ready-for-po-items`}
    element={
      <ProfilePage menuItem={['PO', 'PO_READY_FOR_PO_ITEMS']} title="新增PO" collection={collection} editMode="new" />
    }
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="PO" collection={collection} editMode="edit" />}
  />
];

export default routes;
