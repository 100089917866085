import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/user/index';
import ProfilePage from 'pages/user/profile';
import UploadPage from 'pages/user/upload';

const collection = 'user';
const menuItem = ['SETTING', 'USER'];

const routes = [
  <Route
    path={`/${collection}`}
    element={<IndexPage menuItem={menuItem} title="用戶管理" collection={collection} />}
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="用戶" collection={collection} editMode="edit" />}
  />,
  <Route
    path={`/${collection}/copy/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增用戶" collection={collection} editMode="copy" />}
  />,
  <Route
    path={`/${collection}/new`}
    element={<ProfilePage menuItem={menuItem} title="新增用戶" collection={collection} editMode="new" />}
  />,
  <Route
    path={`/${collection}/import`}
    element={<UploadPage menuItem={menuItem} title="批量新增用戶" collection={collection} />}
  />
];

export default routes;
