import { map } from 'lodash';
import React from 'react';
import ItemTable from './components/item-table';
import ItemListTable from './components/itemlist-table';
import InputFileFilename from 'components/common/input-edit/file/filename';
import { parseISODateString } from 'lib/date';

function PrItems(props) {
  const { data } = props;

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="flex flex-col gap-2">
        <div className="text-sm text-zinc-600">物料</div>
        <div className="flex flex-col gap-4">
          <ItemListTable items={data?.pr__items} />
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <div className="text-sm text-zinc-600">物料紀錄</div>
        <div className="flex flex-col gap-4">
          {map(data?.pr__items, (item, index) => (
            <ItemTable item={item} index={index} key={index} />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <div className="text-sm text-zinc-600">報價參考</div>
        <div className="py-2 px-4 border border-zinc-200 rounded text-sm">
          {data?.quote_references
            ? data?.quote_references?.map((reference, index) => (
                <div className="flex gap-2 justify-between">
                  <div className="text-zinc-500">{index + 1}</div>
                  <div className="grow">
                    <InputFileFilename key={reference?._id} value={reference} />
                  </div>
                  <div className="text-zinc-700">{parseISODateString(reference?.updated_at)}</div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default PrItems;
