import { isInteger, isNumber, isNaN } from 'lodash';

export const isPriceFormat = (str, locale, options) => {
  const getLocale = locale || 'en-US';
  const getOptions = options || undefined;

  const sample_options = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  };

  return str ? new Intl.NumberFormat(getLocale, getOptions).format(str) : str;
};
