import InputEdit from 'components/common/input-edit';
import { useDepartment } from './hooks';

function Department({ schema, field, value, setInputs }) {
  const { title, options, is_required } = useDepartment({
    value,
    field,
    setInputs,
    schema
  });
  return (
    <div className="flex gap-2 items-end">
      <div className="grow">
        <InputEdit
          schema={{ title, input_type: 'select', selectOptions: options }}
          field={field}
          value={value}
          setInputs={setInputs}
          overrideSchema={{ is_required: is_required }}
        />
      </div>
    </div>
  );
}

export default Department;
