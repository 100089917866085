import React from 'react';

function Tags({ value }) {
  return (
    <p className="flex gap-2 flex-wrap">
      {value.map((t) => (
        <span key={t} className="bg-zinc-200 text-zinc-700 rounded-md px-2">
          {t}
        </span>
      ))}
    </p>
  );
}

export default Tags;
