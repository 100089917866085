import { find, map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const withSupplier = (WrappedComponent) => {
  return (props) => {
    const { value, controllers, index, config } = props;
    const { editRow } = controllers;
    const { suppliers } = config;

    const [data, setData] = useState({ supplier: { value } });

    useEffect(() => {
      if (data?.supplier?.touched) {
        const supplier = find(suppliers, { _id: data.supplier.value });
        editRow(index, 'supplier', data.supplier?.value);
        editRow(index, 'currency', supplier?.currency || '');
      }
    }, [JSON.stringify(data), index, JSON.stringify(suppliers)]);

    const selectOptions = useMemo(() => {
      const empty = { value: '', label: ' ' };
      return [empty, ...suppliers];
    }, [suppliers]);

    const customValue = useMemo(() => {
      if (value) {
        const supplier = find(selectOptions, { _id: value });
        return supplier?._id;
      }
      return value;
    }, [value, selectOptions]);

    return (
      <WrappedComponent {...props} value={customValue} setInputs={setData} key={index} selectOptions={selectOptions} />
    );
  };
};
