import { map, multiply } from 'lodash';
import React from 'react';
import { withItemListTable } from './hooks';

const Td = (props) => {
  return <td className={`border text-xs py-1 ${props.className || ''}`}>{props.children}</td>;
};
const Th = (props) => {
  return <th className={`border py-1 ${props.className || ''}`}>{props.children}</th>;
};

function PrItemListTable(props) {
  const { items } = props;

  return (
    <table className="text-sm w-full border text-center">
      <thead className="border">
        <Th className="w-60">名稱</Th>
        <Th className="w-40">代號</Th>
        <Th className="w-20">數量</Th>
        <Th className="w-20">單位</Th>
        <Th className="w-60">供應商</Th>
        <Th className="w-60">貨幣</Th>
        <Th className="w-20">供應商單價</Th>
        <Th className="w-20">小計(HKD)</Th>
      </thead>
      <tbody>
        {map(items, (row) => {
          const { name, code, supplier, quantity, unit, currency, price, subtotal, prior } = row;
          return (
            <tr className={`border ${prior ? 'text-red-700' : ''}`} key={row._id}>
              <Td>{name}</Td>
              <Td>{code}</Td>
              <Td>{quantity}</Td>
              <Td>{unit}</Td>
              <Td>{supplier?.code}</Td>
              <Td>{currency}</Td>
              <Td>{price}</Td>
              <Td>{subtotal}</Td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default withItemListTable(PrItemListTable);
