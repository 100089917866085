import React from 'react';
import InputEdit from 'components/common/input-edit';
import { useCloneFrom, withCloneFrom } from './hooks';

function CloneFrom(props) {
  const { schema, value, overrideSchema } = props;
  return <InputEdit {...props} schema={schema} value={value} overrideSchema={overrideSchema} />;
}

export default withCloneFrom(CloneFrom);
