import React, { useContext } from 'react';

import Tag from './tag';

function Tags({ context }) {
  const { config, controllers } = useContext(context);
  const { filters } = config;
  const { removeFilter } = controllers;
  if (!filters) return null;

  let showFilters = filters;
  if (config.collection === 'item') {
    showFilters = filters.filter((filter) => filter.field !== 'is_category');
  }
  return (
    <div className="flex gap-2">
      {showFilters.map((filter, key) => (
        <Tag key={key} filter={filter} onDelete={() => removeFilter(filter)} />
      ))}
    </div>
  );
}

export default Tags;
