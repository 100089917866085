import { filter, isEmpty, map, find } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const usePopup = (props) => {
  const { controllers, config } = props;

  const [selected, setSelected] = useState([]);
  const [selectedPrimaryItem, setSelectedPrimaryItem] = useState(null);
  const [selectedSecondaryItem, setSelectedSecondaryItem] = useState(null);

  const { pushRows, closeModal } = controllers;
  const { items, category_items } = config;

  const pushSelected = (_id) => {
    setSelected((v) => [...v, _id]);
  };

  const pullSelected = (_id) => {
    setSelected((v) => {
      const value = v.filter((item) => item !== _id);
      return value;
    });
  };

  const clearSelected = () => {
    setSelected([]);
  };

  const confirm = useCallback(() => {
    const rows = items.filter((item) => selected.includes(item._id));
    pushRows(rows);

    setSelected([]);
    closeModal();
  }, [items, selected]);

  const primaryCategoryItems = useMemo(() => {
    return map(
      filter(category_items, (item) => item.code.length === 2),
      (item) => ({ value: item.code, label: item.code })
    );
  }, [category_items]);

  const secondaryCategoryItems = useMemo(() => {
    const parent = find(category_items, { code: selectedPrimaryItem });
    if (!parent) return null;
    return map(
      filter(category_items, (item) => item.parent && item.parent.toString() === parent?._id.toString()),
      (item) => ({ value: item.code, label: item.code })
    );
  }, [category_items, selectedPrimaryItem]);

  const rows = useMemo(() => {
    if (!isEmpty(selectedPrimaryItem) && !isEmpty(selectedSecondaryItem)) {
      const parent = find(items, { code: selectedSecondaryItem });
      if (!parent) return null;
      return items.filter((item) => item.parent && item.parent.toString() === parent?._id.toString());
    }
    return items;
  }, [items, selectedPrimaryItem, selectedSecondaryItem]);

  return {
    selected,
    pushSelected,
    pullSelected,
    clearSelected,
    confirm,
    primaryCategoryItems,
    setSelectedPrimaryItem,
    selectedPrimaryItem,

    secondaryCategoryItems,
    setSelectedSecondaryItem,
    selectedSecondaryItem,
    rows
  };
};
