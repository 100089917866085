import React, { useEffect } from 'react';
import { useDataEdit } from 'hooks/use-data-edit';
import { useTopNav } from 'components/common/top-nav/hooks';
import { redirect } from 'lib/location';
import { useEntityLogs } from 'components/common/entity-logs/hooks';
import { useTable } from 'components/common/table/hooks';

export const useProfile = ({ pageProps }) => {
  const { _id, mode, collection, metadata, isLoading, isEditMode, isCopyMode } = pageProps;
  const { userData, contractUserData, userDataIsLoading, userDataRefetch } = useUserData({ _id });
  const url = isEditMode
    ? `/api/collection/${collection}/edit/${_id}`
    : isCopyMode
    ? `/api/collection/${collection}/copy/${_id}`
    : `/api/collection/${collection}/new`;
  const { Component: EntityLogs, refetch: refetchLogs } = useEntityLogs({ _id });
  const { setTopNav } = useTopNav();
  const { data, config, controller, save, canSave } = useDataEdit({
    mode,
    value: pageProps,
    config: { editable: false, ...pageProps },
    url,
    refetch: () => {
      pageProps?.fetchPageData();
      userDataRefetch();
      refetchLogs();
    },
    success:
      mode === 'new' || mode === 'copy'
        ? (result) => {
            if (result && result?._id) {
              redirect(`/${collection}/profile/${result._id}`);
            }
          }
        : undefined
  });

  const { schema, fieldsToDisplay = [], isEdit } = config;
  const { setInputs } = controller;

  useEffect(() => {
    const copy = isEdit
      ? () => {
          redirect(`/${collection}/copy/${_id}`);
        }
      : null;
    const title = `${config?.title} ${data?.code ? '(' + data?.code + ')' : ''} `;
    setTopNav({ save, canSave, copy, title });
  }, [save, canSave, isEdit, _id, data, config]);

  useEffect(() => {
    setTopNav({ save, canSave });
  }, [save, canSave]);

  return {
    _id,
    data,
    userDataIsLoading,
    userData,
    contractUserData,
    config,
    controller,
    save,
    canSave,
    metadata,
    schema,
    fieldsToDisplay,
    setInputs,
    isLoading,
    EntityLogs
  };
};

export const useUserData = (props) => {
  const { _id } = props;

  const {
    controllers: userDataControllers,
    data: userData = [],
    isLoading: userDataIsLoading
  } = useTable({
    url: '/api/collection/user/listing',
    paramsPreset: {
      pageSize: 10000
    }
  });
  const {
    controllers: contractUserDataControllers,
    data: contractUserData = [],
    isLoading: contractUserDataIsLoading
  } = useTable({
    url: '/api/collection/contract-user/listing',
    paramsPreset: {
      pageSize: 10000,
      filters: [
        {
          field: 'contract',
          op: 'eq',
          value: _id
        }
      ]
    }
  });

  return {
    userData,
    contractUserData,
    userDataIsLoading: userDataIsLoading || contractUserDataIsLoading,
    userDataRefetch: () => {
      userDataControllers.refetch();
      contractUserDataControllers.refetch();
    }
  };
};
