import dayjs from 'dayjs';

export const isISODateString = (str) => {
  try {
    const day = new Date(str);
    return day.toISOString() === str;
  } catch {
    return false;
  }
};

export const toISOString = (str) => {
  try {
    if (str) {
      const day = dayjs(str);
      if (day.isValid()) {
        return day.toISOString();
      }
    }
    return undefined;
  } catch {
    return undefined;
  }
};

export const parseISODateString = (str, config) => {
  try {
    if (isISODateString(str)) {
      const day = dayjs(str);
      if (day.isValid()) {
        const { format, show_date_only, show_time_only, show_datetime } = config || {};
        return day.format(
          format
            ? format
            : show_date_only
            ? 'YYYY-MM-DD'
            : show_time_only
            ? 'HH:mm'
            : show_datetime
            ? 'YYYY-MM-DD HH:mm:ss'
            : 'YYYY-MM-DD'
        );
      }
    }
    return str;
  } catch (err) {
    return str;
  }
};

export function getTimeAgo(date) {
  const now = new Date();
  const value = new Date(date);
  const diffInMinutes = Math.floor((now - value) / (1000 * 60));

  if (diffInMinutes < 1) {
    return '剛剛';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}分鐘前`;
  } else if (diffInMinutes < 1440) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours}小時前`;
  } else if (diffInMinutes < 43200) {
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return `${diffInDays}天前`;
  } else {
    return dayjs(value).format('YYYY-MM-DD');
  }
}
