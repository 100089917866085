import React from 'react';
import DataRow from './components/data-row';
import PrDetail from './components/pr-detail';
import { withSummaryForm } from './hooks';
import PrItems from './components/pr-items';
import ApprovalHistory from './components/approval-history';
import Subcontractors from './components/subcontractors';
import To from 'components/common/to';

function PrSummaryForm(props) {
  const { data } = props;

  let title_1 = 'PR-A SUMMARY';
  let title_2 = '工程柯打物料訂購表';

  if (data?.form === 'A' && data?.record_status >= 60) {
    title_1 = 'PR-B SUMMARY';
    title_2 = '工程柯打物料訂購表';
  }
  if (data?.form !== 'A' && data?.record_status < 60) {
    title_1 = 'PR-C SUMMARY';
    title_2 = '非工程柯打物料訂購表';
  }

  if (data?.form !== 'A' && data?.record_status >= 60) {
    title_1 = 'PR-D SUMMARY';
    title_2 = '非工程柯打物料訂購表';
  }

  return (
    <div className="p-4 w-[297mm] h-[210mm]">
      <div className="flex">
        <img className="w-[240px] h-fit" src="/assets/images/logo--dark.png" alt="logo" />
        <div className="ml-12 text-xl grow">
          <h1 className="">{title_1}</h1>
          <h1 className="">{title_2}</h1>
        </div>
        <div className="w-[300px]">
          <To href={`/pr/profile/${data?._id}`} toNew>
            <DataRow title="申請表編號" data={data?.code} />
          </To>
          <DataRow title="合約編號" data={data?.contract?.code} />
        </div>
      </div>
      <PrDetail data={data} />
      <PrItems data={data} />
      <Subcontractors data={data} />
      <ApprovalHistory data={data} />
    </div>
  );
}

export default withSummaryForm(PrSummaryForm);
