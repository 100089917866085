import { find, isEmpty, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const useRowToggle = (props) => {
  const { field, value, schema, setInputs } = props;
  const { toggle_options } = schema;

  const [picked, setPicked] = useState([]);

  const currentPicked = useMemo(() => {
    if (!value || isEmpty(value)) return [];
    return value.map((v) => v.option);
  }, [value]);

  useEffect(() => {
    setPicked(currentPicked);
  }, [currentPicked]);

  const options = useMemo(() => {
    if (!toggle_options || isEmpty(toggle_options)) {
      return [];
    }
    return toggle_options.map((option) => {
      const selected = picked.includes(option._id);
      return {
        ...option,
        selected,
        info: find(value, { option: option._id })
      };
    });
  }, [toggle_options, picked, value]);

  const push = useMemo(() => {
    return options.filter((v) => v.selected && !currentPicked.includes(v._id));
  }, [options, currentPicked]);

  const pull = useMemo(() => {
    return options.filter((v) => !v.selected && currentPicked.includes(v._id));
  }, [options, currentPicked]);

  const selectedOptions = useMemo(() => {
    return options.filter((v) => v.selected);
  }, [options]);
  const notSelectedOptions = useMemo(() => {
    return options.filter((v) => !v.selected);
  }, [options]);

  const selectOption = (_id) => {
    setPicked((v) => [...v, _id]);
  };

  const deselectOption = (_id) => {
    setPicked((v) => v.filter((id) => id !== _id));
  };

  useEffect(() => {
    setInputs((v) => {
      const prev = reduce(
        v,
        (prev, curr, key) => {
          if (!(key.startsWith(`$push.${field}`) || key.startsWith(`$pull.${field}`))) {
            prev[key] = curr;
          }
          return prev;
        },
        {}
      );

      return {
        ...prev,
        ...reduce(
          pull,
          (prev, curr, index) => {
            const { _id } = find(value, { option: curr._id });
            prev[`$pull.${field}[${index}]`] = {
              value: _id,
              touched: true
            };
            return prev;
          },
          {}
        ),
        ...reduce(
          push,
          (prev, curr, index) => {
            prev[`$push.${field}[${index}]`] = {
              value: curr._id,
              touched: true
            };
            return prev;
          },
          {}
        )
      };
    });
  }, [push, pull, field, value]);

  return {
    picked,
    selectedOptions,
    notSelectedOptions,
    selectOption,
    deselectOption
  };
};
