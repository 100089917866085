import * as React from 'react';
import { isEmpty } from 'lodash';
import { Button, Tooltip, Chip, Divider, List, ListSubheader, ListItemText } from '@mui/material';
import To from 'components/common/to';
import { getTimeAgo, parseISODateString } from 'lib/date';
import { withApprovalStatus } from './hooks';

import { Collapse } from 'antd';
import { AccessTime, Close, Done, HourglassEmpty, MoreHoriz, Pending, Person, Print } from '@mui/icons-material';
import FileDetail from 'components/common/input-edit/file/detail';
import Section from 'components/common/section';

function ApprovalStatus(props) {
  const { rows = [], data } = props;
  const items = rows.map((item, i) => ({
    key: i,
    collapsible: item.skip ? 'disabled' : 'header',
    style: {
      backgroundColor: '#fff',
      marginBottom: 16,
      borderRadius: 4,
      boxShadow: '1px 1px #ececec',
      border: '1px solid #eee'
    },
    label: (
      <div className="flex items-center gap-4 w-full bg-white">
        <div className="w-20 text-center text-base text-zinc-700">{item.tier}</div>
        <div className="flex gap-2 items-center text-zinc-700">
          <div
            className={`flex items-center justify-center rounded-full w-6 h-6 ${
              !item.active
                ? 'bg-gray-400'
                : item.is_approve || item.skip || item.subcontractor_verified
                ? 'bg-green-400'
                : 'bg-red-400'
            }`}
          >
            {!item.active ? (
              <MoreHoriz style={{ fontSize: 16, color: '#fff' }} />
            ) : item.is_approve || item.skip || item.subcontractor_verified ? (
              <Done style={{ fontSize: 16, color: '#fff' }} />
            ) : (
              <Close style={{ fontSize: 16, color: '#fff' }} />
            )}
          </div>
          <div className="">
            {!item.active
              ? '未處理'
              : item.is_approve
              ? '已批核'
              : item.skip
              ? '不適用'
              : item.subcontractor_verified
              ? '已提交'
              : '已拒批'}
          </div>
        </div>
        <div className="flex gap-2 grow justify-end items-center mr-2 text-xs">
          {item.active && !item.skip && item?.user ? (
            <>
              <div className="flex gap-2 items-center">
                <div className="">
                  <Person style={{ fontSize: '20px', color: '#323232' }} />
                </div>
                <div className="font-bold">{item?.user?.nickname}</div>
              </div>
              <div className="w-[120px] flex gap-2 items-center justify-end">
                <div className="">
                  <AccessTime style={{ fontSize: '20px', color: '#323232' }} />
                </div>

                <Tooltip title={parseISODateString(item.created_at, { show_datetime: true })} placement="top">
                  <div>{getTimeAgo(item.created_at)}</div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>
      </div>
    ),
    children: !item.skip ? (
      <div className="flex flex-col gap-2">
        {item?.remarks ? (
          <List component="nav" subheader={<ListSubheader component="div">備忘</ListSubheader>}>
            <ListItemText>
              <div className="ml-22">{item?.remarks}</div>
            </ListItemText>
          </List>
        ) : null}
        {item?.attachments ? (
          <>
            <Divider />
            <List component="nav" subheader={<ListSubheader component="div">附件</ListSubheader>}>
              <div className="flex flex-col gap-1">
                {item?.attachments.map((attachment, index) => {
                  return (
                    <div className="flex items-center">
                      <div className="w-20 text-center">{index + 1}</div>
                      <FileDetail value={attachment} readonly={true} />
                    </div>
                  );
                })}
              </div>
            </List>
          </>
        ) : null}
        {!isEmpty(item?.users) && (
          <List component="nav" subheader={<ListSubheader component="div">用戶</ListSubheader>} disablePadding>
            {item.users?.map((user, index) => (
              <ListItemText key={index} sx={{ margin: 0 }}>
                {index > 0 && <Divider />}
                <div className="flex gap-2 items-center py-1 text-zinc-700 text-sm">
                  <div className="w-20 text-center">{index + 1}</div>
                  <div className="flex gap-2 items-center">
                    <span className={`${user?.isMyself ? 'font-bold' : ''}`}>
                      {user?.user?.nickname} {user?.isMyself ? ' [您]' : ''}
                    </span>

                    {user?.level > 10 && <Chip label="Sub" size="small" sx={{ color: '#545454' }} className="" />}
                  </div>

                  <div className="grow flex justify-end">
                    {user?.active && (
                      <Chip
                        avatar={user?.is_approve ? <Done /> : <Close />}
                        label={user?.is_approve ? '已批核' : '已拒批'}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </div>
                </div>
              </ListItemText>
            ))}
          </List>
        )}
      </div>
    ) : null
  }));

  return (
    <div className="">
      <div className="flex justify-end pb-4">
        {/* <Button variant="contained" endIcon={<Print />}>
          <To href={`/pr/summary-form/${data?._id}`} toNew>
            Print Pr View
          </To>
        </Button> */}
      </div>

      <Collapse ghost items={items} expandIconPosition="end" />
    </div>
  );
}

export default withApprovalStatus(ApprovalStatus);
