import React from 'react';
import { withPage } from 'hooks/with-page';
import { useTable } from 'components/common/table/hooks';

function PaymentIndexPage(props) {
  const { collection } = props;
  const paramsPreset = {
    sort: { field: 'code', order: 1 },
    filters: []
  };
  const { Component } = useTable({
    collection,
    url: `/api/collection/${collection}/listing`,
    paramsPreset,
    Components: {
      downloadButton: {
        title: `${collection} listing`,
        active: false
      }
    },
    componentProps: {
      NewEntry: {
        url: `/${collection}/new`
      },
      destination: {
        prefix: `/${collection}/profile/`
      }
    }
  });
  return Component;
}

export default withPage(PaymentIndexPage);
