import './App.css';
import { withApp } from 'store';
import { useAtom } from 'jotai';

import Routes from './routes';
import Document from './document';
import * as store from 'store';
import { redirect } from 'lib/location';

function App({ domReady, userData }) {
  const [pathname] = useAtom(store.pathname);

  if (!domReady) {
    return null;
  } else {
    if (!userData && !pathname.startsWith('/login') && !pathname.startsWith('/register')) {
      redirect('/login');
      return;
    }
    if (
      pathname.startsWith('/login') ||
      pathname.startsWith('/register') ||
      pathname.startsWith('/pr/summary-form') ||
      pathname.startsWith('/403')
    ) {
      return <Routes />;
    }

    return <Document />;
  }
}

export default withApp(App);
