import { useState } from 'react';

export const withItemIndexPage = (WrappedComponent) => {
  return (props) => {
    const [showIsCategoryOnly, setShowIsCategoryOnly] = useState(false);
    const [showIsItemOnly, setShowIsItemOnly] = useState(true);

    const showCategoryHandler = (option) => {
      if (option === 'category') {
        setShowIsCategoryOnly(true);
        setShowIsItemOnly(false);
      } else if (option === 'item') {
        setShowIsItemOnly(true);
        setShowIsCategoryOnly(false);
      }
    };

    return (
      <WrappedComponent
        {...props}
        showCategoryHandler={showCategoryHandler}
        showIsCategoryOnly={showIsCategoryOnly}
        setShowIsCategoryOnly={setShowIsCategoryOnly}
        showIsItemOnly={showIsItemOnly}
        setShowIsItemOnly={setShowIsItemOnly}
      />
    );
  };
};
