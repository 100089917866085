import React from 'react';
import { Button, Card, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Section from 'components/common/section';
import ApprovalTimeline from './components/approval-timeline';
import ApprovalForm from './components/approval-form';
import { withApprovePopup } from './hooks';
import { ClickAwayListener } from '@mui/base';

function ApprovePopup(props) {
  const {
    open,
    onClose,
    onSubmit,
    historyData,
    _id,
    tiers,
    setAttachments,
    selectedTier,
    setApprove,
    remarks,
    setRemarks,
    setSelectedTier,
    approve,
    data,
    submitted
  } = props;

  if (!open) return null;
  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault();
      }}
    >
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth disableEscapeKeyDown scroll="paper">
        <DialogTitle>審批申請</DialogTitle>
        <DialogContent>
          <div className="flex gap-16 items-start justify-center ">
            <Section title="批核時間表" className="w-1/3">
              <div className="">
                <ApprovalTimeline historyData={historyData} data={data} />
              </div>
            </Section>
            <div className="">
              <ApprovalForm
                historyData={historyData}
                tiers={tiers}
                selectedTier={selectedTier}
                setApprove={setApprove}
                setRemarks={setRemarks}
                setAttachments={setAttachments}
                setSelectedTier={setSelectedTier}
                approve={approve}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>取消</Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            autoFocus
            disabled={approve === null || !selectedTier || (approve === false && !remarks) || submitted}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </ClickAwayListener>
  );
}

export default withApprovePopup(ApprovePopup);
