import { Person } from '@mui/icons-material';
import { useAtom } from 'jotai';

import { user } from 'store';
import LogoutButton from './logout-button';

export default function MenuItems() {
  const [_user] = useAtom(user);

  const { username, nickname } = _user || {};
  return (
    <div className="pl-[18px] pr-2 flex gap-2 items-center">
      <Person sx={{ transform: 'scale(0.8)' }} />
      <div className="grow break-all text-sm flex items-center">{nickname ? nickname : username}</div>
      <LogoutButton />
    </div>
  );
}
