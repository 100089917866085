import { useTable } from 'components/common/table/hooks';
import { useFetch } from 'lib/fetch';
import { redirect } from 'lib/location';
import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo } from 'react';

export const withJobType = (WrappedComponent) => {
  return (props) => {
    const { _id, data, field, value, setInputs, schema, inputs, body } = props;

    const readonly = useMemo(() => {
      const a = find(schema, { field });
      return a?.readonly;
    }, [schema, field]);

    const jobType = body?.job_type || data?.job_type;

    return <WrappedComponent {...props} jobType={jobType} />;
  };
};
