import React, { useEffect, useState } from 'react';
import Tab from './components/tab';
import Tabs from './index';

export const useTabs = (props) => {
  const { componentProps = {} } = props || {};

  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.location.hash = `#${tabs[newValue]}`;
  };

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const v = tabs.indexOf(hash.substring(1));
      if (v !== -1) {
        setValue(v);
      }
    }
  }, [tabs]);

  const switchTab = (id) => {
    const v = tabs.indexOf(id);
    if (v !== -1) {
      setValue(v);
    }
  };

  return {
    switchTab,
    tabProps: {
      tabs,
      value,
      setValue,
      handleChange,
      setTabs,
      switchTab
    },
    Component: {
      Tabs,
      Tab
    }
  };
};
