import React from 'react';
import { Tabs as MuiTabs } from '@mui/material';
import { withTabs } from './with-tabs';

function Tabs({ children, value, handleChange, Headers }) {
  return (
    <div>
      <MuiTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        {Headers}
      </MuiTabs>
      <div>{children}</div>
    </div>
  );
}
export default withTabs(Tabs);
