import React from 'react';
import { Divider } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import { withDelivery } from './hooks';

function Delivery(props) {
  const { schema, address, name, phone, options, setRecord, data, setDepartment, readonly } = props;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full gap-4">
        <InputEdit
          schema={schema}
          field="delivery_department"
          value={data?.delivery_department}
          setInputs={setDepartment}
          className="basis-1/3"
          overrideSchema={{ selectOptions: options, input_type: 'select', readonly }}
        />
        <div className="basis-1/3" />
        <div className="basis-1/3" />
      </div>

      <div className="flex w-full gap-4">
        <InputEdit
          schema={schema}
          field="delivery_address"
          value={address}
          setInputs={setRecord}
          className="basis-1/3"
          overrideSchema={{ readonly }}
        />
        <InputEdit
          schema={schema}
          field="delivery_contact_name"
          value={name}
          setInputs={setRecord}
          className="basis-1/3"
          overrideSchema={{ readonly }}
        />
        <InputEdit
          schema={schema}
          field="delivery_contact_phone"
          value={phone}
          setInputs={setRecord}
          className="basis-1/3"
          overrideSchema={{ readonly }}
        />
      </div>
    </div>
  );
}

export default withDelivery(Delivery);
