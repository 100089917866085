import React, { useState } from 'react';
import { withEmails } from './hooks';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { filter, map } from 'lodash';

function Emails(props) {
  const { className, value, title, onChange, options } = props;
  const [freesoloOptions, setFreesoloOptions] = useState([]);
  return (
    <div className={`${className} flex flex-col`}>
      <div class="text-sm mb-1 ml-1">
        <label for="content">{title}</label>
      </div>
      <Autocomplete
        multiple
        freeSolo
        size="small"
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => {
          return option?._id === value?._id;
        }}
        filterOptions={(arr, params) => {
          const newArr = arr;

          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.title);
          if (inputValue !== '' && !isExisting) {
            newArr.push({
              _id: inputValue,
              label: inputValue
            });
          }

          return newArr;
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip size="small" label={option.label || option._id} {...getTagProps({ index })} />
          ));
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
}

export default withEmails(Emails);
