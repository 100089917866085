import { isValueEmpty } from 'lib/input';
import { find, isEmpty, map, reduce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const withSubcontractor = (WrappedComponent) => {
  return (props) => {
    const { data, setInputs, body, order, value, options, readonly } = props;
    const field = `pr__subcontractors[${order}]`;

    const [form, setForm] = useState({
      subcontractor: { value: value?.subcontractor?._id },
      percentage: { value: value?.percentage },
      contact_person: { value: value?.contact_person },
      contact_email: { value: value?.contact_email },
      contact_phone: { value: value?.contact_phone }
    });

    const contact_person = form?.contact_person;
    const contact_email = form?.contact_email;
    const contact_phone = form?.contact_phone;
    const subcontractor = form?.subcontractor;
    const percentage = form?.percentage;

    const contactOptions = useMemo(() => {
      return find(options, { _id: subcontractor?.value }) || {};
    }, [options, subcontractor]);

    const contactSelectOptions = useMemo(() => {
      const selected_subcon = find(options, { _id: subcontractor?.value }) || {};
      const contacts = selected_subcon?.contacts || null;

      if (!contacts) return {};
      return contacts.map((row, index) => ({
        _id: index,
        label: row?.person,
        person: row?.person,
        email: row?.email,
        phone: row?.phone
      }));
    }, [options, subcontractor]);

    const showClearButton = !readonly && (subcontractor?.value || percentage?.value);
    const isContactRequired = subcontractor?.value;
    const isContactReadonly = !isContactRequired || readonly;

    const clear = useCallback(() => {
      setForm({
        subcontractor: { value: '', touched: true },
        percentage: { value: '', touched: true },
        contact_person: { value: '', touched: true },
        contact_email: { value: '', touched: true },
        contact_phone: { value: '', touched: true }
      });
    }, []);

    useEffect(() => {
      if (body?.contract) {
        clear();
      }
    }, [body?.contract, clear]);

    const setContact = useCallback(
      (select_value) => {
        if (select_value >= 0) {
          // if (!contact_person?.value && !contact_email?.value && !contact_phone?.value) {
          const contact_info = find(contactSelectOptions, { _id: select_value }) || {};
          setForm({
            subcontractor: { value: subcontractor?.value, touched: true },
            percentage: { value: percentage?.value, touched: true },
            contact_person: { value: contact_info?.person, touched: true },
            contact_email: { value: contact_info?.email, touched: true },
            contact_phone: { value: contact_info?.phone, touched: true }
          });
          // }
        }
      },
      [contactSelectOptions, subcontractor, percentage, contact_person, contact_email, contact_phone]
    );

    useEffect(() => {
      setInputs((v) => {
        return {
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!key.startsWith(`$edit.${field}`)) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          ),
          ...reduce(
            form,
            (prev, curr, key) => {
              const oldValue = value?.[key]?._id || value?.[key];
              if (curr?.value !== oldValue) {
                prev[`$edit.${field}.${key}`] = curr;
              }
              return prev;
            },
            {}
          )
        };
      });
      // return () => {
      //   setInputs((v) => {
      //     return {
      //       ...reduce(
      //         v,
      //         (prev, curr, key) => {
      //           if (!key.startsWith(`$edit.${field}`)) {
      //             prev[key] = curr;
      //           }
      //           return prev;
      //         },
      //         {}
      //       )
      //     };
      //   });
      // };
    }, [JSON.stringify(form)]);

    return (
      <WrappedComponent
        {...props}
        field={field}
        setForm={setForm}
        subcontractor={subcontractor}
        percentage={percentage}
        contact_person={contact_person}
        contact_email={contact_email}
        contact_phone={contact_phone}
        clear={clear}
        contactOptions={contactOptions}
        setContact={setContact}
        contactSelectOptions={contactSelectOptions}
        showClearButton={showClearButton}
        isContactRequired={isContactRequired}
        isContactReadonly={isContactReadonly}
      />
    );
  };
};
