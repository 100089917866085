import React from 'react';
import { withPage } from 'hooks/with-page';
import { useTable } from 'components/common/table/hooks';

function UserIndexPage(props) {
  const { collection } = props;
  const paramsPreset = {
    filters: []
  };
  const { Component } = useTable({
    collection: 'user',
    url: '/api/collection/user/listing',
    paramsPreset,
    Components: {
      downloadButton: {
        title: 'user listing',
        active: false
      }
    },
    componentProps: {
      NewEntry: {
        url: `/${collection}/new`
      },
      destination: {
        prefix: `/user/profile/`
      }
    }
  });
  return Component;
}

export default withPage(UserIndexPage);
