import { find, forEach, isObject, isPlainObject, isUndefined, isEmpty } from 'lodash';
import { getDataDisplayComponent } from 'components/common/data-display';
import { isArrayOfString } from 'lib/input';

export const withSchemaConfig = (WrappedComponent) => {
  return (props) => {
    const { schema = [], field, value, config = {}, mode = 'edit', overrideSchema } = props || {};
    let Component = null;
    let setting = {
      editable: true,
      readonly: false,
      is_boolean: false,
      is_textarea: false,
      is_money: false,
      is_integer: false,
      is_number: false,
      is_id: false,
      is_phone: false,
      is_email: false,
      is_password: false,
      is_date: false,
      is_percentage: false,
      is_file: false,
      is_row_toggle: false,
      is_array_of_string: false,
      is_multiple: false,

      input_type: null,
      input_variant: null,

      show_date_only: false,
      show_time_only: false
    };

    let fieldSchema = undefined;
    if (field) {
      fieldSchema = find(schema, { field });
    }
    if (!fieldSchema && isPlainObject(schema)) {
      fieldSchema = schema;
    }
    if (!isEmpty(overrideSchema)) {
      fieldSchema = {
        ...fieldSchema,
        ...overrideSchema
      };
    }
    if (isArrayOfString(value)) {
      setting.is_array_of_string = true;
    }
    if (fieldSchema) {
      setting = {
        ...setting,
        ...fieldSchema
      };

      switch (fieldSchema.type) {
        case 'number':
          setting.is_number = true;
          break;
        case 'boolean':
          setting.is_boolean = true;
          break;
        case 'date':
          setting.is_date = true;
          setting.show_ago = field === 'updated_at' || field === 'created_at';
          setting.format = config?.format || fieldSchema?.format || 'YYYY-MM-DD';
          break;
        case 'relation':
          if (fieldSchema?.foreign === 'file') {
            setting.is_file = true;
          }
          break;
        default:
          break;
      }
    }
    if (mode !== 'edit' && setting.editable) {
      setting.readonly = false;
    }

    field === 'is_active' && console.log(setting, mode);

    Component = getDataDisplayComponent({ value, config: setting });

    return <WrappedComponent {...props} config={setting} Component={Component} />;
  };
};
