import React from 'react';
import { Checkbox, Divider } from 'antd';
import { withUserPermission } from './hooks';
import { map } from 'lodash';
import Tooltip from 'components/common/tooltip';

const CheckboxGroup = Checkbox.Group;

function UserPermission(props) {
  const { options, value, onChange } = props;
  return map(options, (array, group) => (
    <div className="flex flex-col gap-2 border-b py-4">
      <div className="text-md">{group}</div>
      <div className="ml-4">
        <Tooltip title={group == '系統管理員' ? '系統管理員可以造訪所有頁面' : ''}>
          <CheckboxGroup options={array} value={value} onChange={(v) => onChange(v, group)} />
        </Tooltip>
      </div>
    </div>
  ));
}

export default withUserPermission(UserPermission);
