import { useCollectionListing } from 'hooks/use-collection-listing';
import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTable } from 'components/common/table/hooks';

export const withDelivery = (WrappedComponent) => {
  return (props) => {
    const { setInputs, data } = props;
    const { deliveryData } = useDeliveryData();
    const [record, setRecord] = useState({});

    const [department, setDepartment] = useState({});
    const [address, setAddress] = useState(data?.delivery_address);
    const [name, setName] = useState(data?.delivery_contact_name);
    const [phone, setPhone] = useState(data?.delivery_contact_phone);

    const options = useMemo(() => {
      if (!deliveryData) return [];
      return deliveryData.map((row) => ({
        _id: row?._id,
        label: row?.department
      }));
    }, [deliveryData]);

    useEffect(() => {
      const input = reduce(
        record,
        (prev, curr, key) => {
          if (curr.touched) {
            prev[key] = curr;
          }
          return prev;
        },
        {}
      );

      if (!isEmpty(input)) {
        setInputs((inputs) => ({
          ...inputs,
          ...input
        }));
      }
    }, [JSON.stringify(record)]);

    useEffect(() => {
      if (department?.delivery_department?.touched) {
        setInputs((inputs) => ({
          ...inputs,
          ...department
        }));
        const obj = find(deliveryData, { _id: department?.delivery_department?.value });
        if (obj) {
          const { delivery_address, contact_name, contact_phone } = obj;
          setRecord({
            delivery_address: {
              value: delivery_address,
              error: null,
              touched: true
            },
            delivery_contact_name: {
              value: contact_name,
              error: null,
              touched: true
            },
            delivery_contact_phone: {
              value: contact_phone,
              error: null,
              touched: true
            }
          });
          setAddress(delivery_address);
          setName(contact_name);
          setPhone(contact_phone);
        }
      }
    }, [JSON.stringify(department), JSON.stringify(deliveryData)]);

    return (
      <WrappedComponent
        {...props}
        options={options}
        setRecord={setRecord}
        address={address}
        name={name}
        phone={phone}
        record={record}
        setDepartment={setDepartment}
      />
    );
  };
};

export const useDeliveryData = () => {
  const { data } = useTable({
    url: '/api/collection/delivery/listing',
    paramsPreset: {
      pageSize: 10000
    }
  });

  return {
    deliveryData: data
  };
};
