import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useInputRichTextEditor } from './hooks';

function InputRichTextEditor(props) {
  const { field, value, setInputs, config } = props;
  const { text, setText } = useInputRichTextEditor({ defaultValue: value, setInputs, field, config });
  return (
    <div>
      <ReactQuill theme="snow" value={text} onChange={setText} />
    </div>
  );
}

export default InputRichTextEditor;
