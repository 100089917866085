import { useEffect, useRef, useState } from 'react';

export const useInputRadio = ({ defaultValue, field, setInputs, config }) => {
  const [selected, setSelected] = useState(null);
  const isTouched = useRef(null);

  const { readonly } = config;

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (readonly) return;
    setInputs((v) => {
      return {
        ...v,
        [field]: {
          value: selected,
          touched: !!isTouched.current
        }
      };
    });
  }, [field, selected, setInputs, readonly]);

  return {
    selected,
    setSelected: (value) => {
      if (!isTouched.current) {
        isTouched.current = true;
      }
      setSelected(value);
    }
  };
};
