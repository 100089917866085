import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withImpendingNumber } from './hooks';

function ImpendingNumber(props) {
  const { field, value, setInputs, schema } = props;
  return (
    <InputEdit
      field={field}
      schema={schema}
      setInputs={setInputs}
      value={value}
      className="basis-1/3"
      overrideSchema={{ is_required: true }}
    />
  );
}

export default withImpendingNumber(ImpendingNumber);
