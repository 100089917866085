import { difference, filter, groupBy, isEmpty, isEqual, map, reduce, union, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const userPermissionOptions = [
  {
    label: '系統管理員',
    value: 'IS_ADMIN',
    group: '系統管理員'
  },
  {
    label: '物料',
    value: 'ITEM',
    group: '資料庫'
  },
  {
    label: '供應商',
    value: 'SUPPLIER',
    group: '資料庫'
  },
  {
    label: '合約',
    value: 'CONTRACT',
    group: '資料庫'
  },
  {
    label: '判頭',
    value: 'SUBCONTRACTOR',
    group: '資料庫'
  },
  {
    label: '貨幣',
    value: 'CURRENCY',
    group: '資料庫'
  },
  {
    label: '送貨地址',
    value: 'DELIVERY',
    group: '資料庫'
  },
  {
    label: '申請',
    value: 'PR1',
    group: '採購'
  },
  {
    label: '等待報價',
    value: 'PRAQ',
    group: '採購'
  },
  {
    label: '待批報價',
    value: 'PRAQV',
    group: '採購'
  },
  {
    label: '待批採購單',
    value: 'PRMAA',
    group: '採購'
  },
  {
    label: '批准狀況',
    value: 'PRAA',
    group: '採購'
  },
  {
    label: '待補WO No.',
    value: 'PRAWO',
    group: '採購'
  },

  {
    label: '已批PR',
    value: 'PO_READY_FOR_PO_ITEMS',
    group: 'PO'
  },
  {
    label: '已出PO',
    value: 'PO1',
    group: 'PO'
  },

  {
    label: '系統設置',
    value: 'SETTING',
    group: '系統設置'
  }
];

export const withUserPermission = (Component) => (props) => {
  const { data, setInputs } = props;
  const { is_admin, permissions_read } = data || {};
  const options = groupBy(userPermissionOptions, 'group');
  const [checkedList, setCheckedList] = useState([]);
  const [checkedListByGroup, setCheckedListByGroup] = useState({});

  useEffect(() => {
    let arr = [];

    if (is_admin) {
      arr.push('IS_ADMIN');
    }

    if (permissions_read) {
      const record = permissions_read.filter((p) => p !== '*');
      arr = [...arr, ...record];
    }
    setCheckedList(arr);
  }, [data, is_admin, permissions_read]);

  const onChange = (v, group) => {
    setCheckedListByGroup((list) => ({
      ...list,
      [group]: v
    }));
  };

  useEffect(() => {
    if (!isEmpty(checkedListByGroup)) {
      setCheckedList((list) =>
        reduce(
          checkedListByGroup,
          (acc, pickedOptions, group) => {
            const groupOptions = map(options[group], 'value');

            return [...acc.filter((v) => !groupOptions.includes(v)), ...pickedOptions];
          },
          list
        )
      );
    }
  }, [checkedListByGroup]);

  const edit = useMemo(() => {
    const originalList = filter(permissions_read, (i) => i !== '*');
    const newList = filter(checkedList, (i) => i !== 'IS_ADMIN' && i !== '*');

    if (!isEmpty(originalList) && isEmpty(newList)) {
      return {
        [`$pull.permissions_read`]: {
          value: true,
          touched: true
        }
      };
    }

    if (
      !isEmpty(difference(newList, originalList)) ||
      !isEmpty(difference(originalList, newList)) ||
      isEmpty(originalList)
    ) {
      return reduce(
        newList,
        (prev, curr, key) => {
          prev[`$edit.permissions_read[${key}]`] = {
            value: curr,
            touched: true
          };
          return prev;
        },
        {}
      );
    }
  }, [checkedList, permissions_read]);

  useEffect(() => {
    const isAdmin =
      !is_admin && checkedList.includes('IS_ADMIN')
        ? true
        : is_admin && !checkedList.includes('IS_ADMIN')
        ? false
        : undefined;
    setInputs((v) => {
      return {
        ...reduce(
          v,
          (prev, curr, key) => {
            if (!(key.startsWith('$edit.permissions_read') || key.startsWith('$pull.permissions_read'))) {
              prev[key] = curr;
            }
            return prev;
          },
          {}
        ),
        is_admin: {
          value: isAdmin,
          touched: true,
          error: false
        },
        ...edit
      };
    });
  }, [checkedList, is_admin, edit, setInputs]);

  return <Component {...props} options={options} value={checkedList} onChange={onChange} />;
};
