import { useAlert } from 'components/alert-popup/store';
import { useTable } from 'components/common/table/hooks';
import { useFetch } from 'lib/fetch';
import { redirect } from 'lib/location';
import { useCallback, useEffect } from 'react';

export const withDevIndexPage = (WrappedComponent) => {
  return (props) => {
    const { data: users } = useTable({
      url: `/api/collection/user/listing`,
      paramsPreset: {
        pageSize: 99999
      }
    });

    const { itemCategoryUpdate, itemCategoryLoading, itemCategoryResult } = useItemCategory();
    const { itemParentRemove, itemParentRemoveLoading, itemParentRemoveResult } = useItemParentRemove();
    const { itemParent1Update, itemParent1Loading, itemParent1Result } = useItemParent1();
    const { itemParent2Update, itemParent2Loading, itemParent2Result } = useItemParent2();
    const { itemParent3Update, itemParent3Loading, itemParent3Result } = useItemParent3();

    const { userLoginUpdate, userLoginUpdateLoading, userLoginUpdateResult } = useUpdateUserLogin();

    return (
      <WrappedComponent
        {...props}
        users={users}
        itemCategoryUpdate={itemCategoryUpdate}
        itemCategorySubmitted={itemCategoryLoading}
        itemParentRemove={itemParentRemove}
        itemParentRemoveSubmitted={itemParentRemoveLoading}
        itemParent1Update={itemParent1Update}
        itemParent1Submitted={itemParent1Loading}
        itemParent2Update={itemParent2Update}
        itemParent2Submitted={itemParent2Loading}
        itemParent3Update={itemParent3Update}
        itemParent3Submitted={itemParent3Loading}
        userLoginUpdate={userLoginUpdate}
        userLoginSubmitted={userLoginUpdateLoading}
      />
    );
  };
};

export const useItemCategory = (params) => {
  const { fetch, isLoading, result } = useFetch();

  const itemCategoryUpdate = () => {
    fetch('POST', `/api/collection/item/db/update_category/`);
  };

  return {
    itemCategoryUpdate,
    itemCategoryResult: result,
    itemCategoryLoading: isLoading
  };
};

export const useItemParent1 = (params) => {
  const { fetch, isLoading, result } = useFetch();

  const itemParent1Update = () => {
    fetch('POST', `/api/collection/item/db/update_parent1/`);
  };

  return {
    itemParent1Update,
    itemParent1Result: result,
    itemParent1Loading: isLoading
  };
};

export const useItemParent2 = (params) => {
  const { fetch, isLoading, result } = useFetch();

  const itemParent2Update = () => {
    fetch('POST', `/api/collection/item/db/update_parent2/`);
  };

  return {
    itemParent2Update,
    itemParent2Result: result,
    itemParent2Loading: isLoading
  };
};

export const useItemParent3 = (params) => {
  const { fetch, isLoading, result } = useFetch();

  const itemParent3Update = () => {
    fetch('POST', `/api/collection/item/db/update_parent3/`);
  };

  return {
    itemParent3Update,
    itemParent3Result: result,
    itemParent3Loading: isLoading
  };
};

export const useItemParentRemove = (params) => {
  const { fetch, isLoading, result } = useFetch();

  const itemParentRemove = () => {
    fetch('POST', `/api/collection/item/db/remove_parent/`);
  };

  return {
    itemParentRemove,
    itemParentRemoveResult: result,
    itemParentRemoveLoading: isLoading
  };
};

export const useUpdateUserLogin = (params) => {
  const { fetch, isLoading, result } = useFetch();

  const userLoginUpdate = () => {
    fetch('POST', `/api/collection/user/db/update_username/`);
  };

  return {
    userLoginUpdate,
    userLoginUpdateResult: result,
    userLoginUpdateLoading: isLoading
  };
};

export const useLogin = () => {
  const { fetch, result } = useFetch();
  const { setAlert } = useAlert();
  const login = (username) => {
    if (username) {
      fetch('POST', '/api/collection/user/login', { params: { username, password: '1234' } });
    }
  };

  useEffect(() => {
    if (result) {
      setAlert({ message: 'DONE' });
    }
  }, [result]);
  return { login };
};
