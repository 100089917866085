export const isDev = process.env.NODE_ENV === 'development';

export const host = `${window.location.protocol}//${window.location.hostname}`;
export const basename = '';
export const backend = isDev ? 'http://localhost' : '';

export const apikey = process.env.apikey || 'y6bslj9t15YkjqGDbkdOf2qLScLsef1A';

export const approval_status_map = {
  10: 'Pending',
  20: '等待報價',
  30: '待批報價',
  35: '核實報價',
  40: '提交批核',
  50: 'Approval 1',
  60: 'Approval 2-4',
  70: 'Approval 5-7'
  // 60: 'Completed',
  // 70: 'Rejected',
  // 80: 'Void',
  // 90: 'Canceled'
};

export const tier_list = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7'];
