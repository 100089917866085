import React from 'react';
import Section from 'components/common/section';
import UserRow from './components/row';
import { withIssue } from './hooks';
import { map, reduce } from 'lodash';

import TableLayout from 'components/common/table-layout';
import CsrTable from 'components/common/csr-table';
import InputEdit from 'components/common/input-edit';
import { tier_list } from 'lib/config';

function Issue(props) {
  const { data, schema, setInputs, userData, contractUserData, rows, userDataIsLoading } = props;

  // return (
  //   <div className="flex flex-col gap-2">
  //     <TableLayout
  //       className="w-max-content mx-auto bg-white rounded-xl table-fixed"
  //       isLoading={userDataIsLoading}
  //       showEmpty={!userDataIsLoading && !userData?.length}
  //       thead={
  //         <thead>
  //           <tr className="text-center border-b-2 text-sm text-zinc-500">
  //             {/* <th className="px-4 w-12"></th> */}
  //             <th className="w-28 py-4 text-zinc-800 text-base text-center ">用戶</th>
  //             <th className="px-2">Issue</th>
  //             <th className="px-2">A1</th>
  //             <th className="px-2">A2</th>
  //             <th className="px-2">A3</th>
  //             <th className="px-2">A4</th>
  //             <th className="px-2">A5</th>
  //             <th className="px-2">A6</th>
  //             <th className="px-2">A7</th>
  //           </tr>
  //         </thead>
  //       }
  //     >
  //       <tbody>
  //         {map(userData, (user, index) => {
  //           return (
  //             <UserRow
  //               {...props}
  //               key={index}
  //               user={user}
  //               contractData={contractUserData}
  //               className={`px-2 hover:bg-zinc-50`}
  //             />
  //           );
  //         })}
  //       </tbody>
  //     </TableLayout>
  //   </div>
  // );

  const commonProps = {
    sortable: false,
    searchable: false
  };

  const columns = [
    {
      ...commonProps,
      fixed: 'left',
      title: '用戶',
      dataIndex: 'username',
      key: 'username'
    },
    ...map(['Issue', ...tier_list], (tier) => ({
      ...commonProps,
      title: tier,
      dataIndex: tier,
      key: tier,
      render: (_, value, item) => (
        <InputEdit setInputs={setInputs} field={`[${value.user_id}].${tier}`} value={value[tier]} schema={schema} />
      )
    }))
  ];

  return (
    <CsrTable
      columns={columns}
      data={rows}
      size="small"
      scroll={{
        x: 800,
        y: 600
      }}
      pagination={false}
      bordered
    />
  );
}

export default withIssue(Issue);
