import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/contract/index';
import ProfilePage from 'pages/contract/profile';

const collection = 'contract';
const menuItem = ['DATABASE', 'CONTRACT'];

const routes = [
  <Route
    path={`/${collection}`}
    element={<IndexPage menuItem={menuItem} title="合約管理" collection={collection} />}
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="合約" collection={collection} editMode="edit" />}
  />,
  <Route
    path={`/${collection}/copy/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增合約" collection={collection} editMode="copy" />}
  />,
  <Route
    path={`/${collection}/new`}
    element={<ProfilePage menuItem={menuItem} title="新增合約" collection={collection} editMode="new" />}
  />
];

export default routes;
