import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withContractors } from './hooks';
import Subcontractor from './components/subcontractor';
import { map, max, min, range } from 'lodash';

function Contractors(props) {
  const { body, data, options, readonly, setInputs, length } = props;
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="text-md ml-1">
        <label>訂購 (扣數)*</label>
      </div>
      {map(range(min([length, 3])), (key) => (
        <Subcontractor
          setInputs={setInputs}
          key={key}
          options={options}
          order={key}
          readonly={readonly}
          value={data?.pr__subcontractors?.[key]}
          data={data}
          body={body}
        />
      ))}
    </div>
  );
}

export default withContractors(Contractors);
