import React from 'react';
import { Divider } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';
import Currency from './components/currency';
import Contact from './components/contact';

function General(props) {
  const { data, schema, setInputs, mode, body } = props;

  const inputEditProps = {
    schema,
    setInputs,
    mode
  };

  return (
    <Section title="General">
      <div className="flex flex-col gap-2">
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="code" value={data?.code} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="short_name" value={data?.short_name} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="chin_name" value={data?.chin_name} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="eng_name" value={data?.eng_name} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="chin_address" value={data?.chin_address} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="eng_address" value={data?.eng_address} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="br_no" value={data?.br_no} className="basis-1/3" />
          <InputEdit {...inputEditProps} field="br_expire" value={data?.br_expire} className="basis-1/3" />
          <InputEdit {...inputEditProps} field="business_nature" value={data?.business_nature} className="basis-1/3" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="phone_number" value={data?.phone_number} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="fax_number" value={data?.fax_number} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="payment_terms" value={data?.payment_terms} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="shipping_terms" value={data?.shipping_terms} className="basis-1/2" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit
            {...inputEditProps}
            field="shipping_description"
            value={data?.shipping_description}
            className="grow"
          />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="remark" value={data?.remark} className="grow" />
        </div>
        <div className="flex w-full gap-4">
          <InputEdit
            {...inputEditProps}
            field="product_services"
            value={data?.product_services}
            className="basis-1/4"
          />
          <Currency {...inputEditProps} field="currency" value={data?.currency} className="basis-1/4" />
          {/* <InputEdit {...inputEditProps} field="approval_state" value={data?.approval_state} className="basis-1/4" /> */}
          {/* <InputEdit {...inputEditProps} field="is_active" value={data?.is_active} className="basis-1/4" /> */}
        </div>
        <div className="my-2">
          <Divider />
        </div>
        <div className="flex w-full">
          <Contact {...inputEditProps} value={data?.contacts} body={body} />
        </div>
        <div className="my-2">
          <Divider />
        </div>
      </div>
    </Section>
  );
}

export default General;
