import { useSideMenu } from 'components/common/side-menu/hooks';
import { useTopNav } from 'components/common/top-nav/hooks';
import { useEffect } from 'react';
import { useUserPagePermission } from './use-user-page-permission';
import { redirect } from 'lib/location';

export const withPage = (WrappedComponent) => {
  return (props) => {
    const { menuItem, title } = props || {};
    const { setTopNav } = useTopNav();
    const { setSideMenuActiveItem } = useSideMenu();

    const { hasPermission } = useUserPagePermission({ menuItem });

    useEffect(() => {
      if (title) {
        setTopNav({ title });
      }
    }, []);

    useEffect(() => {
      if (menuItem) {
        setSideMenuActiveItem(menuItem);
      }
    }, []);

    if (!hasPermission) {
      return redirect('/403');
    }

    return <WrappedComponent {...props} />;
  };
};
