export const opStringMap = {
  eq: '等於',
  gt: '大於',
  lt: '小於',
  gte: '大於或等於',
  lte: '小於或等於',
  regex: '中含有',
  regexstart: '字首是',
  ne: '不等於'
};

export const useTableFilter = () => {};
