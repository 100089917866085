import React from 'react';
import { parseISODateString } from 'lib/date';
import { compact } from 'lodash';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: 12
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'start',
    fontSize: 12
  },
  infoTitle: {
    paddingRight: 10
  },
  infoText: {
    borderBottom: '1px solid black',
    width: 140,
    height: 20,
    textAlign: 'center',
    paddingRight: 10
  },
  infoTable: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    fontSize: 12
  },
  infoTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoCell: {
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  infoCell1: {
    flexBasis: 30
  },
  infoCell2: {
    borderBottom: '1px solid #000',
    flexBasis: 55
    // textAlign: 'right'
  }
});

function PrInformation(props) {
  const { data } = props;
  return (
    <>
      <View style={[styles.paragraph, styles.info]}>
        {data?.form === 'A' && (
          <>
            <View style={[styles.infoTable]}>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>1. 日期:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{parseISODateString(data?.application_date)}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>2. 申請人:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.apply_by}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>聯絡電話:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.contact_number}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>3. 訂購(扣數):</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>
                  {compact(
                    data?.pr__subcontractors?.map((row) => {
                      if (row?.subcontractor?.code && row?.subcontractor?.chin_name) {
                        return `${row?.subcontractor?.code} - ${row?.subcontractor?.chin_name} (${row?.percentage}%)`;
                      }
                      return null;
                    })
                  ).join(', ')}
                </Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>4. 區份:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.district_code}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>5. 工程性質:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.pr__items_nature}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>6. Job Type:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.jot_type_label || data?.job_type}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>/ No.:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.eo_number}</Text>
              </View>
            </View>
            <View style={[styles.infoTable]}>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>7. 需註明原因:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.reason}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>8. Works Order No.:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.wo_number}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>9. 發出日期:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{parseISODateString(data?.send_date)}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>10. 預計開工日期:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{parseISODateString(data?.start_date)}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>11. 預計完工日期:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{parseISODateString(data?.end_date)}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>12. 預算總額:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.budget}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>13. 備註:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.remark}</Text>
              </View>
            </View>
          </>
        )}
        {data?.form === 'C' && (
          <>
            <View style={[styles.infoTable]}>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>1. 日期:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{parseISODateString(data?.application_date)}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>2. 申請人:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.apply_by}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>聯絡電話:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.contact_number}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>3. 訂購(扣數):</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>
                  {compact(
                    data?.pr__subcontractors?.map((row) => {
                      if (row?.subcontractor?.code && row?.subcontractor?.chin_name) {
                        return `${row?.subcontractor?.code} - ${row?.subcontractor?.chin_name} (${row?.percentage}%)`;
                      }
                      return null;
                    })
                  ).join(', ')}
                </Text>
              </View>
            </View>
            <View style={[styles.infoTable]}>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>4. 工程性質:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.pr__items_nature}</Text>
              </View>
              <View style={[styles.infoTableRow]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>5. 備註:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{data?.remark} </Text>
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
}

export default PrInformation;
