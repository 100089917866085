import React from 'react';
import { useProfile } from './hooks';
import { withProfilePage } from 'hooks/with-profile-page';

import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';
import { useTabs } from 'components/common/tabs/hooks';
import ItemUsers from './components/user-item';
import Metadata from 'components/common/metadata';
import CloneFrom from './components/clone-from';
import MergeTo from './components/merge-to';
import Parent from './components/parent';
import Code from './components/code';

function ItemProfilePage(pageProps) {
  const {
    _id,
    body,
    data,
    metadata,
    schema,
    setInputs,
    inputs,
    isCopyMode,
    itemOptions,
    parentIsCategoryOptions,
    EntityLogs
  } = useProfile({
    pageProps
  });

  const { isEditMode, mode } = pageProps;

  const { Component, tabProps } = useTabs();
  const { Tabs, Tab } = Component;

  const inputEditProps = {
    mode,
    schema,
    setInputs
  };

  return (
    <div className="flex flex-col gap-4">
      <Metadata metadata={metadata} />
      <Tabs {...tabProps}>
        <Tab title="Basic Information" id="info">
          <div className="flex flex-col gap-4">
            <Section title="General Information">
              <div className="flex flex-col gap-2">
                <div className="flex w-full gap-4">
                  <Code
                    {...inputEditProps}
                    field="code"
                    value={data?.code}
                    body={body}
                    mode={mode}
                    data={data}
                    inputs={inputs}
                    setInputs={setInputs}
                    _id={_id}
                    className="basis-1/2"
                  />
                  <MergeTo
                    {...inputEditProps}
                    field="merge_to"
                    value={data?.merge_to}
                    inputs={inputs}
                    mode={mode}
                    _id={_id}
                    itemOptions={itemOptions}
                    className="basis-1/2"
                  />
                </div>
                <div className="flex w-full gap-4">
                  <Parent
                    {...inputEditProps}
                    field="parent"
                    value={data?.parent}
                    inputs={inputs}
                    mode={mode}
                    _id={_id}
                    itemOptions={parentIsCategoryOptions}
                    className="basis-1/2"
                  />
                  <CloneFrom
                    {...inputEditProps}
                    field="clone_from"
                    value={isCopyMode ? _id : data?.clone_from}
                    inputs={inputs}
                    mode={mode}
                    _id={_id}
                    itemOptions={itemOptions}
                    className="basis-1/2"
                  />
                </div>
                <div className="">
                  <InputEdit {...inputEditProps} field="name" value={data?.name} />
                </div>
                <div className="">
                  <InputEdit {...inputEditProps} field="type" value={data?.type} />
                </div>
                <div className="flex gap-4">
                  <InputEdit {...inputEditProps} field="is_service" value={data?.is_service} />
                  <InputEdit {...inputEditProps} field="unit" value={data?.unit} />
                  <InputEdit {...inputEditProps} field="moq" value={data?.moq} />
                  <InputEdit {...inputEditProps} field="nature" value={data?.nature} />
                  <InputEdit {...inputEditProps} field="is_category" value={data?.is_category} />
                  <InputEdit {...inputEditProps} field="can_select" value={data?.can_select} />
                </div>
                <div className="">
                  <InputEdit {...inputEditProps} field="description" value={data?.description} />
                </div>
              </div>
            </Section>
            <Section title="最新報價">
              <div className="flex flex-col gap-2">
                <div className="flex gap-4">
                  <InputEdit {...inputEditProps} field="latest_quote_price" value={data?.latest_quote_price} />
                </div>
              </div>
            </Section>
            <Section title="For PR Form">
              <div className="flex flex-col gap-2">
                <div className="flex gap-4">
                  <InputEdit {...inputEditProps} field="form_ab" value={data?.form_ab} />
                  <InputEdit {...inputEditProps} field="form_cd" value={data?.form_cd} />
                </div>
              </div>
            </Section>
            <Section title="For Analysis">
              <div className="flex flex-col gap-2">
                <div className="flex w-full gap-4">
                  <InputEdit {...inputEditProps} field="admin" value={data?.admin} className="basis-1/2" />
                  <InputEdit {...inputEditProps} field="purchase" value={data?.purchase} className="basis-1/2" />
                </div>
                <div className="flex w-full gap-4">
                  <InputEdit {...inputEditProps} field="account" value={data?.account} className="basis-1/2" />
                  <InputEdit {...inputEditProps} field="bill" value={data?.bill} className="basis-1/2" />
                </div>
              </div>
            </Section>
            <Section title="For ANSI PO/PC">
              <div className="flex flex-col gap-2">
                <div className="flex gap-4">
                  <InputEdit {...inputEditProps} field="show_price" value={data?.show_price} />
                  <InputEdit {...inputEditProps} field="show_item" value={data?.show_item} />
                </div>
              </div>
            </Section>
          </div>
        </Tab>
        {/* {isEditMode ? (
          <Tab title="Logs" id="logs">
            <Section title="Logs">{EntityLogs}</Section>
          </Tab>
        ) : null} */}
      </Tabs>
    </div>
  );
}

export default withProfilePage(ItemProfilePage);
