import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

const emailList = [
  {
    _id: '{issuer_email}',
    label: '{issuer_email}'
  },
  {
    _id: '{pd_email}',
    label: '{pd_email}'
  },
  {
    _id: '{pd_manager_email}',
    label: '{pd_manager_email}'
  },
  {
    _id: '{approver_email}',
    label: '{approver_email}'
  },
  {
    _id: '{subcon_email}',
    label: '{subcon_email}'
  },
  {
    _id: '{po_supplier_email}',
    label: '{po_supplier_email}'
  }
];

export const withEmails = (WrappedComponent) => {
  return (props) => {
    const { value, field, setInputs, schema } = props;

    const [emails, setEmails] = useState(value ? value.split(',').map((_id) => ({ _id })) : []);

    const onChange = (event, newValue) => {
      setEmails(newValue);
    };

    useEffect(() => {
      setInputs((v) => {
        const newValue = map(emails, '_id').join(',');
        return {
          ...v,
          [field]: {
            value: newValue,
            error: false,
            touched: newValue !== value
          }
        };
      });
    }, [emails, field, value]);

    const title = useMemo(() => {
      const obj = find(schema, { field });
      return obj?.title;
    }, [schema, field]);

    return (
      <WrappedComponent
        {...props}
        value={emails}
        schema={schema}
        options={emailList}
        onChange={onChange}
        title={title}
      />
    );
  };
};
