import InputEdit from 'components/common/input-edit';
import React from 'react';
import { withCurrency } from './hooks';

function Currency(props) {
  const { value, setInputs, readonly } = props;
  return (
    <InputEdit
      field="currency"
      value={value}
      className="flex w-full"
      setInputs={setInputs}
      schema={{ type: 'text', input_variant: 'slim-text', readonly }}
    />
  );
}

export default withCurrency(Currency);
