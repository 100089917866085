import { usePrSubmitQuoteVerifiedConfirm } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const withSubmitQuoteVerify = (WrappedComponent) => {
  return (props) => {
    const { data = {}, userProfile, disabled } = props;
    const { record_status } = data || {};
    const [open, setOpen] = useState(false);
    const { onSubmit, isLoading, result } = usePrSubmitQuoteVerifiedConfirm({ _id: data?._id }); // 30 -> 40

    const showVerifyButton = useMemo(() => {
      const { department } = userProfile || {};

      if (department && department === 'PM' && record_status === 30) {
        return true;
      }

      return false;
    }, [userProfile, record_status]);

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    useEffect(() => {
      if (result) {
        redirect(`/pr/profile/${data._id}`);
      }
    }, [result, data?._id]);

    if (!showVerifyButton) return null;
    return (
      <WrappedComponent
        {...props}
        open={open}
        onSubmit={onSubmit}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        disabled={disabled}
        submitted={isLoading || result}
      />
    );
  };
};
