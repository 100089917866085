import { usePrAmendment } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useFetch } from 'lib/fetch';
import { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'components/alert-popup/store';

export const withSendEmailButton = (WrappedComponent) => {
  return (props) => {
    const { className, data } = props;
    const { setAlert } = useAlert();

    const [open, setOpen] = useState(false);
    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const { fetch, isLoading, result } = useFetch();
    const onSubmit = useCallback(() => {
      if (data?._id) {
        fetch('POST', `/api/collection/po/send-email/email_to_vendor/${data?._id}`);
      }
    }, [data]);

    useEffect(() => {
      if (result) {
        setAlert({ message: '發送成功' });
        redirect(`/po/profile/${data?._id}`, { reload: true });
      } else if (result === false) {
        setAlert({ message: '發生錯誤', type: 'error' });
      }
    }, [result, data]);

    return (
      <WrappedComponent
        {...props}
        open={open}
        onSubmit={onSubmit}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        submitted={isLoading || result}
      />
    );
  };
};
