import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withAttachments } from './hooks';

function Attachments(props) {
  const { defaultValue, schema, field } = props;
  return <InputEdit {...props} schema={schema} value={defaultValue} />;
}

export default withAttachments(Attachments);
