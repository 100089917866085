import { useTable } from 'components/common/table/hooks';
import { cloneDeep, difference, filter, find, forEach, isEmpty, map, reduce, set } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';
import { useFetch } from 'lib/fetch';
import { useFetchCurrency } from 'hooks/use-fetch-currency';

export const usePRItems = (props) => {
  const { userProfile, data, setInputs } = props;
  const { record_status, created_by } = data || {};
  const [modal, setModal] = useState(false);

  const currentUser = useAtomValue(user);
  const { suppliers } = useFetchSuppliers();
  const { currencyData } = useCurrencyData();

  const readonly = useMemo(() => {
    if (record_status && record_status > 20) {
      return true;
    }

    if (created_by?._id === currentUser?._id || userProfile?.department === 'PD') {
      return false;
    }

    if (created_by?._id !== currentUser?._id) {
      return true;
    }

    return false;
  }, [record_status, currentUser, created_by, userProfile]);

  const { editRow, pushRows, pullRow, rows } = useRows({ data: data?.pr__items, readonly });
  useItemEdit({ setInputs, rows, data: data?.pr__items });
  const { items } = useFetchItems({ rows });
  const { category_items } = useFetchItemCategoryList({ rows });

  const config = {
    suppliers,
    currencyData,
    items,
    category_items,
    modal,
    readonly
  };

  const controllers = {
    openModal: () => {
      setModal(true);
    },
    closeModal: () => {
      setModal(false);
    },
    editRow,
    pushRows,
    pullRow
  };

  return {
    rows,
    controllers,
    config
  };
};

export const useItemEdit = (props) => {
  const { setInputs, rows, data } = props;

  const pull = useMemo(() => {
    const existingIds = map(data, '_id');
    const rowIds = map(rows, '_id');

    return difference(existingIds, rowIds);
  }, [JSON.stringify(data), JSON.stringify(rows)]);

  const push = useMemo(() => {
    return filter(rows, (row) => !row._id);
  }, [JSON.stringify(rows)]);

  const edit = useMemo(() => {
    const rowIds = map(rows, '_id');

    return reduce(
      rowIds,
      (prev, _id) => {
        const newData = find(rows, { _id });
        const oldData = find(data, { _id });

        if (newData && oldData) {
          const fields = ['quantity', 'supplier', 'price', 'currency'];

          forEach(fields, (field) => {
            if (newData[field] !== oldData[field]) {
              prev[`$edit.pr__items[${_id}].${field}`] = {
                value: newData[field],
                touched: true
              };
            }
          });
        }

        return prev;
      },
      {}
    );
  }, [JSON.stringify(data), JSON.stringify(rows)]);

  useEffect(() => {
    setInputs((v) => {
      const prev = reduce(
        v,
        (prev, curr, key) => {
          if (
            !(
              key.startsWith(`$push.pr__items`) ||
              key.startsWith(`$pull.pr__items`) ||
              key.startsWith(`$edit.pr__items`)
            )
          ) {
            prev[key] = curr;
          }
          return prev;
        },
        {}
      );

      return {
        ...prev,
        ...reduce(
          pull,
          (prev, curr, index) => {
            prev[`$pull.pr__items[${index}]`] = {
              value: curr,
              touched: true
            };
            return prev;
          },
          {}
        ),
        ...reduce(
          push,
          (prev, curr, index) => {
            prev[`$push.pr__items[${index}].item`] = {
              value: curr.item,
              touched: true
            };
            prev[`$push.pr__items[${index}].quantity`] = {
              value: curr.quantity,
              touched: true
            };
            prev[`$push.pr__items[${index}].price`] = {
              value: curr.price,
              touched: true
            };
            prev[`$push.pr__items[${index}].supplier`] = {
              value: curr.supplier,
              touched: true
            };
            prev[`$push.pr__items[${index}].currency`] = {
              value: curr.currency,
              touched: true
            };
            return prev;
          },
          {}
        ),
        ...edit
      };
    });
  }, [JSON.stringify(edit), JSON.stringify(pull), JSON.stringify(push), setInputs]);

  return {};
};

export const useRows = (props) => {
  const { data = [], readonly } = props;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setRows(map(data, (row) => ({ ...row, readonly: !!row?.po || readonly })));
    }
  }, [data, readonly]);

  const pushRows = (items) => {
    const newRows = items.map((row) => {
      return {
        item: row._id,
        code: row.code,
        name: row.name,
        unit: row.unit,
        key: row._id,
        latest_quote_price: row.latest_quote_price,
        moq: row.moq,
        po: row.po
      };
    });
    setRows((r) => {
      return [...r, ...newRows];
    });
  };

  const editRow = (index, field, data) => {
    setRows((rows) => {
      const value = cloneDeep(rows);
      set(value, `[${index}].${field}`, data);
      return value;
    });
  };

  const pullRow = (index) => {
    setRows((r) => {
      const filtered = r.filter((row, i) => index !== i);
      return filtered;
    });
  };

  return {
    rows,
    editRow,
    pushRows,
    pullRow
  };
};

export const useFetchItems = (props) => {
  const { rows } = props;
  const { data } = useTable({
    url: '/api/collection/pr/listing-items',
    paramsPreset: {
      pageSize: 1000000
    }
  });

  const items = useMemo(() => {
    if (!data) return [];
    const items = map(rows, 'item');
    return data?.filter((item) => !items.includes(item._id));
  }, [data, rows]);

  return {
    items
  };
};

export const useFetchItemCategoryList = (props) => {
  const { rows } = props;
  const { data } = useTable({
    url: '/api/collection/pr/listing-category-items',
    paramsPreset: {
      sort: { field: 'code', order: 1 },
      pageSize: 1000000
    }
  });

  const category_items = useMemo(() => {
    if (!data) return [];
    const items = map(rows, 'item');
    return data?.filter((item) => !items.includes(item._id));
  }, [data, rows]);

  return {
    category_items
  };
};

export const useFetchSuppliers = (props) => {
  const { fetch, result } = useFetch();

  useEffect(() => {
    fetch('GET', '/api/collection/supplier/select?label=code');
  }, []);

  const suppliers = useMemo(() => {
    if (!result) return [];
    return result;
  }, [result]);

  return {
    suppliers
  };
};

export const useCurrencyData = () => {
  const { data } = useFetchCurrency();

  const currencyData = useMemo(() => {
    if (!data) return {};

    return reduce(
      data,
      (prev, curr) => {
        prev[curr.code] = curr?.rate || '';
        return prev;
      },
      {}
    );
  }, [data]);

  return { currencyData };
};
