import { useTable } from 'components/common/table/hooks';
import { find, isEmpty, map, reduce, startsWith } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const withContact = (WrappedComponent) => {
  return (props) => {
    const { body, value } = props;
    const [rowCount, setRowCount] = useState(1);

    const length = value?.length;

    const showNewRow = useMemo(() => {
      return reduce(
        body,
        (prev, curr, key) => {
          if (startsWith(key, '$edit.contacts') || startsWith(key, '$pull.contacts')) {
            return false;
          }
          return prev;
        },
        true
      );
    }, [body]);

    useEffect(() => {
      setRowCount(length || 1);
    }, [length, value]);

    const addRowCount = () => {
      setRowCount((v) => v + 1);
    };

    return (
      <WrappedComponent
        {...props}
        rowCount={rowCount}
        addRowCount={addRowCount}
        length={length}
        showNewRow={true}
        body={body}
      />
    );
  };
};
