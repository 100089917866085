import { atom, useAtomValue, useAtom } from 'jotai';
import { compact, forEach, isArray, isEmpty, pull, reduce } from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { queryString as _queryString } from 'store';
import Table from './index';
import { useFetch } from 'lib/fetch';
import { urlStringToObj, queryOpMap, urlObjToString } from 'lib/query';

const defaultPageSize = 20;

export const params = atom(null);

export const useTable = (props) => {
  const {
    collection,
    tablePrefix = '',
    url,
    paramsPreset = {},
    componentProps = {},
    Components,
    setting = {}
  } = props || {};

  const [params, setParams] = useState(null);
  const TableContext = createContext();

  const preset = usePreset(tablePrefix, paramsPreset);
  const { isLoading, data, fieldsToDisplay, schema, metadata, refetch } = useDataFetch(url, params);

  const { nextPage, prevPage, toPage } = usePage(params, setParams, metadata);
  const { setPageSize } = usePageSize(params, setParams);

  const { sort, setSort } = useSort(params, setParams);

  const { filters, addFilter, removeFilter } = useFilters(params, setParams);

  useEffect(() => {
    if (preset?.ready) {
      let value = {
        page: 1,
        pageSize: defaultPageSize
      };

      const { sort, page, pageSize, filters } = preset || {};

      if (sort && !isEmpty(sort)) {
        value = {
          ...value,
          sort: `${sort?.order === -1 ? '-' : ''}${sort?.field}`
        };
      }
      if (page) {
        value = {
          ...value,
          page
        };
      }
      if (pageSize) {
        value = {
          ...value,
          pageSize
        };
      }
      if (filters) {
        const filtersString = compact(
          filters.map((filter) => {
            const { field, op, value } = filter;
            if (field && op && queryOpMap[op]) {
              return `${field}^${queryOpMap[op]}${value}`;
            }
            return null;
          })
        ).join(',');
        if (filtersString) {
          value = {
            ...value,
            filters: filtersString
          };
        }
      }
      setParams({ ...value, ready: true });
    }
  }, [JSON.stringify(preset)]);

  const controllers = {
    nextPage,
    prevPage,
    toPage,
    setSort,
    setPageSize,
    addFilter,
    removeFilter,
    refetch
  };

  const config = {
    collection,
    Components,
    componentProps,
    filters,
    sort
  };

  return {
    editData: () => {},
    data,
    controllers,
    config,
    isLoading,
    Component: (
      <TableContext.Provider
        value={{
          isLoading,
          params,
          data,
          fieldsToDisplay: setting?.fieldsToDisplay || fieldsToDisplay,
          schema: setting?.schema || schema,
          metadata,
          controllers,
          config
        }}
      >
        <Table context={TableContext} {...componentProps} />
      </TableContext.Provider>
    )
  };
};

export const usePreset = (tablePrefix, paramsPreset = {}) => {
  const queryString = useAtomValue(_queryString);
  const [urlPresetProps, setUrlPresetProps] = useState(false);
  useEffect(() => {
    // forEach(queryString, (value, key) => {
    //   if (key.startsWith(tablePrefix)) {
    //     params[key.replace(tablePrefix, '').replace('table_', '')] = value;
    //   }
    // });
    setUrlPresetProps(queryString || null);
    return () => {
      setUrlPresetProps(null);
    };
  }, []);

  const presetObject = useMemo(() => {
    if (urlPresetProps === false) return null;
    let obj = {
      ready: true,
      sort: { order: -1, field: '_id' },
      page: urlPresetProps?.page || 1,
      pageSize: urlPresetProps?.pageSize || defaultPageSize,
      filters: urlPresetProps?.filters || []
    };

    if (urlPresetProps?.sort) {
      obj.sort = {
        order: urlPresetProps.sort.startsWith('-') ? -1 : 1,
        field: urlPresetProps.sort.replace('-', '')
      };
    }
    if (paramsPreset?.sort) {
      obj.sort = paramsPreset.sort;
    }
    if (paramsPreset?.page) {
      obj.page = paramsPreset.page;
    }
    if (paramsPreset?.pageSize) {
      obj.pageSize = paramsPreset.pageSize;
    }
    if (paramsPreset?.filters) {
      obj.filters = [...obj.filters, ...paramsPreset.filters];
    }

    obj.pageSize = parseInt(obj.pageSize);
    obj.page = parseInt(obj.page);

    return obj;
  }, [urlPresetProps, paramsPreset]);

  return presetObject;
};

export const useFilters = (params, setParams) => {
  const filters = useMemo(() => {
    const value = params?.filters;
    if (value) {
      const obj = urlStringToObj(`filters=${params?.filters}`);
      if (obj && obj.filters) {
        return obj.filters;
      }
    }
    return null;
  }, [params]);

  const removeFilter = (filter) => {
    const string = urlObjToString({ filters: [filter] }) || '';
    const value = string?.replace('filters=', '');
    if (value) {
      const newFilters = pull(params.filters.split(','), value).join(',');
      setParams((v) => ({
        ...v,
        filters: newFilters,
        page: 1
      }));
    }
  };
  const addFilter = (filter) => {
    const string = urlObjToString({ filters: [filter] }) || '';
    const value = string?.replace('filters=', '');

    if (value) {
      setParams((v) => ({
        ...v,
        filters: v.filters ? [v.filters || '', value].join(',') : value,
        page: 1
      }));
    }
  };

  return {
    filters,
    removeFilter,
    addFilter
  };
};

export const usePage = (params, setParams, metadata) => {
  const { page, totalPage } = metadata || {};
  const nextPage = useCallback(() => {
    if (metadata?.totalPage === params?.page) {
      return;
    } else {
      setParams((v) => ({
        ...v,
        page: v.page + 1
      }));
    }
  }, [page, totalPage]);

  const prevPage = useCallback(() => {
    if (params?.page === 1) {
      return;
    } else {
      setParams((v) => ({
        ...v,
        page: v.page - 1
      }));
    }
  }, [params?.page]);

  const toPage = useCallback(
    (page) => {
      if (page !== params?.page) {
        setParams((v) => ({
          ...v,
          page
        }));
      }
    },
    [params?.page]
  );

  return {
    nextPage,
    prevPage,
    toPage
  };
};

export const usePageSize = (params, setParams) => {
  const setPageSize = useCallback(
    (size) => {
      if (size !== params?.pageSize) {
        setParams((v) => ({
          ...v,
          pageSize: size,
          page: 1
        }));
      }
    },
    [params?.pageSize]
  );

  return {
    setPageSize
  };
};

export const useSort = (params, setParams) => {
  const setSort = (value) => {
    setParams((v) => ({
      ...v,
      sort: `${value?.order === -1 ? '-' : ''}${value.field}`,
      page: 1
    }));
  };

  const sort = useMemo(() => {
    const value = params?.sort;
    if (value) {
      return {
        field: value.replace('-', ''),
        order: value.startsWith('-') ? -1 : 1
      };
    }
    return null;
  }, [params]);

  return {
    sort,
    setSort
  };
};

export const useDataFetch = (url, params) => {
  const { ready } = params || {};
  const { fetch, result, isLoading } = useFetch();

  const fetchData = (url, params) => {
    const { ready, ...body } = params || {};
    if (url && ready) {
      fetch('GET', url, { params: body });
    }
  };

  useEffect(() => {
    if (url && ready) {
      fetchData(url, params);
    }
  }, [url, params, ready]);

  const data = useMemo(() => {
    if (result) {
      return {
        fieldsToDisplay: result?.fieldsToDisplay,
        data: result?.result?.records,
        metadata: result?.result?.metadata,
        schema: result?.schema
      };
    }
    return null;
  }, [result]);

  return { ...data, isLoading, refetch: () => fetchData(url, params) };
};
