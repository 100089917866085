import InputEdit from 'components/common/input-edit';
import React from 'react';
import { withSupplier } from './hooks';

function Supplier(props) {
  const { value, setInputs, readonly, selectOptions } = props;

  return (
    <InputEdit
      field="supplier"
      value={value}
      className="flex w-full"
      setInputs={setInputs}
      schema={{ input_type: 'select', input_variant: 'slim-select', readonly, selectOptions, freesolo: true }}
    />
  );
}

export default withSupplier(Supplier);
