import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withParent } from './hooks';

function Parent(props) {
  const { schema, value, selectOptions } = props;
  return <InputEdit {...props} schema={schema} overrideSchema={{ selectOptions }} value={value} />;
}

export default withParent(Parent);
