import { tier_list } from 'lib/config';
import { find } from 'lodash';
import { useApprovalHistoryData } from 'pages/pr/profile/hooks';
import { useMemo } from 'react';

export const withApprovalHistory = (Component) => (props) => {
  const { data } = props || {};
  const { _id } = data || {};
  const { historyData = [] } = useApprovalHistoryData({ _id });

  const rows = useMemo(() => {
    return tier_list.map((tier) => {
      const history = find(historyData, { tier });
      return {
        tier,
        skipped: !!data?.[`skip_${tier}`],
        ...history
      };
    });
  }, [historyData, data]);

  return <Component {...props} historyData={historyData} rows={rows} />;
};
