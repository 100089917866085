import React from 'react';
import { withTo } from './hooks';

function To({ children, className, href, target, onClick, toNew = false }) {
  if (onClick) {
    return (
      <div className={className} onClick={onClick}>
        {children}
      </div>
    );
  }
  return (
    <a href={href} target={target || (toNew ? '_blank' : undefined)} className={className}>
      {children}
    </a>
  );
}

export default withTo(To);
