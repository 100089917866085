import React from 'react';
import { isEmpty, includes, startsWith } from 'lodash';
import JsFileDownloader from 'js-file-downloader';
import { apikey, host } from 'lib/config';
import To from 'components/common/to';

import { withFileDetail } from './hooks';
import { AttachFile, Close } from '@mui/icons-material';

function FileDetail(props) {
  const { className, files, removeFile, removedFiles, setRemovedFiles, readonly } = props;
  if (isEmpty(files)) return null;
  return (
    <div className={`flex flex-col mx-2 gap-2 shadow-md rounded-md p-2 text-zinc-600 ${className}`}>
      {files.map((file, key) => {
        const { filename, mimetype, _id } = file || {};
        const url = `${host}/api/collection/file/file/${filename}`;

        const deleted = includes(removedFiles, _id);

        return (
          <div className="flex items-center gap-1" key={key}>
            <div className="">
              <AttachFile sx={{ fontSize: 20 }} />
            </div>
            <div className={deleted ? 'line-through' : ''}>{filename}</div>
            {_id &&
              (startsWith(mimetype, 'image') ? (
                <To className="default-link" plainHref={url} target="_blank">
                  [預覽]
                </To>
              ) : (
                <div
                  className="text-[#0c66e4] cursor-pointer hover:underline"
                  onClick={() => {
                    new JsFileDownloader({
                      url,
                      filename,
                      withCredentials: true,
                      headers: [{ name: 'apikey', value: apikey }]
                    });
                  }}
                >
                  [下載]
                </div>
              ))}

            {!readonly && (
              <Close
                className={`cursor-pointer ${deleted ? 'text-red-500' : ''}`}
                onClick={() => {
                  removeFile({ _id, filename });
                }}
              />
            )}

            {deleted && (
              <div className="flex gap-2 cursor-pointer">
                <div
                  className=""
                  onClick={() =>
                    setRemovedFiles((v) => {
                      return v.filter((id) => id !== _id);
                    })
                  }
                >
                  取消刪除
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default withFileDetail(FileDetail);
