import React from 'react';
import { parseISODateString } from 'lib/date';
import { compact } from 'lodash';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  paragraph: {
    marginTop: 12,
    marginBottom: 12
  },
  table: {
    // width: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderBottom: '1px solid #000',
    fontSize: 10
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    borderTop: '1px solid #000',
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cell: {
    borderRight: '1px solid #000',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  cell1: {
    borderLeft: '1px solid #000'
    // flexBasis: 10
    // textAlign: 'right'
  },
  cell2: {
    flexBasis: 35
    // textAlign: 'right'
  },
  cell3: {
    flexBasis: 5
    // textAlign: 'right'
  },
  cell4: {
    flexBasis: 5
    // textAlign: 'right'
  },
  cell5: {
    flexBasis: 35
    // textAlign: 'right'
  },
  cell6: {
    flexBasis: 5
    // textAlign: 'right'
  },
  cell7: {
    flexBasis: 5
    // textAlign: 'right'
  },
  cell8: {
    flexBasis: 12
    // textAlign: 'right'
  }
});

function PrItemsTable(props) {
  const { data } = props;
  return (
    <View style={[styles.table, styles.paragraph, styles.pr_item]}>
      <View style={[styles.tableRow, styles.tableHeader]} wrap={false}>
        <Text style={[styles.cell, styles.cell1]}>名稱</Text>
        <Text style={[styles.cell, styles.cell2]}>代號</Text>
        <Text style={[styles.cell, styles.cell3]}>數量</Text>
        <Text style={[styles.cell, styles.cell4]}>單位</Text>
        <Text style={[styles.cell, styles.cell5]}>供應商</Text>
        <Text style={[styles.cell, styles.cell6]}>貨幣</Text>
        <Text style={[styles.cell, styles.cell7]}>單價</Text>
        <Text style={[styles.cell, styles.cell8]}>小計(HKD)</Text>
      </View>
      {data?.pr__items?.map((d, index) => {
        return (
          <View style={[styles.tableRow]} wrap={false} key={index}>
            <Text style={[styles.cell, styles.cell1]}>{d?.name}</Text>
            <Text style={[styles.cell, styles.cell2]}>{d?.code}</Text>
            <Text style={[styles.cell, styles.cell3]}>{d?.quantity}</Text>
            <Text style={[styles.cell, styles.cell4]}>{d?.unit}</Text>
            <Text style={[styles.cell, styles.cell5]}>{d?.supplier?.code}</Text>
            <Text style={[styles.cell, styles.cell6]}>{d?.currency}</Text>
            <Text style={[styles.cell, styles.cell7]}>{d?.price}</Text>
            <Text style={[styles.cell, styles.cell8]}>{d?.subtotal}</Text>
          </View>
        );
      })}
    </View>
  );
}

export default PrItemsTable;
