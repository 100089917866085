import React, { useMemo } from 'react';
import {
  Block,
  Checklist,
  Close,
  Create,
  DoDisturb,
  Done,
  HourglassTop,
  PriceCheck,
  Publish,
  RequestQuote
} from '@mui/icons-material';
import { approval_status_map } from 'lib/config';

export const withRecordStatus = (WrappedComponent) => {
  return (props) => {
    const { data } = props;
    const { record_status } = data || {};
    const config = useMemo(() => {
      switch (record_status) {
        case 20:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <HourglassTop />
                {approval_status_map[record_status]}
              </div>
            ),
            className: 'border-orange-400 text-orange-500'
          };
        case 30:
        case 35:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <RequestQuote />
                {approval_status_map[record_status]}
              </div>
            ),
            className: 'border-yellow-600 text-yellow-600'
          };
        case 40:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <PriceCheck /> {approval_status_map[record_status]}
              </div>
            ),
            className: 'border-yellow-600 text-yellow-600'
          };
        case 50:
        case 60:
        case 70:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <Checklist /> {approval_status_map[record_status]}
              </div>
            ),
            className: 'border-green-600 text-green-600'
          };
        case 80:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <Block /> {approval_status_map[record_status]}
              </div>
            ),
            className: 'border-zinc-400 text-zinc-500'
          };
        case 90:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <Close /> {approval_status_map[record_status]}
              </div>
            ),
            className: 'border-red-600 text-red-600'
          };
        default:
          return {
            title: (
              <div className="flex gap-2 items-center">
                <Create />
                {approval_status_map[10]}
              </div>
            ),
            className: 'border-sky-400 text-sky-500'
          };
      }
    }, [record_status]);
    return <WrappedComponent {...props} config={config} />;
  };
};
