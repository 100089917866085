import React from 'react';
import { Divider } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';

function General(props) {
  const { data, schema, setInputs, mode } = props;

  const inputEditProps = {
    schema,
    setInputs,
    mode
  };

  return (
    <Section title="General">
      <div className="flex flex-col gap-2">
        <div className="flex w-full gap-4">
          <InputEdit {...inputEditProps} field="code" value={data?.code} className="basis-1/2" />
          <InputEdit {...inputEditProps} field="rate" value={data?.rate} className="basis-1/2" />
        </div>
      </div>
    </Section>
  );
}

export default General;
