import React, { useContext } from 'react';
import Td from './td';
import { redirect } from 'lib/location';
import { Search } from '@mui/icons-material';

function Tr({ className, context, onClick, fieldsToDisplay, data, schema, row, prefix }) {
  const { config = {} } = useContext(context);

  if (config?.Components?.Tr) return <config.Components.Tr {...{ context, onClick, fieldsToDisplay, data, schema }} />;

  const rowClickNewTab = () => {
    redirect(`${prefix}${row?._id}`, { newTab: true });
  };

  return (
    <tr className={`${className} hover:bg-blue-50 even:bg-stone-50 cursor-pointer`}>
      {fieldsToDisplay.map((field) => {
        if (config?.Components?.Td) {
          return (
            <config.Components.Td
              value={data[field]}
              key={`table-td-${field}`}
              schema={schema}
              field={field}
              context={context}
              data={data}
            />
          );
        }
        return (
          <Td
            value={data[field]}
            key={`table-td-${field}`}
            schema={schema}
            field={field}
            context={context}
            onClick={onClick}
          />
        );
      })}
      <td
        className="p-2 rounded-sm text-sm text-zinc-800 w-max max-w-[220px] break-all new_tab"
        onClick={rowClickNewTab}
      >
        <Search />
      </td>
    </tr>
  );
}

export default Tr;
