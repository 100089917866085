import React from 'react';
import { withPrItemsStats } from './hooks';
import { isPriceFormat } from 'lib/price';

function PrItemsStats(props) {
  const { length, price, suppliers } = props;
  return (
    <div className="flex gap-4 ml-4">
      <div className="flex flex-col p-4 border rounded-xl bg-white min-w-[160px] shadow gap-2">
        <div className="text-md text-zinc-500">總數</div>
        <div className="text-3xl font-bold text-zinc-900 ml-2">
          {length}
          <span className="text-base font-normal ml-2">項</span>
        </div>
      </div>
      <div className="flex flex-col p-4 border rounded-xl bg-white min-w-[160px] shadow gap-2">
        <div className="text-md text-zinc-500">總金額</div>
        <div className="text-3xl font-bold text-zinc-900">
          <span className="text-base font-normal mr-2">$</span>
          {isPriceFormat(price)}
        </div>
      </div>
      <div className="flex flex-col p-4 border rounded-xl bg-white min-w-[160px] shadow gap-2">
        <div className="text-md text-zinc-500">供應商</div>
        <div className="text-3xl font-bold text-zinc-900 ml-2">
          {suppliers}
          <span className="text-base font-normal ml-2">間</span>
        </div>
      </div>
    </div>
  );
}

export default withPrItemsStats(PrItemsStats);
