import React from 'react';
import { useInputDate } from './hooks';
import { DatePicker } from '@mui/x-date-pickers';

function InputDate({ field, value, setInputs, config }) {
  const { date, setDate } = useInputDate({ defaultValue: value, config, setInputs, field });

  const { readonly, editable, format = 'YYYY-MM-DD' } = config;

  return (
    <DatePicker
      value={date}
      onChange={(newValue) => setDate(newValue)}
      format={format}
      disabled={readonly || !editable}
      slotProps={{
        actionBar: { actions: ['clear', 'today'] },
        textField: {
          style: {
            width: '100%'
          }
        }
      }}
      sx={{
        '.MuiOutlinedInput-input': {
          height: '40px',
          padding: 0,
          paddingLeft: '14px'
        }
      }}
    />
  );
}

export default InputDate;
