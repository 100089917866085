import React from 'react';
import InputEdit from 'components/common/input-edit';
import { withSubcontractorReferences } from './hooks';
import Section from 'components/common/section';
import { map } from 'lodash';
import Reference from './components/reference';
import ApproveButton from './components/approve-button';
import { Close, Done } from '@mui/icons-material';

function SubcontractorReferences(props) {
  const { _id, schema, rows, setInputs, readonly, skip, showApproveButton, subcontractor_verified } = props;

  if (skip) {
    return <></>;
  }
  return (
    <Section title="訂購(扣數)文件">
      {showApproveButton && (
        <div className="">
          <ApproveButton references={rows} _id={_id} />
        </div>
      )}
      {subcontractor_verified && (
        <div className="flex gap-2 justify-end">
          <Done style={{ fontSize: 16, color: '#4ade80' }} />
          已提交
        </div>
      )}
      <div className="flex flex-col gap-4">
        {map(rows, (row) => (
          <Reference data={row} key={row._id} schema={schema} setInputs={setInputs} />
        ))}
      </div>
    </Section>
  );
}

export default withSubcontractorReferences(SubcontractorReferences);
