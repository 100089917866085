import Fields from './components/fields';
import Date from 'components/common/data-display/date';
import { useContext, useEffect, useMemo } from 'react';

export const withTd = (WrappedComponent) => {
  return (props) => {
    const { value, schema, field, data, context } = props;
    const { config } = useContext(context);

    const { componentProps } = config || {};
    const { _id } = componentProps?.Td;

    const isRelationDoc = useMemo(() => {
      return data?.related_doc_ids?.includes(_id);
    }, [data?.related_doc_ids, _id]);

    const Component = useMemo(() => {
      switch (field) {
        case 'action':
          let text = value;
          if (isRelationDoc) {
            text = 'UPDATE';
          }

          return <div>{text === 'INSERT' ? '新增' : '更新'}</div>;
        case 'fields':
          return <Fields value={isRelationDoc ? [data?.database] : value} />;
        case 'created_at':
          return <Date value={value} config={{ show_ago: false, show_datetime: true }} />;
        default:
          break;
      }

      return <div>{value}</div>;
    }, [value, isRelationDoc, data?.database, field]);

    return <WrappedComponent {...props} Component={Component} />;
  };
};
