import { useTable } from 'components/common/table/hooks';
import { find, isEmpty, map } from 'lodash';
import { useMemo } from 'react';

export const withCurrency = (WrappedComponent) => {
  return (props) => {
    const { value } = props;

    const { data } = useTable({
      url: '/api/collection/currency/listing',
      paramsPreset: {
        pageSize: 10000
      }
    });

    const options = useMemo(() => {
      if (isEmpty(data)) {
        if (value) {
          return [value];
        }
        return [];
      }
      return map(data, (row) => ({
        _id: row._id,
        label: row?.code
      }));
    }, [data, value]);

    const defaultValue = useMemo(() => {
      if (isEmpty(options)) {
        return [value];
      } else {
        return find(options, { _id: value });
      }
    }, [value, options]);

    return <WrappedComponent {...props} options={options} value={defaultValue} />;
  };
};
