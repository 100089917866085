import React from 'react';
import { parseISODateString } from 'lib/date';
import { compact, uniqBy } from 'lodash';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
// paragraph: {
//   marginBottom: 12
// },
// fontBold: {
//   fontFamily: 'Noto Sans TC Bold'
// },
// header: {
//   // fontSize: 24,
//   marginBottom: 18,
//   flexDirection: 'row',
//   justifyContent: 'space-between',
//   alignContent: 'stretch',
//   flexWrap: 'nowrap',
//   alignItems: 'center'
// },
// logoImage: {
//   height: 50,
//   objectFit: 'contain',
//   objectPosition: '0'
// },
// row: {
//   flexDirection: 'row',
//   justifyContent: 'space-between',
//   alignContent: 'stretch',
//   flexWrap: 'nowrap',
//   alignItems: 'start',
//   fontSize: 12
// },
// rowTitle: {
//   paddingRight: 10
// },
// rowText: {
//   borderBottom: '1px solid black',
//   width: 140,
//   height: 20,
//   textAlign: 'center',
//   paddingRight: 10
// },
// headerTitle: {
//   fontSize: 13,
//   textAlign: 'center',
//   marginBottom: 14
// },
// infoLeftRight: {
//   // flex: 1,
//   flexDirection: 'row',
//   fontSize: 10
// },
// infoLeft: {
//   padding: 5,
//   flexBasis: '60%'
// },
// infoRight: {
//   padding: 5,
//   flexBasis: '40%'
// },
// infoTable: {
//   // width: 550,
//   display: 'flex',
//   flexDirection: 'column',
//   width: '100%'
// },
// infoTableRow: {
//   display: 'flex',
//   flexDirection: 'row'
// },
// infoCell: {
//   flexGrow: 2,
//   flexShrink: 1,
//   // flexBasis: 100,
//   alignSelf: 'stretch',
//   paddingVertical: 1,
//   paddingHorizontal: 2
// },
// infoCell1: {
//   flexBasis: '40%'
//   // textAlign: 'right'
// },
// infoCell2: {
//   flexBasis: '60%'
//   // borderBottom: '1px solid #000'
//   // textAlign: 'right'
// }
// });

function PoHeader(props) {
  // logo image src is different in frontend & backend
  // e.g. frontend is '/assets/images/logo--dark.png', backend is in '/app/admin-panel/assets/images/logo--dark.png'
  const { data, data_item, pr_number, styles } = props;

  return (
    <>
      {/* <View style={styles.header}>
        <View>
          <Image style={styles.logoImage} src={`/assets/images/logo--dark.png`} />
        </View>
      </View> */}
      <View style={styles.headerTitle} wrap={false}>
        <Text style={styles.fontBold}>Purchase Order</Text>
      </View>

      <View style={[styles.infoLeftRight, styles.paragraph]}>
        <View style={[styles.infoLeft]}>
          <View style={[styles.infoLeftRight]}>
            <View style={{ flexBasis: '20%' }}>
              <Text>MESSR:</Text>
            </View>
            <View style={{ flexBasis: '80%' }}>
              <Text>{data?.supplier?.eng_name}</Text>
              <Text>{data?.supplier?.chin_name}</Text>
              <Text> </Text>
              <Text>{data?.supplier?.eng_address}</Text>
              <Text>{data?.supplier?.chin_address}</Text>
            </View>
          </View>
          <View style={[styles.infoLeftRight]}>
            <View style={{ flexBasis: '20%' }}>
              <Text>ATTN TEL:</Text>
            </View>
            <View style={{ flexBasis: '80%' }}>
              <Text>{data?.supplier?.phone_number}</Text>
            </View>
          </View>
          <View style={[styles.infoLeftRight]}>
            <View style={{ flexBasis: '20%' }}>
              <Text>{data?.supplier?.fax_number && `FAX :`}</Text>
            </View>
            <View style={{ flexBasis: '80%' }}>
              <Text>{data?.supplier?.fax_number && `${data?.supplier?.fax_number}`}</Text>
            </View>
          </View>
          {pr_number.length > 0 && uniqBy(pr_number).length > 10 && (
            <View style={[styles.infoLeftRight]}>
              <View style={{ flexBasis: '20%' }}>
                <Text style={{ paddingTop: 10 }}>PR NO:</Text>
              </View>
              <View style={{ flexBasis: '80%' }}>
                <Text style={{ paddingTop: 10 }}>{uniqBy(pr_number).join(', ')}</Text>
              </View>
            </View>
          )}
        </View>
        <View style={[styles.infoRight]}>
          <View style={[styles.infoTable, styles.paragraph]}>
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>P/O NO.:</Text>
              <Text style={[styles.infoCell, styles.infoCell2]}>{data?.po_number}</Text>
            </View>
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>PAGE NO.:</Text>
              <Text
                style={[styles.infoCell, styles.infoCell2]}
                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
              />
            </View>
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>PO DATE:</Text>
              <Text style={[styles.infoCell, styles.infoCell2]}>{parseISODateString(data?.created_at)}</Text>
            </View>
            {pr_number.length > 0 && uniqBy(pr_number).length < 11 && (
              <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
                <Text style={[styles.infoCell, styles.infoCell1]}>PR NO:</Text>
                <Text style={[styles.infoCell, styles.infoCell2]}>{uniqBy(pr_number).join(', ')}</Text>
              </View>
            )}
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>JOB NO:</Text>
              <Text style={[styles.infoCell, styles.infoCell2]}>{data?.contract?.code}</Text>
            </View>
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>DEPT(USER):</Text>
              <Text style={[styles.infoCell, styles.infoCell2]}>{data?.created_by?.department_input}</Text>
            </View>
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>PREPARED BY:</Text>
              <Text style={[styles.infoCell, styles.infoCell2]}>{data?.created_by?.nickname}</Text>
            </View>
            <View style={[styles.infoTableRow, styles.infoTableHeader]} wrap={false}>
              <Text style={[styles.infoCell, styles.infoCell1]}>VENDOR CODE:</Text>
              <Text style={[styles.infoCell, styles.infoCell2]}>{data?.supplier?.code}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default PoHeader;
