import React, { useMemo } from 'react';
import { usePRItems } from './hooks';
import { Add, Done, Notifications, RemoveCircleOutline } from '@mui/icons-material';
import { Button, Checkbox } from '@mui/material';
import CsrTable from 'components/common/csr-table';
import To from 'components/common/to';
import { isEmpty, isNumber, multiply, toInteger, toNumber } from 'lodash';
import Popup from './components/popup';
import Quantity from './components/quantity';
import Supplier from './components/supplier';
import Price from './components/price';
import SubmitQuoteVerify from './components/submit-quote-verify';
import SubmitQuoteDone from './components/submit-quote-done';
import BacktoQuote from './components/backto-quote';
import Stats from './components/stats';
import Currency from './components/currency';
import Subtotal from './components/subtotal';

const PRItems = (props) => {
  const { data, userProfile, body } = props;
  const { controllers, config, rows } = usePRItems(props);
  const { items, readonly } = config;
  const { openModal, pullRow } = controllers;

  const columns = [
    {
      title: '',
      key: 'operation',
      fixed: 'left',
      width: 30,
      sortable: false,
      searchable: false,
      render: (_, item, index) =>
        item.readonly || readonly ? null : (
          <div className="flex justify-center w-full">
            {
              <Checkbox
                icon={<RemoveCircleOutline />}
                checked={false}
                disabled={readonly}
                sx={{
                  padding: 0
                }}
                onChange={(v) => {
                  pullRow(index);
                }}
              />
            }
          </div>
        )
    },
    {
      title: '名稱',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (_, item) => (
        <To href={`/item/profile/${item.item}`} target="_blank">
          {item.name}
        </To>
      )
    },
    {
      title: '代號',
      width: 100,
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'PO',
      width: 42,
      dataIndex: 'po',
      key: 'po',
      sortable: false,
      searchable: false,
      render: (_, item) => (
        <div className="text-xs">
          {item?.po ? (
            <>
              <To href={`/po/profile/${item?.po?._id}`} target="_blank">
                {item?.po?.po_number}
              </To>
            </>
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      title: '數量',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 80,
      sortable: false,
      searchable: false,
      render: (_, item, index) => (
        <Quantity
          index={index}
          controllers={controllers}
          value={item.quantity}
          key={item?._id}
          readonly={item?.readonly || readonly}
        />
      )
    },
    {
      title: '單位',
      dataIndex: 'unit',
      key: 'unit',
      width: 50,
      sortable: false,
      searchable: false
    },
    {
      title: '單價 (最後批核)',
      width: 60,
      sortable: false,
      searchable: false,
      dataIndex: 'latest_quote_price',
      key: 'latest_quote_price'
    },
    {
      title: (
        <div className="flex items-center">
          <div>供應商</div>
          <div className="ml-1">
            <To className="hover:bg-zinc-100 rounded-full cursor-pointer" href="/supplier/new" target="_blank">
              <Add sx={{ fontSize: 16, color: '#0C66E4' }} />
            </To>
          </div>
        </div>
      ),
      dataIndex: 'supplier',
      key: 'supplier',
      width: 150,
      sortable: false,
      searchable: false,
      render: (_, item, index) => (
        <Supplier
          index={index}
          controllers={controllers}
          value={item.supplier}
          key={item?._id}
          readonly={item?.readonly || readonly}
          config={config}
        />
      )
    },
    {
      title: '貨幣',
      dataIndex: 'currency',
      key: 'currency',
      width: 60,
      sortable: false,
      searchable: false,
      render: (_, item, index) => (
        <Currency index={index} controllers={controllers} value={item.currency} key={item?._id} readonly={true} />
      )
    },
    {
      title: '供應商單價',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      sortable: false,
      searchable: false,
      render: (_, item, index) => (
        <Price
          index={index}
          controllers={controllers}
          value={item.price}
          key={item?._id}
          readonly={readonly || item.readonly}
        />
      )
    },
    {
      title: '小計(HKD)',
      width: 60,
      sortable: false,
      searchable: false,
      render: (_, item, index) => {
        return <Subtotal config={config} item={item} data={data} />;
      }
    }
  ];

  return (
    <div className="flex flex-col gap-6 mb-[200px]">
      <CsrTable
        columns={columns}
        data={rows}
        size="small"
        scroll={{
          x: 800,
          y: 600
        }}
        pagination={{
          pageSize: 20,
          position: ['topRight']
        }}
        bordered
        footer={() => {
          return (
            <div className="flex gap-4">
              {!isEmpty(items) && !readonly && (
                <div className="">
                  <Button variant="outlined" endIcon={<Add />} onClick={openModal} size="small">
                    新增物料
                  </Button>
                  <Popup config={config} controllers={controllers} />
                </div>
              )}
            </div>
          );
        }}
      />
      <div className="fixed left-0 bottom-0 right-0 bg-white justify-end p-4 gap-4 flex flex-col items-end z-0 ">
        <div className="flex gap-4">
          {/* {data?.record_status === 20 && (
            <Button variant="outlined" size="large" endIcon={<Notifications />}>
              通知PM
            </Button>
          )} */}
          <SubmitQuoteDone userProfile={userProfile} data={data} disabled={!isEmpty(body)} />
          <BacktoQuote userProfile={userProfile} data={data} disabled={!isEmpty(body)} />
          <SubmitQuoteVerify userProfile={userProfile} data={data} disabled={!isEmpty(body)} />
        </div>
        <Stats rows={rows} config={config} data={data} />
      </div>
    </div>
  );
};
export default PRItems;
