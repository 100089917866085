import { useEffect, useMemo, useState } from 'react';

export const withPrice = (WrappedComponent) => {
  return (props) => {
    const { value, controllers, index } = props;
    const { editRow } = controllers;

    const [data, setData] = useState({ price: { value } });

    useEffect(() => {
      if (data?.price?.touched) {
        editRow(index, 'price', data.price?.value);
      }
    }, [JSON.stringify(data), index]);

    return <WrappedComponent {...props} value={value} setInputs={setData} key={index} />;
  };
};
