import React from 'react';
import { Button } from '@mui/material';
import { Lens } from '@mui/icons-material';

function BooleanButton(props) {
  const { isOn, setIsOn, readonly } = props;

  return (
    <Button
      variant="outlined"
      startIcon={<Lens fontSize="10px" sx={{ fill: isOn ? '#8ade93' : '#ef4444' }} />}
      sx={{
        cursor: readonly ? 'default' : 'pointer',
        height: '40px',
        ...(!isOn
          ? {
              borderColor: '#f87171',
              color: '#dc2626',
              ':hover': {
                borderColor: '#dc2626'
              }
            }
          : {
              borderColor: '#22c55e',
              color: '#16a34a',
              ':hover': {
                borderColor: '#16a34a'
              }
            })
      }}
      onClick={() => {
        if (readonly) return;
        setIsOn(!isOn);
      }}
    >
      {isOn ? '已啟用' : '未啟用'}
    </Button>
  );
}

export default BooleanButton;
