import React from 'react';
import Section from 'components/common/section';
import InputEdit from 'components/common/input-edit';
import Department from './components/department';

function General(props) {
  const { data, isEditMode, schema, setInputs } = props;

  return (
    <Section title="General">
      <div className="flex flex-col gap-2">
        <InputEdit
          schema={schema}
          field="username"
          value={data?.username}
          setInputs={setInputs}
          overrideSchema={{ readonly: isEditMode }}
        />

        <InputEdit schema={schema} field="password" value={data?.password} setInputs={setInputs} />

        <InputEdit schema={schema} field="nickname" value={data?.nickname} setInputs={setInputs} />

        <InputEdit schema={schema} field="chinese_name" value={data?.chinese_name} setInputs={setInputs} />

        <InputEdit schema={schema} field="english_name" value={data?.english_name} setInputs={setInputs} />

        <InputEdit schema={schema} field="email" value={data?.email} setInputs={setInputs} />

        <InputEdit schema={schema} field="mobile_number" value={data?.mobile_number} setInputs={setInputs} />

        <InputEdit schema={schema} field="staff_number" value={data?.staff_number} setInputs={setInputs} />

        <Department schema={schema} field="department" value={data?.department} setInputs={setInputs} />

        <InputEdit schema={schema} field="department_input" value={data?.department_input} setInputs={setInputs} />

        <InputEdit schema={schema} field="signature" value={data?.signature} setInputs={setInputs} />

        {/* <InputEdit schema={schema} field="job_title" value={data?.job_title} setInputs={setInputs} /> */}
      </div>
    </Section>
  );
}

export default General;
