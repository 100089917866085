import InputEdit from 'components/common/input-edit';
import { withPoItems } from './hooks';
import CsrTable from 'components/common/csr-table';
import { Checkbox, Divider } from 'antd';
import { map } from 'lodash';
import classNames from 'classnames';
import { isPriceFormat } from 'lib/price';

function PoItems(props) {
  const {
    body,
    columns,
    items,
    onSelectChange,
    getCheckboxProps,
    setHideOptions,
    hideOptions,
    setInputs,
    schema,
    value,
    selected,
    totalPrice,
    readonly
  } = props;
  return (
    <div className="flex flex-col mb-4">
      <CsrTable
        columns={columns}
        data={items}
        size="small"
        bordered
        scroll={{
          x: 800,
          y: 600
        }}
        pagination={
          readonly
            ? false
            : {
                pageSize: 20,
                position: ['botRight'],
                showSizeChanger: false
              }
        }
        rowSelection={{
          type: 'checkbox',
          getCheckboxProps,
          onChange: onSelectChange,
          selectedRowKeys: map(selected.items, '_id')
        }}
        footer={() =>
          readonly ? null : (
            <div className="flex gap-4 p-1">
              {/* <Checkbox onChange={() => setHideOptions(!hideOptions)} checked={hideOptions}>
                隱藏無效選項
              </Checkbox> */}
            </div>
          )
        }
      />
      <Divider />
      <div className="flex flex-col gap-2 text-base">
        <div className="w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-sm mb-1 ml-1">合約</div>
            <div
              className={classNames([
                'border px-3 rounded h-10 flex items-center border-zinc-300',
                { 'text-zinc-400': readonly }
              ])}
            >
              {selected.contract?.label}
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-sm mb-1 ml-1">供應商</div>
            <div
              className={classNames([
                'border px-3 rounded h-10 flex items-center border-zinc-300',
                { 'text-zinc-400': readonly }
              ])}
            >
              {selected.supplier?.label}
            </div>
          </div>
        </div>
        <div className="w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-sm mb-1 ml-1">總價(HKD)</div>
            <div
              className={classNames([
                'border px-3 rounded h-10 flex items-center border-zinc-300',
                { 'text-zinc-400': readonly }
              ])}
            >
              {isPriceFormat(totalPrice)}
            </div>
          </div>
          <div className="w-1/2" />
        </div>
      </div>
    </div>
  );
}

export default withPoItems(PoItems);
