import { withSchemaConfig } from 'hooks/with-schema-config';
import React from 'react';

function Td({ value, config, Component, onClick }) {
  return (
    <td className="p-2 rounded-sm text-sm text-zinc-800 w-max max-w-[220px] break-all" onClick={onClick}>
      {Component}
    </td>
  );
}

export default withSchemaConfig(Td);
