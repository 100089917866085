import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/email-template/index';

const collection = 'email';
const menuItem = ['SETTING', 'EMAIL_TEMPLATE'];

const routes = [
  <Route
    path={`/email-template`}
    element={<IndexPage menuItem={menuItem} title="Email Template" collection={collection} editMode="edit" />}
  />
];

export default routes;
