import { OtherHouses, Storage, Pending, Settings, Topic, Assignment } from '@mui/icons-material';

export const items = [
  {
    name: '系統概覽',
    url: '/',
    icon: <OtherHouses />,
    disabled: true,
    isRoot: true
  },
  {
    name: '資料庫',
    icon: <Topic />,
    key: 'DATABASE',
    children: [
      {
        name: '物料',
        url: '/item',
        key: 'ITEM'
      },
      {
        name: '供應商',
        url: '/supplier',
        key: 'SUPPLIER'
      },
      {
        name: '合約',
        url: '/contract',
        key: 'CONTRACT'
      },
      {
        name: '判頭',
        url: '/subcontractor',
        key: 'SUBCONTRACTOR'
      },
      {
        name: '貨幣',
        url: '/currency',
        key: 'CURRENCY'
      },
      {
        name: '送貨地址',
        url: '/delivery',
        key: 'DELIVERY'
      }
    ]
  },
  {
    name: '採購',
    icon: <Storage />,
    key: 'PR',
    children: [
      {
        name: '申請',
        url: '/pr',
        key: 'PR1'
      },
      {
        name: '等待報價',
        url: '/pr/await-quote',
        key: 'PRAQ'
      },
      {
        name: '待批報價',
        url: '/pr/await-quote-verify',
        key: 'PRAQV'
      },
      {
        name: '待批採購單',
        url: '/pr/await-my-approve',
        key: 'PRMAA'
      },
      {
        name: '批准狀況',
        url: '/pr/await-approve',
        key: 'PRAA'
      },
      // {
      //   name: '統計',
      //   url: '/pr/status',
      //   key: 'PR_STATUS'
      // },
      {
        name: '待補WO No.',
        url: '/pr/await-wo-number',
        key: 'PRAWO'
      }
      // {
      //   name: '購買審核',
      //   url: '/purchase-approval',
      //   key: 'PURCHASE_APPROVAL'
      // },
      // {
      //   name: '變更申請',
      //   url: '/amendment',
      //   key: 'AMENDMENT'
      // }
    ]
  },
  {
    name: 'PO',
    icon: <Pending />,
    key: 'PO',
    children: [
      {
        name: '已批PR',
        url: '/po/ready-for-po-items',
        key: 'PO_READY_FOR_PO_ITEMS'
      },
      {
        name: '已出PO',
        url: '/po',
        key: 'PO1'
      }
    ]
  },
  {
    name: '報告',
    icon: <Assignment />,
    key: 'REPORT',
    children: [
      {
        name: 'Top Product (Amount)',
        url: '/top-product-amount',
        key: 'TOP_PRODUCT_AMOUNT'
      },
      {
        name: 'Top Product (Count)',
        url: '/top-product-count',
        key: 'TOP_PRODUCT_COUNT'
      },
      {
        name: 'Top Supplier (Amount)',
        url: '/top-supplier-amount',
        key: 'TOP_SUPPLIER_AMOUNT'
      },
      {
        name: 'Top Supplier (Count)',
        url: '/top-supplier-count',
        key: 'TOP_SUPPLIER_COUNT'
      }
    ]
  },
  {
    key: 'SETTING',
    name: '系統設置',
    icon: <Settings />,
    isAdminOnly: true,
    children: [
      {
        name: 'Log',
        url: '/log',
        key: 'LOG'
      },
      {
        name: 'Email Template',
        url: '/email-template',
        key: 'EMAIL_TEMPLATE'
      },
      {
        name: '用戶管理',
        url: '/user',
        key: 'USER'
      }
    ]
  }
];
