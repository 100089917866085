import { isNumber, multiply, round, toInteger, toNumber } from 'lodash';
import { useMemo } from 'react';

export const withSubtotal = (WrappedComponent) => {
  return (props) => {
    const { config, item, data } = props;
    const { currencyData } = config;

    const subtotal = useMemo(() => {
      if (item?.subtotal && data?.record_status >= 30) {
        return item.subtotal;
      }
      const quantity = toInteger(item.quantity);
      const price = toNumber(item.price);
      const currency = item.currency;
      const rate = currencyData?.[currency] || '';

      const count = multiply(quantity, price);

      if (isNumber(count) && !isNaN(count)) {
        if (rate) {
          return round(count / rate, 2);
        }
        return `${count}*`;
      }
      return null;
    }, [item, currencyData]);

    return <WrappedComponent {...props} subtotal={subtotal} />;
  };
};
