import React, { useState } from 'react';
import { Checkbox, Divider } from 'antd';
import { map } from 'lodash';

import { withContractUser } from './hooks';
import { tier_list } from 'lib/config';
import Section from 'components/common/section';

const CheckboxGroup = Checkbox.Group;

const ContractUser = (props) => {
  const { checkedList, onChange, options } = props;
  return (
    <Section title="選擇用戶">
      <div className="flex flex-col ml-2">
        {map(tier_list, (tier, index) => (
          <div className={`flex gap-4 py-4 rounded-md ${index > 0 ? 'border-t' : ''}`} key={index}>
            <div className="text-zinc-600 text-md">{tier}</div>
            <div className="">
              <CheckboxGroup
                options={options[tier]}
                value={checkedList[tier]}
                onChange={(list) => onChange(tier, list)}
              />
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};
export default withContractUser(ContractUser);
