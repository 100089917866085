import { useFetch } from 'lib/fetch';
import { difference, find, isEmpty, filter, groupBy, map, reduce, startsWith, omit } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const useGeneralTab = (props) => {
  const { body, data, userProfile, isEditMode, contractUserData } = props;
  const { contract } = body;
  const { record_status } = data || {};
  const { workorderData } = useWorkorderData({
    contract: contract || data?.contract?._id,
    wo_number: body?.wo_number || data?.wo_number,
    record_status: record_status || 1
  });
  const form = data?.form || body?.form;
  const { username, nickname, mobile_number } = userProfile || {};

  const contract_user_options = useMemo(() => {
    const liveOptions = groupBy(
      filter(contractUserData, (row) => row.level === 10),
      'tier'
    );
    return Object.keys(omit(liveOptions, 'Issue'));
  }, [JSON.stringify(contractUserData)]);

  const contract_user_records = useMemo(() => {
    const liveOptions = groupBy(
      filter(data?.pr__users, (row) => row.tier),
      'tier'
    );
    return Object.keys(omit(liveOptions, 'Issue'));
  }, [JSON.stringify(data?.pr__users)]);

  const showSubmit =
    contract_user_options.sort().toString() === contract_user_records.sort().toString() &&
    data?.pr__items.reduce((n, { quantity }) => n + parseInt(quantity), 0) > 0;

  const isContractEmpty = useMemo(() => {
    return !(body?.contract || data?.contract);
  }, [body, data]);

  const oldJobType = useMemo(() => {
    return data?.job_type;
  }, [body, data]);

  const schema = useMemo(() => {
    if (!props.schema || isEmpty(props.schema)) return null;
    return props.schema.map((row) => {
      const { field, ...config } = row;
      const enhancedConfig = { field };

      if (workorderData && workorderData?.[field]) {
        enhancedConfig.default = workorderData[field];
        enhancedConfig.forceUpdate = true;
      } else {
        enhancedConfig.default = undefined;
      }

      if (record_status && record_status > 10 && isEditMode) {
        enhancedConfig.readonly = true;
      }

      switch (field) {
        case 'application_date':
          enhancedConfig.default = new Date();
          enhancedConfig.readonly = isEditMode;
          if (form === 'A') {
            enhancedConfig.is_required = true;
          }
          break;
        case 'send_date':
          enhancedConfig.default = new Date();
          enhancedConfig.readonly = isEditMode;
          break;
        case 'apply_by':
          enhancedConfig.default = nickname || username;
          break;
        case 'form':
          enhancedConfig.default = 'A';
          break;
        case 'contact_number':
          enhancedConfig.default = mobile_number;
          break;
        case 'job_type':
          if (form === 'A') {
            enhancedConfig.is_required = true;
          }
          if (
            record_status &&
            record_status > 10 &&
            (oldJobType === 'eo' || oldJobType === 'not_release' || oldJobType === 'impending_no') &&
            isEditMode
          ) {
            enhancedConfig.readonly = false;
          }
          break;
        case 'wo_number':
        case 'reason':
          if (
            record_status &&
            record_status > 10 &&
            (oldJobType === 'eo' || oldJobType === 'not_release' || oldJobType === 'impending_no') &&
            isEditMode
          ) {
            enhancedConfig.readonly = false;
          }
          break;
        default:
          break;
      }

      return {
        ...config,
        ...enhancedConfig
      };
    });
  }, [props.schema, record_status, isEditMode, workorderData, nickname, username, mobile_number, form]);

  return {
    isContractEmpty,
    isEditMode,
    schema,
    workorderData,
    form,
    showSubmit
  };
};

export const useWorkorderData = (props) => {
  const { contract, wo_number, record_status } = props;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (contract && wo_number && record_status < 20) {
      fetch('GET', `/api/collection/contract/external/get-contract-worksorder/${contract}/${wo_number}`);
    }
  }, [contract, wo_number]);

  return {
    workorderData: result
  };
};
