import React from 'react';
import { withCode } from './hooks';
import InputEdit from 'components/common/input-edit';

function Code(props) {
  const { schema, value } = props;
  return <InputEdit {...props} schema={schema} value={value} />;
}

export default withCode(Code);
