import { atom, useAtom, useSetAtom } from 'jotai';
import { useLocation, useParams } from 'react-router-dom';
import { useFetch } from 'lib/fetch';
import { useEffect } from 'react';
import { urlStringToObj } from 'lib/query';
import { useSideMenu } from 'components/common/side-menu/hooks';

export const user = atom(undefined);

export const domReady = atom(false);
export const pathname = atom(null);
export const queryString = atom(null);
export const urlParams = atom(null);

const useUser = () => {
  const [userData, setUserData] = useAtom(user);
  const { fetch, result } = useFetch();

  useEffect(() => {
    fetch('GET', '/api/collection/user/current-user');
  }, []);

  useEffect(() => {
    setUserData(result);
  }, [result]);

  return {
    userData
  };
};

export const useLogout = () => {
  const { fetch, result } = useFetch();

  const logout = () => {
    fetch('POST', '/api/collection/user/logout');
  };
  return { logout, result };
};

export const withApp = (WrappedComponent) => {
  return (props) => {
    const { userData } = useUser();
    useSetupSideMenu();
    const [isDomReady, setIsDomReady] = useAtom(domReady);
    const setPathname = useSetAtom(pathname);
    const setQueryString = useSetAtom(queryString);
    const setUrlParams = useSetAtom(urlParams);

    const location = useLocation();
    const params = useParams();

    useEffect(() => {
      if (location?.pathname) {
        setPathname(location.pathname);
      }

      if (location?.search) {
        const queryObj = urlStringToObj(location.search);
        setQueryString(queryObj);
      }

      if (params) {
        setUrlParams(params);
      }
    }, []);

    useEffect(() => {
      if (userData !== undefined) {
        setIsDomReady(true);
      }
    }, [userData]);

    return <WrappedComponent {...props} userData={userData} domReady={isDomReady} />;
  };
};

export const useSetupSideMenu = () => {
  const { userData } = useUser();
  const { hideSideMenuItems } = useSideMenu();
  useEffect(() => {
    if (userData !== undefined && userData !== null && userData?._id) {
      if (!userData?.is_admin) {
        hideSideMenuItems([['PR', 'PR_STATUS']]);
      }
    }
  }, [userData]);
};
