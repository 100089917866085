import * as React from 'react';
import { Upload } from 'antd';
import { Divider, TextField } from '@mui/material';
import { Close, Done } from '@mui/icons-material';
import { InboxOutlined } from '@ant-design/icons';
import { withApprovalForm } from './hooks';

function ApprovalForm(props) {
  const { approve, remarks, setApprove, setRemarks, selectedTier, setSelectedTier, tiers, fileDraggerProps } = props;
  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <div className="w-full flex flex-col gap-4">
        <div className="w-full flex flex-col gap-2">
          <div className="text-xs text-zinc-500">選擇你要批核的層級</div>
          <div className="flex gap-2">
            {tiers.map((tier, i) => (
              <div
                key={i}
                className={`cursor-pointer rounded-md border-2 border-zinc-200 bg-popover w-12 h-12 flex items-center justify-center text-zinc-400 hover:bg-zinc-200 hover:text-zinc-700 ${
                  tier?.tier === selectedTier ? 'text-zinc-700 border-zinc-700' : ''
                }`}
                onClick={() => setSelectedTier(tier?.tier)}
              >
                {tier?.tier}
              </div>
            ))}
          </div>
        </div>
        <Divider />
        <div className="w-full flex flex-col gap-2">
          <div className="text-xs text-zinc-500">附加資訊</div>
          <div className="">
            <Upload.Dragger {...fileDraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-hint">拖拽或點擊上傳檔案</p>
            </Upload.Dragger>
          </div>
          <div className="w-[400px] mt-2">
            <TextField
              disabled={!selectedTier}
              value={remarks}
              multiline
              rows={3}
              label="備註"
              sx={{ width: '100%' }}
              onChange={(e) => setRemarks(e.target.value)}
              required={!approve}
            />
          </div>
        </div>
        <Divider />
        <div className="flex gap-6 justify-center text-sm">
          <div
            className={`flex justify-center gap-2 cursor-pointer items-center border-zinc-400 text-zinc-700 border w-28 py-2 rounded-md hover:bg-green-100 ${
              approve ? 'border-zinc-700 bg-green-100 border-green-400' : ''
            }`}
            onClick={() => {
              setApprove(true);
            }}
          >
            <div className={`${approve ? 'text-green-700' : ''}`}>
              <Done />
            </div>
            <div className="">同意</div>
          </div>
          <div
            className={`flex justify-center gap-2 cursor-pointer items-center border-zinc-400 text-zinc-700 border w-28 py-2 rounded-md hover:bg-red-100 ${
              approve === false ? 'border-zinc-700 bg-red-100 border-red-400' : ''
            }`}
            onClick={() => {
              setApprove(false);
            }}
          >
            <div className={`${approve === false ? 'text-red-700' : ''}`}>
              <Close />
            </div>
            <div className="">不同意</div>
          </div>
        </div>
        {approve !== null && (
          <div className="text-center text-sm text-zinc-500">你{!approve ? '不' : ''}同意此申請</div>
        )}
      </div>
    </div>
  );
}

export default withApprovalForm(ApprovalForm);
