import { useFetch } from 'lib/fetch';
import { map } from 'lodash';
import { useEffect, useMemo } from 'react';

export const withItemTable = (WrappedComponent) => (props) => {
  const { item } = props || {};
  const { _id } = item;
  const { priceHistory } = useItemPriceHistory({ _id: item?.item });

  const rows = useMemo(() => {
    return map(priceHistory, (row) => ({
      ...row,
      prior: row._id === _id
    }));
  }, [priceHistory, _id]);
  return <WrappedComponent {...props} rows={rows} />;
};

export const useItemPriceHistory = (props) => {
  const { _id, before } = props;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (_id) {
      fetch('GET', `/api/collection/item/price-history/${_id}/${before || new Date().getTime()}`);
    }
  }, [_id, before]);

  return {
    priceHistory: result
  };
};
