import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Publish } from '@mui/icons-material';
import { withConfirmQuote } from './hooks';

function ConfirmQuote(props) {
  const { className, open, onSubmit, handleDialogClose, handleDialogOpen, submitted } = props;
  return (
    <div className={`${className} flex justify-end items-center`}>
      <Button
        className="whitespace-nowrap"
        variant="contained"
        color="info"
        endIcon={<Publish />}
        onClick={handleDialogOpen}
      >
        提交批核
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        sx={{
          '.MuiDialog-paper': {
            width: 500
          }
        }}
      >
        <DialogTitle>你確定要提交批核嗎?</DialogTitle>
        <DialogContent>
          <DialogContentText>一旦提交後將無法修改</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>取消</Button>
          <Button onClick={onSubmit} variant="contained" autoFocus disabled={submitted}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withConfirmQuote(ConfirmQuote);
