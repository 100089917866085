import { useCollectionFieldDistinct } from 'hooks/use-collection-field-distinct';
import { find, isEmpty, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const useContractRow = (props) => {
  const { contract, userData, setInputs, readonly } = props;
  const [rowInputs, setRowInputs] = useState({});
  const selectOptions = [
    { _id: -1, label: ' ' },
    { _id: 10, label: 'Yes' },
    { _id: 20, label: 'Sub' }
  ];

  const contractTitle = contract?.code;
  const contractId = contract?._id;

  const records = useMemo(() => {
    const relatedRecords = userData.filter((data) => data?.contract === contract._id);

    return reduce(
      relatedRecords,
      (prev, curr) => {
        prev[curr.tier] = curr.level;
        return prev;
      },
      {}
    );
  }, [contract, userData]);

  useEffect(() => {
    const prefix = `$edit.contract__users[${contract._id}]`;
    const data = reduce(
      rowInputs,
      (prev, curr, key) => {
        prev[`${prefix}.${key}`] = curr;
        return prev;
      },
      {}
    );
    setInputs((v) => {
      const prev = reduce(
        v,
        (prev, curr, key) => {
          if (!key.startsWith(prefix)) {
            prev[key] = curr;
          }
          return prev;
        },
        {}
      );
      return {
        ...prev,
        ...data
      };
    });
  }, [contract, rowInputs]);

  const inputEditProps = {
    className: 'w-28',
    setInputs: setRowInputs,
    schema: {
      input_type: 'select',
      input_variant: 'slim-select',
      selectOptions,
      readonly
    }
  };

  return {
    records,
    inputEditProps,
    contractId,
    contractTitle
  };
};
