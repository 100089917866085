import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { isObject, isString, find, isUndefined } from 'lodash';

function InputEditAutocomplete(props) {
  const { errorMessage, isFocusing, isTouched, groupBy, freesolo, text, options, setText, readonly } = props;

  return (
    <Autocomplete
      disabled={readonly}
      size="small"
      freeSolo={freesolo}
      value={isUndefined(text) ? null : text}
      options={options}
      groupBy={groupBy}
      getOptionLabel={(v) => {
        if (isObject(v) && v?.label) {
          return v.label;
        } else if (isString(v)) {
          const option = find(options, { _id: v });
          return option?.label || v;
        }
        return v;
      }}
      getOptionDisabled={(option) => option?.disabled}
      isOptionEqualToValue={(option, value) => {
        if (option?._id === value?._id) {
          return true;
        }
        if (option?.label === value?.label) {
          return true;
        }

        return false;
      }}
      onInputChange={(event, v, r) => {
        if (event) {
          if (r === 'clear') {
            setText('');
          } else if (r === 'input') {
            if (freesolo) {
              setText(v);
            }
          } else if (r === 'reset') {
            const option = find(options, { label: v });
            if (option?._id) {
              setText(option._id);
            } else {
              setText(v);
            }
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={isTouched.current && !isFocusing ? !!errorMessage : false}
          helperText={errorMessage}
        />
      )}
    />
  );
}

export default InputEditAutocomplete;
