import { filter, isEmpty, reduce, startsWith } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const withContactRow = (WrappedComponent) => {
  return (props) => {
    const { length, setInputs, index, value, body } = props;
    const [data, setData] = useState({});
    const [pull, setPull] = useState(null);
    const pullKey = `$pull.contacts[${value?._id}]`;
    const isPull = pull?.[pullKey]?.touched;

    const person = data.person;
    const phone = data.phone;
    const email = data.email;

    useEffect(() => {
      setData({
        person: { value: value?.person },
        phone: { value: value?.phone },
        email: { value: value?.email }
      });
    }, [value]);

    const push = useMemo(() => {
      if (!value) {
        if (person || phone || email) {
          const key = index + 1 > length ? index - length : index;
          return {
            [`$push.contacts[${key}].person`]: person,
            [`$push.contacts[${key}].phone`]: phone,
            [`$push.contacts[${key}].email`]: email
          };
        }
      }
      return {};
    }, [person, phone, email, value, length, index]);

    const edit = useMemo(() => {
      if (value) {
        return {
          [`$edit.contacts[${value._id}].person`]: person,
          [`$edit.contacts[${value._id}].phone`]: phone,
          [`$edit.contacts[${value._id}].email`]: email
        };
      }
      return {};
    }, [person, phone, email, value]);

    const pullRow = () => {
      if (isPull) {
        setPull({
          [`$pull.contacts[${value?._id}]`]: {
            value: true,
            touched: false
          }
        });
      } else {
        setPull({
          [`$pull.contacts[${value?._id}]`]: {
            value: true,
            touched: true
          }
        });
      }
    };
    useEffect(() => {
      const editExist = !isEmpty(body)
        ? !isEmpty(filter(Object.keys(body), (k) => startsWith(k, '$edit.contacts')))
        : false;
      const ignoreKeys = Object.keys({ ...push, ...edit, ...pull });
      setInputs((v) => {
        return {
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!ignoreKeys.includes(key)) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          ),
          ...reduce(
            { ...push, ...edit, ...pull },
            (prev, curr, key) => {
              if (startsWith(key, '$pull.contacts') && editExist) {
                return prev;
              } else {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          )
        };
      });

      return () => {
        setInputs((v) => ({
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!ignoreKeys.includes(key)) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          )
        }));
      };
    }, [edit, push, pull, JSON.stringify(body)]);

    return (
      <WrappedComponent
        {...props}
        person={person}
        phone={phone}
        email={email}
        setData={setData}
        pullRow={pullRow}
        isPull={isPull}
      />
    );
  };
};
