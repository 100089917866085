import { useFetch } from 'lib/fetch';
import { useCallback, useEffect, useMemo } from 'react';

export const useCollectionListing = (config, setting) => {
  const { collection, optionsUrl } = config || {};
  const { fetchOnInit = true } = setting || {};
  const { fetch, result } = useFetch();

  const url = useMemo(() => {
    if (optionsUrl) return optionsUrl;
    if (!collection) return;
    return `/api/collection/${collection}/listing`;
  }, [collection, optionsUrl]);

  const fetchOptions = useCallback(() => {
    fetch('GET', url);
  }, [url]);

  useEffect(() => {
    if (fetchOnInit && url) {
      fetchOptions();
    }
  }, []);

  return {
    fetchOptions,
    data: result
  };
};
