import React from 'react';
import dayjs from 'dayjs';
import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, tooltipClasses } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

import Tooltip from 'components/common/tooltip';

function Row({ option, onClick }) {
  return (
    <ListItem
      secondaryAction={
        option?.info &&
        option.info?.updated_at && (
          <div className="flex items-center justify-center">
            <Tooltip
              title={<div className="">於 {dayjs(option.info?.updated_at).format('YYYY-MM-DD HH:mm')} 新增</div>}
              placement="top"
              sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                  width: 'max-content',
                  fontSize: '12px'
                }
              }}
            >
              <HelpOutline fontSize="8px" sx={{ fill: '#a29f9f' }} />
            </Tooltip>
          </div>
        )
      }
      disablePadding
    >
      <ListItemButton role={undefined} onClick={onClick} dense>
        <ListItemIcon sx={{ '.MuiCheckbox-root': { padding: 0 } }}>
          <Checkbox edge="start" checked={option?.selected} tabIndex={-1} disableRipple onClick={onClick} />
        </ListItemIcon>
        <ListItemText primary={option?.label} />
      </ListItemButton>
    </ListItem>
  );
}

export default Row;
