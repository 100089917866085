import { useCsvDownloader } from 'components/common/csv-downloader/hooks';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';

function DownloadData({ context }) {
  const { data, config } = useContext(context);
  const { Components } = config || {};

  const { downloadButton } = Components || {};

  const { Component } = useCsvDownloader({ filename: downloadButton?.title || 'table-data', data });

  if (downloadButton?.active === false) return null;

  return Component;
}

export default DownloadData;
