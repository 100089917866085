import { isEmpty, map } from 'lodash';
import { parse } from 'papaparse';
import { useEffect, useMemo, useState } from 'react';

import CsvUpload from './index';
import { useFetch } from 'lib/fetch';

export const useCsvUpload = (props) => {
  const { url, tableHeaders } = props;
  const [rawData, setRawData] = useState([]);
  const { file, onFileChange } = useFile({ setRawData });
  const { fetch, status, isLoading } = useFetch();

  const headers = useMemo(() => {
    if (tableHeaders) return tableHeaders();

    if (!isEmpty(rawData)) {
      return Object.keys(rawData[0]);
    }

    return [];
  }, [tableHeaders, rawData]);

  const save = () => {
    if (file instanceof File) {
      fetch('POST', url, {
        params: {
          file
        }
      });
    }
  };

  const data = {
    rawData,
    file
  };

  const config = {
    headers
  };

  const controllers = {
    onFileChange,
    save
  };

  return {
    data,
    controllers,
    Component: <CsvUpload controllers={controllers} data={data} config={config} />
  };
};

const useFile = (props) => {
  const { setRawData } = props;
  const [file, setFile] = useState([]);

  const onFileChange = (v) => {
    parse(v, {
      header: true,
      complete: function (results) {
        console.log(results);
        if (results && results.data) {
          console.log('Finished:', results.data);
          setRawData((v) => {
            return [...v, ...results.data];
          });
        } else {
          console.log('Finished: no data');
        }
      }
    });
    setFile(v);
  };

  return { file, onFileChange };
};
