import React from 'react';
import { Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';

import ProjectType from './components/project-type';
import District from './components/district';
import Contract from './components/contract';
import { useGeneralTab } from './hooks';
import Submit from './components/submit';
import RecordStatus from './components/record-status';
import EntityTags from 'components/common/entity-tags';
import Attachments from './components/attachments';
import JobType from './components/job-type';
import Contractors from './components/contractors';
import ConfirmQuote from './components/confirm-quote';
import Delivery from './components/delivery';
import AmendmentButton from './components/amendment-button';

function General(props) {
  const { schema, isEditMode, isContractEmpty, form, showSubmit } = useGeneralTab(props);
  const { data, setInputs, body, contractUserData } = props;

  const inputEditProps = {
    schema,
    setInputs
  };

  return (
    <Section title="General" isLoading={!schema}>
      <div className="flex flex-col gap-2">
        <div className="flex w-full gap-4 items-center">
          <div className="basis-1/2 flex gap-4">
            <Contract {...inputEditProps} field="contract" value={data?.contract} className="grow" />
            <div className="">
              <InputEdit
                {...inputEditProps}
                overrideSchema={{ readonly: isEditMode }}
                field="form"
                value={data?.form}
              />
            </div>
          </div>
          <div className="basis-1/2 flex justify-end gap-2">
            {/* <AmendmentButton data={data} body={body} />
            <Submit data={data} body={body} showSubmit={showSubmit} />
            <ConfirmQuote data={data} body={body} /> */}
          </div>
        </div>
        {!isContractEmpty && (
          <>
            <div className="my-2">
              <Divider />
            </div>
            <div className="flex w-full gap-4">
              <InputEdit {...inputEditProps} field="apply_by" value={data?.apply_by} className="basis-1/3" />
              <InputEdit
                {...inputEditProps}
                field="contact_number"
                value={data?.contact_number}
                className="basis-1/3"
              />
              <div className="basis-1/3" />
            </div>

            {form === 'A' && (
              <>
                <div className="flex w-full gap-4">
                  <InputEdit
                    {...inputEditProps}
                    field="application_date"
                    value={isEditMode ? data?.application_date : undefined}
                    className="basis-1/3"
                  />
                  <InputEdit {...inputEditProps} field="send_date" value={data?.send_date} className="basis-1/3" />
                  <div className="basis-1/3" />
                </div>

                <div className="flex w-full gap-4">
                  <InputEdit {...inputEditProps} field="start_date" value={data?.start_date} className="basis-1/3" />
                  <InputEdit {...inputEditProps} field="end_date" value={data?.end_date} className="basis-1/3" />
                  <div className="basis-1/3" />
                </div>

                <div className="flex w-full gap-4">
                  <InputEdit {...inputEditProps} field="budget" value={data?.budget} className="basis-1/3" />
                  <InputEdit
                    {...inputEditProps}
                    field="item_total_price"
                    value={data?.item_total_price}
                    className="basis-1/3"
                  />
                  <InputEdit
                    {...inputEditProps}
                    field="district_code"
                    value={data?.district_code}
                    className="basis-1/3"
                  />
                </div>
                <div className="flex w-full gap-4">
                  <JobType {...inputEditProps} field="job_type" data={data} body={body} />
                </div>
              </>
            )}
            <Delivery inputEditProps={inputEditProps} data={data} body={body} />
            <Contractors
              {...inputEditProps}
              field="contractors"
              value={data?.contractors}
              data={data}
              body={body}
              isEditMode={isEditMode}
              className="basis-1/3"
            />

            <div className="my-2">
              <Divider />
            </div>

            <div className="flex w-full gap-4">
              <InputEdit
                {...inputEditProps}
                field="pr__items_nature"
                value={data?.pr__items_nature}
                schema={{ type: 'text', readonly: true, title: '工程性質' }}
                className="w-full"
              />
            </div>
            <div className="flex w-full gap-4">
              {/* <div className="flex flex-col gap-2 basis-1/3">
                <ProjectType {...inputEditProps} field="project_type" value={data?.project_type} className="" />
              </div> */}
              <InputEdit {...inputEditProps} field="remark" value={data?.remark} className="w-full" />
            </div>
            <div className="flex w-full gap-4">
              <Attachments {...inputEditProps} field="attachments" value={data?.attachments} className="w-full" />
            </div>
            <div className="flex w-full">
              {/* <EntityTags
                {...inputEditProps}
                data={data?.tags}
                className="basis-2/3"
                collection="pr"
                disabled={isEditMode && data?.record_status > 10}
              /> */}
            </div>
            {isEditMode && (
              <>
                <div className="my-4">
                  <Divider />
                </div>
                <div className="flex w-full gap-2">
                  <RecordStatus data={data} />
                </div>
                <div className="flex w-full gap-4">
                  <InputEdit
                    {...inputEditProps}
                    field="created_by"
                    value={isEditMode ? data?.created_by?.nickname : undefined}
                    schema={{ type: 'text', readonly: true, title: '開單人' }}
                    className="basis-1/3"
                  />
                  <InputEdit
                    {...inputEditProps}
                    field="created_at"
                    value={isEditMode ? data?.created_at : undefined}
                    schema={{ type: 'date', readonly: true, title: '開單時間', format: 'YYYY-MM-DD HH:mm' }}
                    className="basis-1/3"
                  />
                  <InputEdit
                    {...inputEditProps}
                    field="code"
                    value={isEditMode ? data?.code : undefined}
                    className="basis-1/3"
                  />
                </div>
                <div className="flex w-full gap-4">
                  <InputEdit
                    {...inputEditProps}
                    field="updated_by"
                    value={isEditMode ? data?.updated_by?.nickname || data?.created_by?.nickname : undefined}
                    schema={{ type: 'text', readonly: true, title: '改單人' }}
                    className="basis-1/3"
                  />
                  <InputEdit
                    {...inputEditProps}
                    field="updated_at"
                    value={isEditMode ? data?.updated_at || data?.created_at : undefined}
                    schema={{ type: 'date', readonly: true, title: '改單時間', format: 'YYYY-MM-DD HH:mm' }}
                    className="basis-1/3"
                  />
                  {/* <InputEdit
                    {...inputEditProps}
                    field="last_printed_at"
                    schema={{
                      type: 'date',
                      readonly: true,
                      title: `最後列印時間 ${data?.total_print_count ? `[ ${data.total_print_count}次 ]` : ''}`,
                      format: 'YYYY-MM-DD HH:mm'
                    }}
                    value={isEditMode ? data?.last_printed_at : undefined}
                    className="basis-1/3"
                  /> */}
                  <InputEdit
                    {...inputEditProps}
                    field="submit_quote_at"
                    schema={{
                      type: 'date',
                      readonly: true,
                      title: `索取報價時間`,
                      format: 'YYYY-MM-DD HH:mm'
                    }}
                    value={isEditMode ? data?.submit_quote_at : undefined}
                    className="basis-1/3"
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Section>
  );
}

export default General;
