import { useTable } from 'components/common/table/hooks';
import { redirect } from 'lib/location';
import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo } from 'react';

export const withMergeTo = (WrappedComponent) => {
  return (props) => {
    const { _id, value, field, setInputs, schema, inputs, mode, itemOptions } = props;

    const selectOptions = useMemo(() => {
      if (isEmpty(itemOptions)) return [];

      return map(itemOptions, (row) => {
        return {
          _id: row._id,
          label: `[${row?.code}] ${row?.name}`,
          disabled: row._id === _id
        };
      });
    }, [_id, itemOptions]);

    const selectedValue = useMemo(() => {
      return value ? find(selectOptions, { _id: value })?.label : undefined;
    }, [selectOptions, value]);

    return <WrappedComponent {...props} value={selectedValue} selectOptions={selectOptions} />;
  };
};
