import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { isEmpty } from 'lodash';
import { CloseOutlined } from '@mui/icons-material';

export const withCsrTable = (WrappedComponent) => {
  return (props) => {
    const { data, columns } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = useCallback(
      (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder="搜尋"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                display: 'block',
                marginBottom: 8,
                width: 120
              }}
            />
            <Space>
              <Button
                type="link"
                size="small"
                icon={<CloseOutlined />}
                onClick={() => {
                  clearFilters() && handleReset(clearFilters());
                  setSelectedKeys('');
                  handleSearch('', confirm, dataIndex);
                  close();
                }}
              />
              <Button
                type="default"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
              >
                Search
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : undefined
            }}
          />
        ),
        onFilter: (value, record) => record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text?.toString() : ''}
            />
          ) : (
            text
          )
      }),
      [searchText, searchedColumn]
    );

    const cols = useMemo(() => {
      if (isEmpty(columns)) return [];

      return columns.map((col) => {
        const { sortable = true, searchable = true } = col;
        return {
          width: 100,
          dataIndex: col?.key,
          sorter: sortable ? (a, b) => (a[col.key] > b[col.key] ? 1 : -1) : undefined,
          ...(searchable ? getColumnSearchProps(col.key) : {}),
          ...col
        };
      });
    }, [columns, getColumnSearchProps]);

    return <WrappedComponent {...props} columns={cols} data={data} />;
  };
};
