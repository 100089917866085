import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { withCsvUploadTable } from './hooks';

function CsvUploadTable(props) {
  const { rows, columns } = props;
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 30 }
        }
      }}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      pageSizeOptions={[30, 60, 90]}
      autoPageSize
      density="compact"
    />
  );
}

export default withCsvUploadTable(CsvUploadTable);
