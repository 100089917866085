import React from 'react';
import { Checkbox } from '@mui/material';

function BooleanCheckbox(props) {
  const { isOn, setIsOn, readonly } = props;

  return <Checkbox disabled={readonly} checked={isOn} onChange={() => setIsOn(!isOn)} />;
}

export default BooleanCheckbox;
