import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const useInputDate = (props) => {
  const { defaultValue, field, setInputs, config } = props;
  const [date, setDate] = useState(null);
  const isTouched = useRef(null);

  const { is_required, readonly, forceUpdate } = config;

  useEffect(() => {
    const value = dayjs(defaultValue);
    if (defaultValue && value.isValid()) {
      setDate(value);
    } else {
      setDate(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (readonly) return;
    setInputs((v) => {
      return {
        ...v,
        [field]: {
          value: isNull(date) ? '' : new Date(date).toISOString(),
          touched: forceUpdate ? true : !!isTouched.current,
          error: is_required ? isNull(date) : false
        }
      };
    });
  }, [field, date, setInputs, readonly, is_required, forceUpdate]);

  return {
    date,
    setDate: (date) => {
      if (!isTouched.current) {
        isTouched.current = true;
      }
      const value = dayjs(date);
      if (value.isValid()) {
        setDate(date.toISOString());
      } else {
        setDate(null);
      }
    }
  };
};
