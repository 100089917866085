import { useEffect, useMemo, useState } from 'react';

export const withCurrency = (WrappedComponent) => {
  return (props) => {
    const { value, controllers, index } = props;
    const { editRow } = controllers;

    const [data, setData] = useState({ currency: { value } });

    useEffect(() => {
      if (data?.currency?.touched) {
        editRow(index, 'currency', data.currency?.value);
      }
    }, [data?.currency?.touched, data.currency?.value, index, editRow]);

    return <WrappedComponent {...props} value={value} setInputs={setData} key={index} />;
  };
};
