import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withPage } from './with-page';
import { useFetch } from 'lib/fetch';
import { useCollectionConfig } from './use-collection-config';

export const withUploadPage = (WrappedComponent) => {
  const WithPageComponent = withPage(WrappedComponent);
  return (props) => {
    const { collection } = props || {};

    const collectionConfig = useCollectionConfig({ collection });

    const dataProps = {
      schema: collectionConfig?.schema
    };

    return <WithPageComponent {...props} {...dataProps} />;
  };
};
