import { useAtomValue } from 'jotai';
import { usePrSubmitConfirmQuote } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { user } from 'store';

export const withConfirmQuote = (WrappedComponent) => {
  return (props) => {
    const { body, className, data } = props;
    const currentUser = useAtomValue(user);
    const [open, setOpen] = useState(false);
    const { onSubmit, isLoading, result } = usePrSubmitConfirmQuote({ _id: data?._id });

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    useEffect(() => {
      if (result) {
        redirect(`/pr/profile/${data._id}`);
      }
    }, [result, data]);

    const showButton =
      data && data?.record_status && data?.record_status === 35 && currentUser?._id === data?.created_by?._id;

    if (showButton) {
      return (
        <WrappedComponent
          {...props}
          open={open}
          onSubmit={onSubmit}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          submitted={isLoading || result}
        />
      );
    }
    return null;
  };
};
