import React from 'react';

function DataRow(props) {
  const { title, data, size, className } = props;
  return (
    <h2 className={`flex gap-4 text-sm ${className}`}>
      <div className={`${size === 'large' ? 'w-[130px]' : size === 'mid' ? 'w-[120px]' : 'w-[90px]'} shrink-0`}>
        {title}:{' '}
      </div>
      <div className="flex border-b-2 border-zinc-200 grow pl-1 items-end ">{data}</div>
    </h2>
  );
}

export default DataRow;
