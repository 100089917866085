import React from 'react';

import { Box, Skeleton } from '@mui/material';
import { Empty } from 'antd';

function TableLayout(props) {
  const { className, children, isLoading, showEmpty, thead } = props;
  return (
    <table className={`w-max-content mx-auto bg-white rounded-xl table-fixed ${className}`}>
      {showEmpty || isLoading ? (
        <>
          {thead ? thead : null}
          <tbody>
            {showEmpty ? (
              <tr>
                <td className="py-4" colSpan={10000}>
                  <Empty description="暫無資料" />
                </td>
              </tr>
            ) : (
              Array.from({ length: 10 }).map((_, index) => (
                <tr>
                  <td className="px-4" colSpan={10000}>
                    <Skeleton key={index} height="40px" />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </>
      ) : (
        <>
          {thead ? thead : null}
          {children}
        </>
      )}
    </table>
  );
}

export default TableLayout;
