import { useTable } from 'components/common/table/hooks';
import { useFetch } from 'lib/fetch';
import { redirect } from 'lib/location';
import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo } from 'react';

export const withContract = (WrappedComponent) => {
  return (props) => {
    const { _id, value, field, setInputs, schema, inputs } = props;
    const { fetch, result } = useFetch();

    useEffect(() => {
      fetch('GET', '/api/collection/pr/select-contracts');
    }, []);

    const title = useMemo(() => {
      const a = find(schema, { field });
      return a?.title || '合約*';
    }, [schema, field]);

    const options = useMemo(() => {
      if (isEmpty(result?.records)) {
        if (value) {
          return [value];
        }
        return [];
      }
      return map(result.records, (row) => ({
        _id: row._id,
        label: `[${row?.code}] ${row?.title}`
      }));
    }, [result]);

    const readonly = useMemo(() => {
      const a = find(schema, { field });
      return a?.readonly;
    }, [schema, field]);
    return (
      <WrappedComponent
        {...props}
        title={title}
        options={options}
        defaultValue={value}
        schema={schema}
        readonly={readonly}
      />
    );
  };
};
