import React, { useEffect, useMemo, useState } from 'react';
import { difference, find, isEmpty, filter, groupBy, map, reduce, startsWith, omit } from 'lodash';
import { useAtomValue } from 'jotai';
import { useDataEdit } from 'hooks/use-data-edit';
import { useTopNav } from 'components/common/top-nav/hooks';
import { redirect } from 'lib/location';
import To from 'components/common/to';
import { Button } from '@mui/material';
import { Print } from '@mui/icons-material';
import { useEntityLogs } from 'components/common/entity-logs/hooks';
import { useFetch } from 'lib/fetch';
import { usePrintLog } from 'hooks/use-print-log';
import { user } from 'store';
import PdfDownloadButton from '../print-form';

import Submit from './tabs/general/components/submit';
import ConfirmQuote from './tabs/general/components/confirm-quote';
import AmendmentButton from './tabs/general/components/amendment-button';

export const useProfile = ({ pageProps, currentTab }) => {
  const { _id, mode, collection, metadata, isLoading, isEditMode, isCopyMode } = pageProps;
  const url = isEditMode
    ? `/api/collection/${collection}/edit/${_id}`
    : isCopyMode
    ? `/api/collection/${collection}/copy/${_id}`
    : `/api/collection/${collection}/new`;
  const { Component: EntityLogs, refetch: refetchLogs } = useEntityLogs({ _id });
  const { setTopNav } = useTopNav();
  const { print } = useProfilePrint({ _id });
  const refetch = () => {
    pageProps?.fetchPageData();
    refetchLogs();
  };
  const { body, data, config, controller, inputs, save, canSave } = useDataEdit({
    mode,
    value: pageProps,
    config: { editable: false, ...pageProps },
    url,
    refetch,
    success:
      mode === 'new' || mode === 'copy' || mode === 'amendment'
        ? (result) => {
            if (result && result?._id) {
              redirect(`/${collection}/profile/${result._id}`);
            }
          }
        : undefined
  });
  const userProfile = useUserProfile();

  const { schema, fieldsToDisplay = [], isEdit } = config;
  const { setInputs } = controller;

  const { historyData } = useApprovalHistoryData({ _id });
  const { contractUserData } = useContractUserData({ _id: data?.contract?._id });
  const { prUserData } = usePrUserData({ _id });
  const approveButtonState = useApprovePopupState({ data, historyData, prUserData });

  const contract_user_options = useMemo(() => {
    const liveOptions = groupBy(
      filter(contractUserData, (row) => row.level === 10),
      'tier'
    );
    return Object.keys(omit(liveOptions, 'Issue'));
  }, [JSON.stringify(contractUserData)]);

  const contract_user_records = useMemo(() => {
    const liveOptions = groupBy(
      filter(data?.pr__users, (row) => row.tier),
      'tier'
    );
    return Object.keys(omit(liveOptions, 'Issue'));
  }, [JSON.stringify(data?.pr__users)]);

  const showSubmit =
    contract_user_options.sort().toString() === contract_user_records.sort().toString() &&
    data?.pr__items.reduce((n, { quantity }) => n + parseInt(quantity), 0) > 0;

  useEffect(() => {
    const copy = false
      ? () => {
          redirect(`/${collection}/copy/${_id}`);
        }
      : null;
    const title = `${config?.title} ${data?.code ? '(' + data?.code + ')' : ''} `;
    //
    const element = isEdit ? (
      <div className="flex flex-wrap gap-1">
        <div>
          <AmendmentButton data={data} body={body} />
        </div>
        <div>
          <Submit data={data} body={body} showSubmit={showSubmit} />
        </div>
        <div>
          <ConfirmQuote data={data} body={body} />
        </div>
        {/* <div>
          <PdfDownloadButton _id={_id} print={print} />
        </div> */}
        <div>
          <Button variant="outlined" endIcon={<Print />}>
            <To href={`/pr/summary-form/${data?._id}`} toNew>
              Print PR View
            </To>
          </Button>
        </div>
      </div>
    ) : null;
    if (!currentTab || currentTab < 5) {
      setTopNav({
        _id,
        save,
        canSave,
        copy,
        title,
        element
        // print: isEdit
        //   ? () => {
        //       print();
        //       refetch();
        //     }
        //   : null
      });
    } else {
      setTopNav({ save: undefined, canSave: undefined, copy: undefined, element });
    }
  }, [_id, collection, isEdit, save, canSave, currentTab, data, config]);

  useEffect(() => {
    console.log('PR Data --- ', data);
  }, [data]);

  return {
    _id,
    body,
    data,
    config,
    controller,
    save,
    canSave,
    metadata,
    schema,
    fieldsToDisplay,
    inputs,
    setInputs,
    isLoading,
    userProfile,
    approveButtonState,
    historyData,
    contractUserData,
    prUserData,
    EntityLogs
  };
};

export const useProfilePrint = (props) => {
  const { _id } = props;
  const { print } = usePrintLog({ collection: 'pr', _id });
  return {
    print
  };
};

export const useApprovalHistoryData = (props) => {
  const { _id } = props;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (_id) {
      fetch('GET', `/api/collection/pr-approval/history/${_id}`);
    }
  }, [_id]);

  return {
    historyData: result
  };
};

export const usePrUserData = (props) => {
  const { _id } = props;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (_id) {
      fetch('GET', `/api/collection/pr-user/pr/${_id}`);
    }
  }, [_id]);

  return {
    prUserData: result
  };
};

export const useContractUserData = (props) => {
  const { _id } = props;
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (_id) {
      fetch('GET', `/api/collection/contract-user/contract/${_id}`);
    }
  }, [_id]);

  return {
    contractUserData: result
  };
};

export const useUserProfile = () => {
  const { _id } = useAtomValue(user);
  const { fetch, result = {} } = useFetch();

  useEffect(() => {
    if (_id) {
      fetch('GET', `/api/collection/user/get-profile/${_id}`);
    }
  }, [_id]);

  return result?.data || {};
};

export const useApprovePopupState = (props) => {
  const { historyData = [], prUserData = [], data } = props;
  const [open, setOpen] = useState(false);

  const handlePopupOpen = () => setOpen(true);
  const handlePopupClose = () => setOpen(false);

  const currentUser = useAtomValue(user);

  const currentUserId = currentUser?._id;
  const userLevel = useMemo(() => {
    return reduce(
      prUserData,
      (prev, curr, index) => {
        if (curr?.user?._id === currentUserId) {
          prev[curr?.tier] = curr?.level;
        }
        return prev;
      },
      {}
    );
  }, [prUserData, currentUserId]);

  const tiers = useMemo(() => {
    const { record_status } = data || {};
    if (!record_status || record_status < 40) return [];
    const activeTiers =
      record_status === 40
        ? ['A1']
        : record_status === 50
        ? ['A2', 'A3', 'A4']
        : record_status === 60
        ? ['A5', 'A6', 'A7']
        : [];
    return activeTiers
      .map((tier) => {
        const record = find(historyData, { tier });
        return {
          tier,
          record,
          disabled: !!record || !userLevel[tier] || userLevel[tier] < 10 || data?.[`skip_${tier}`] === true
        };
      })
      .filter((record) => !record.disabled);
  }, [historyData, userLevel, data]);

  return {
    showButton: data && data?.record_status && data?.record_status >= 40 && tiers.length > 0,
    tiers,
    userLevel,
    open,
    handlePopupOpen,
    handlePopupClose
  };
};
