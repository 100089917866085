import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Close, Done } from '@mui/icons-material';
import { withApprovalButton } from './hooks';

function ApproveButton(props) {
  const { onSubmit, open, setOpen } = props;
  return (
    <div className="flex gap-2 justify-end">
      <Button variant="contained" onClick={() => setOpen(true)} endIcon={<Done />}>
        提交
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>你確定要提交訂購(扣數)文件嗎?</DialogTitle>
        <DialogContent>
          <DialogContentText>一旦提交後將無法修改</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between w-full">
            <Button onClick={() => setOpen(false)}>取消</Button>
            <div className="flex gap-4">
              <Button variant="contained" color="success" startIcon={<Done />} onClick={() => onSubmit(true)}>
                確定
              </Button>
              {/* <Button variant="contained" color="error" startIcon={<Close />} onClick={() => onSubmit(false)}>
                拒批
              </Button> */}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withApprovalButton(ApproveButton);
