import { useTable } from 'components/common/table/hooks';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withContract = (WrappedComponent) => {
  return (props) => {
    const currentUser = useAtomValue(user);
    const isAdmin = currentUser?.is_admin;

    const readonly = !isAdmin;

    // const { data = [], isLoading: dataIsLoading } = useTable({
    //   url: '/api/collection/contract/listing',
    //   paramsPreset: {
    //     pageSize: 10000
    //   }
    // });
    // const { data: userData = [], isLoading: contractDataIsLoading } = useTable({
    //   url: '/api/collection/contract-user/listing',
    //   paramsPreset: {
    //     pageSize: 10000,
    //     filters: [
    //       {
    //         field: 'user',
    //         op: 'eq',
    //         value: props._id
    //       }
    //     ]
    //   }
    // });

    // const isLoading = dataIsLoading || contractDataIsLoading;

    // return <WrappedComponent {...props} contracts={data} userData={userData} isLoading={isLoading} />;

    return <WrappedComponent {...props} readonly={readonly} />;
  };
};
