import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/payment/index';
import ProfilePage from 'pages/payment/profile';

const collection = 'payment';
const menuItem = ['DATABASE', 'PAYMENT'];

const routes = [
  <Route
    path={`/${collection}`}
    element={<IndexPage menuItem={menuItem} title="Payment Term管理" collection={collection} />}
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="Payment Term" collection={collection} editMode="edit" />}
  />,
  <Route
    path={`/${collection}/copy/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增Payment Term" collection={collection} editMode="copy" />}
  />,
  <Route
    path={`/${collection}/new`}
    element={<ProfilePage menuItem={menuItem} title="新增Payment Term" collection={collection} editMode="new" />}
  />
];

export default routes;
