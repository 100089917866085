import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/supplier/index';
import ProfilePage from 'pages/supplier/profile';
import UploadPage from 'pages/supplier/upload';

const collection = 'supplier';
const menuItem = ['DATABASE', 'SUPPLIER'];

const routes = [
  <Route
    path={`/${collection}`}
    element={<IndexPage menuItem={menuItem} title="供應商列表" collection={collection} />}
  />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="供應商" collection={collection} editMode="edit" />}
  />,
  <Route
    path={`/${collection}/copy/:_id`}
    element={<ProfilePage menuItem={menuItem} title="新增供應商" collection={collection} editMode="copy" />}
  />,
  <Route
    path={`/${collection}/new`}
    element={<ProfilePage menuItem={menuItem} title="新增供應商" collection={collection} editMode="new" />}
  />,
  <Route
    path={`/${collection}/import`}
    element={<UploadPage menuItem={menuItem} title="批量新增供應商" collection={collection} />}
  />
];

export default routes;
