import React from 'react';
import { useProfile } from './hooks';
import { withProfilePage } from 'hooks/with-profile-page';
import { useTabs } from 'components/common/tabs/hooks';
import Metadata from 'components/common/metadata';
import General from './tabs/general';

function CurrencyProfilePage(pageProps) {
  const { data, metadata, schema, setInputs, EntityLogs } = useProfile({ pageProps });

  const { isEditMode, mode } = pageProps;

  const { Component, tabProps } = useTabs();
  const { Tabs, Tab } = Component;

  return (
    <div className="flex flex-col gap-4">
      <Metadata metadata={metadata} />
      <Tabs {...tabProps}>
        <Tab title="General" id="info">
          <General schema={schema} setInputs={setInputs} data={data} mode={mode} />
        </Tab>
        {/* {isEditMode ? (
          <Tab title="Logs" id="logs">
            <Section title="Logs">{EntityLogs}</Section>
          </Tab>
        ) : null} */}
      </Tabs>
    </div>
  );
}

export default withProfilePage(CurrencyProfilePage);
