import React, { useContext } from 'react';
import Th from './th';
import { find } from 'lodash';

function Thead({ context }) {
  const { fieldsToDisplay, schema } = useContext(context);
  if (!fieldsToDisplay) return null;
  return (
    <thead className="text-md border-b-2">
      <tr>
        {fieldsToDisplay.map((field) => {
          const text = find(schema, { field })?.title || field;
          return <Th text={text} field={field} key={field} context={context} />;
        })}
        <th className="px-3 rounded-sm text-left text-xs bg-neutral-50 font-normal h-10 new_tab"></th>
      </tr>
    </thead>
  );
}

export default Thead;
