import React from 'react';
import { withApprovalHistory } from './hooks';
import InputFileFilename from 'components/common/input-edit/file/filename';
import { parseISODateString } from 'lib/date';

function ApprovalHistory(props) {
  const { rows } = props;
  return (
    <div className="flex flex-col gap-2 mt-4">
      <div className="">
        <div className="text-sm text-zinc-600">批核紀錄</div>
      </div>
      {rows.map((record, index) => (
        <div className="px-4 py-2 rounded-md border border-zinc-200 flex gap-2 items-center text-xs" key={index}>
          <div className="text-sm text-zinc-500 w-8 text-center">{record?.tier}</div>
          <div className="w-20 text-center">
            {record?.is_approve === true
              ? '批准'
              : record?.skipped
              ? '跳過'
              : record?.is_approve === false
              ? '拒批'
              : ''}
          </div>
          <div className="border h-4" />
          <div className="w-40">{record?.user?.nickname}</div>
          <div className="border h-4" />
          <div className="w-20 text-center">{parseISODateString(record?.created_at, { show_datetime: true })}</div>
          <div className="border h-4" />
          <div className="w-60">{record?.remarks}</div>
          <div className="border h-4" />
          <div className="w-[380px]">
            {record?.attachments
              ? record?.attachments.map((attachment, index) => (
                  <div className="flex gap-2 items-center" key={index}>
                    <div className="text-zinc-500">{index + 1}</div>
                    <InputFileFilename key={attachment?._id} value={attachment} />
                  </div>
                ))
              : null}
          </div>
        </div>
      ))}
    </div>
  );
}

export default withApprovalHistory(ApprovalHistory);
