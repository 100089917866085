import { useEffect, useMemo, useState } from 'react';
import { difference, find, isEmpty, filter, groupBy, map, reduce, startsWith } from 'lodash';
import { useContractUserData } from 'pages/pr/profile/hooks';

export const withContractUser = (WrappedComponent) => {
  return (props) => {
    const { contract, data, isEditMode, setInputs, disabled } = props;
    const { contractUserData } = useContractUserData({ _id: contract });
    const [checkedList, setCheckedList] = useState({});

    const options = useMemo(() => {
      if (!disabled) {
        const liveOptions = groupBy(
          filter(contractUserData, (row) => row.level === 10),
          'tier'
        );

        return reduce(
          liveOptions,
          (prev, curr, tier) => {
            prev[tier] = curr.map((row) => ({ label: row?.user?.nickname, value: row?.user?._id, disabled }));
            return prev;
          },
          {}
        );
      } else if (data) {
        return reduce(
          data,
          (prev, row) => {
            prev[row.tier] = [
              ...(prev[row.tier] || []),
              {
                label: row?.user?.nickname,
                value: row?.user?._id,
                disabled: true
              }
            ];
            return prev;
          },
          {}
        );
      }
      return [];
    }, [JSON.stringify(contractUserData), disabled, data]);

    const onChange = (tier, list) => {
      setCheckedList((v) => {
        return {
          ...v,
          [tier]: difference(list, v[tier])
        };
      });
    };

    useEffect(() => {
      if (isEmpty(data)) {
        const contractData = groupBy(
          filter(contractUserData, (row) => row.level === 10),
          'tier'
        );

        setCheckedList(
          reduce(
            contractData,
            (prev, curr, tier) => {
              if (curr.length == 1 && curr[0]?.user?._id) {
                prev[tier] = [curr[0]?.user?._id];
              }
              return prev;
            },
            {}
          )
        );
      } else {
        setCheckedList(
          reduce(
            data,
            (prev, curr) => {
              const { tier, user } = curr;
              prev[tier] = [...(prev[tier] || []), user?._id];
              return prev;
            },
            {}
          )
        );
      }
    }, [data, contractUserData]);

    useEffect(() => {
      const records = reduce(
        checkedList,
        (prev, users, tier) => {
          prev.push(...map(users, (user) => ({ user, tier })));
          return prev;
        },
        []
      );

      const push = reduce(
        records,
        (prev, record, index) => {
          if (!find(data, { tier: record.tier, user: { _id: record.user } })) {
            prev[`$push.pr__users[${index}].tier`] = {
              value: record.tier,
              touched: true
            };
            prev[`$push.pr__users[${index}].user`] = {
              value: record.user,
              touched: true
            };
          }
          return prev;
        },
        {}
      );

      const pull = reduce(
        data,
        (prev, record, index) => {
          if (!find(records, { tier: record.tier, user: record.user._id })) {
            prev[`$pull.pr__users[${index}]`] = {
              value: record._id,
              touched: true
            };
          }
          return prev;
        },
        {}
      );

      setInputs((v) => ({
        ...reduce(
          v,
          (prev, curr, key) => {
            if (!(startsWith(key, '$push.pr__users') || startsWith(key, '$pull.pr__users'))) {
              prev[key] = curr;
            }
            return prev;
          },
          {}
        ),
        ...push,
        ...pull
      }));

      return () => {
        setInputs((v) => ({
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!(startsWith(key, '$push.pr__users') || startsWith(key, '$pull.pr__users'))) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          )
        }));
      };
    }, [checkedList, data]);

    return <WrappedComponent {...props} checkedList={checkedList} onChange={onChange} options={options} />;
  };
};
