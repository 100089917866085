import React from 'react';
import { withReference } from './hooks';
import InputEdit from 'components/common/input-edit';

function Reference(props) {
  const { field, schema, value, setInputs } = props;
  return <InputEdit {...props} field={field} schema={schema} value={value} setInputs={setInputs} />;
}

export default withReference(Reference);
