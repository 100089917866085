import { useCsvUpload } from 'components/common/csv-upload/hooks';
import { withUploadPage } from 'hooks/with-upload-page';
import React from 'react';

function UploadPage(pageProps) {
  const { collection } = pageProps;
  const { Component } = useCsvUpload({ url: `/api/collection/${collection}/import` });
  return Component;
}
export default withUploadPage(UploadPage);
