import { map, multiply } from 'lodash';
import React from 'react';
import { withItemTable } from './hooks';
import dayjs from 'dayjs';

const Td = (props) => {
  return <td className={`border text-xs py-1 ${props.className || ''}`}>{props.children}</td>;
};
const Th = (props) => {
  return <th className={`border py-1 ${props.className || ''}`}>{props.children}</th>;
};

function PrItemTable(props) {
  const { rows } = props;
  return (
    <table className="text-sm w-full border text-center">
      <thead className="border">
        <Th className="w-60">申請表編號</Th>
        <Th className="w-60">開單時間</Th>
        <Th className="w-60">合約</Th>
        <Th className="w-80">名稱</Th>
        <Th className="w-40">代號</Th>
        <Th className="w-20">數量</Th>
        <Th className="w-20">單位</Th>
        <Th className="w-60">供應商</Th>
        <Th className="w-60">貨幣</Th>
        <Th className="w-20">供應商單價</Th>
        <Th className="w-20">小計(HKD)</Th>
      </thead>
      <tbody>
        {rows.map((row) => {
          const { item, supplier, quantity, price, currency, subtotal, prior, pr } = row;
          return (
            <tr className={`border ${prior ? 'text-red-700' : ''}`} key={row._id}>
              <Td>{pr?.code}</Td>
              <Td>{dayjs(pr?.created_at).format('YYYY-MM-DD HH:mm')}</Td>
              <Td>{pr?.contract?.code}</Td>
              <Td>{item?.name}</Td>
              <Td>{item?.code}</Td>
              <Td>{quantity}</Td>
              <Td>{item?.unit}</Td>
              <Td>{supplier?.code}</Td>
              <Td>{currency}</Td>
              <Td>{price}</Td>
              <Td>{subtotal}</Td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default withItemTable(PrItemTable);
