import React from 'react';
import { withPage } from 'hooks/with-page';
import { useTable } from 'components/common/table/hooks';
import { Button } from 'antd';
import { withItemIndexPage } from './hooks';
// import { Button } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

function UserIndexPage(props) {
  const {
    collection,
    showCategoryHandler,
    showIsCategoryOnly,
    setShowIsCategoryOnly,
    showIsItemOnly,
    setShowIsItemOnly
  } = props;
  const paramsPreset = {
    sort: { field: 'code', order: 1 },
    filters: [
      ...(showIsCategoryOnly
        ? [
            {
              field: 'is_category',
              op: 'eq',
              value: showIsCategoryOnly ? '[[true]]' : false
            }
          ]
        : []),
      ...(showIsItemOnly
        ? [
            {
              field: 'is_category',
              op: 'eq',
              value: '[[false]]'
            }
          ]
        : [])
    ]
  };
  const { Component } = useTable({
    collection,
    url: `/api/collection/${collection}/listing`,
    paramsPreset,
    Components: {
      downloadButton: {
        title: `${collection} listing`,
        active: false
      }
    },
    componentProps: {
      NewEntry: {
        url: `/${collection}/new`
      },
      destination: {
        prefix: `/${collection}/profile/`
      }
    }
  });
  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <span className="mr-2">
          <Button
            onClick={() => showCategoryHandler('category')}
            className={showIsCategoryOnly ? 'bg-blue-400 text-white' : ''}
          >
            Category
          </Button>
        </span>
        <Button onClick={() => showCategoryHandler('item')} className={showIsItemOnly ? 'bg-blue-400 text-white' : ''}>
          Item
        </Button>
      </div>
      {Component}
    </div>
  );
}

export default withItemIndexPage(withPage(UserIndexPage));
