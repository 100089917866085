import { TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useInputText } from './hooks';

function InputText({ setInputs, field, value, config }) {
  const {
    placeholder,
    is_required = false,
    editable,
    is_password,
    is_textarea,
    is_money,
    is_positive,
    is_percentage,
    input_variant,
    readonly,
    maxlength
  } = config;
  const ref = useRef();

  const [isFocusing, setIsFocusing] = useState(false);

  const { errorMessage, isTouched, setText, text, showPassword, setShowPassword, textFieldType } = useInputText({
    defaultValue: value,
    config,
    setInputs,
    field
  });

  if (!config) return null;
  return (
    <TextField
      type={textFieldType}
      inputRef={ref}
      onChange={(e) => {
        const v = e.target.value;
        setText(v);
      }}
      fullWidth
      multiline={is_textarea}
      size="small"
      value={text}
      rows={5}
      inputProps={{ maxLength: maxlength, ...(is_positive ? { min: 0 } : {}) }}
      disabled={readonly || !editable}
      onFocus={() => setIsFocusing(true)}
      onBlur={() => setIsFocusing(false)}
      InputProps={{
        endAdornment: is_password ? (
          <InputAdornment position="end">
            {is_required && editable ? <InputAdornment position="start">必填</InputAdornment> : null}
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword((v) => {
                  return !v;
                });
              }}
              onMouseDown={() => {
                setShowPassword((v) => {
                  return !v;
                });
              }}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : is_required && editable ? (
          <InputAdornment position="end">必填</InputAdornment>
        ) : is_money ? (
          <InputAdornment position="end">HKD</InputAdornment>
        ) : is_percentage ? (
          <InputAdornment position="end">%</InputAdornment>
        ) : null
      }}
      error={isTouched.current && !isFocusing ? !!errorMessage : false}
      helperText={isTouched.current && !isFocusing ? errorMessage : null}
      placeholder={placeholder}
      sx={{
        ...(input_variant === 'slim-text'
          ? {
              '.MuiInputBase-input': {
                padding: '3px 7px',
                fontSize: '13px'
              }
            }
          : {})
        //   '.MuiFormHelperText-root': {
        //     display: !touched && !saveBtnClicked ? 'none' : 'inherit'
        //   },
        //   '.Mui-error .MuiOutlinedInput-notchedOutline': {
        //     borderColor: !touched && !saveBtnClicked ? 'rgba(0, 0, 0, 0.23) !important' : '#d32f2f'
        //   }
      }}
    />
  );
}

export default InputText;
