import { useFetch } from 'lib/fetch';
import { debounce } from 'lodash';
import { useEffect } from 'react';

export const withWorksorderNumber = (WrappedComponent) => {
  return (props) => {
    const { body, data, value, setInputs, jobType } = props;
    const field = 'wo_number';

    const isValid = useCheckDuplicatedPr({ body, data, jobType });

    const errorMessage = !isValid ? '此Worksorder Number已有申請' : false;

    useEffect(() => {
      return () => {
        setInputs((v) => ({
          ...v,
          [field]: value
            ? {
                value: '',
                touched: true
              }
            : undefined
        }));
      };
    }, [value]);

    useEffect(() => {
      setInputs((v) => ({
        ...v,
        [field]: { ...v[field], error: errorMessage }
      }));
    }, [errorMessage]);

    return (
      <WrappedComponent {...props} value={value} setInputs={setInputs} field={field} errorMessage={errorMessage} />
    );
  };
};

export const useCheckDuplicatedPr = (props) => {
  const { body, data, jobType } = props;
  const { wo_number } = body;
  const { fetch, result } = useFetch();

  const contract = body?.contract || data?.contract?._id;
  const isWorksorder = jobType === 'worksorder';

  useEffect(() => {
    if (contract && wo_number && isWorksorder) {
      const fn = debounce(() => {
        fetch('GET', `/api/collection/pr/check-deplicated-worksorder-pr/${contract}/${wo_number}`);
      }, 500);

      fn();

      return () => {
        fn.cancel();
      };
    }
  }, [contract, wo_number, isWorksorder]);

  if (wo_number === data?.wo_number || !wo_number || !isWorksorder) {
    return true;
  }

  if (result) {
    return result.isValid;
  }

  return true;
};
