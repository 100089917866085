import React from 'react';
import { parseISODateString } from 'lib/date';
import { compact } from 'lodash';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: 12
  },
  header: {
    // fontSize: 24,
    marginBottom: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  logoImage: {
    height: 50,
    objectFit: 'contain',
    objectPosition: '0'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'start',
    fontSize: 12
  },
  rowTitle: {
    paddingRight: 10
  },
  rowText: {
    borderBottom: '1px solid black',
    width: 140,
    height: 20,
    textAlign: 'center',
    paddingRight: 10
  },
  headerTitle: {
    fontSize: 13,
    textAlign: 'center',
    marginBottom: 14
  }
});

function PrHeader(props) {
  const { data } = props;

  let title_1 = 'PR-A SUMMARY';
  let title_2 = '工程柯打物料訂購表';

  if (data?.form === 'A' && data?.record_status >= 60) {
    title_1 = 'PR-B SUMMARY';
    title_2 = '工程柯打物料訂購表';
  }
  if (data?.form !== 'A' && data?.record_status < 60) {
    title_1 = 'PR-C SUMMARY';
    title_2 = '非工程柯打物料訂購表';
  }

  if (data?.form !== 'A' && data?.record_status >= 60) {
    title_1 = 'PR-D SUMMARY';
    title_2 = '非工程柯打物料訂購表';
  }
  return (
    <>
      <View style={styles.header}>
        <View>
          <Image style={styles.logoImage} src={`/assets/images/logo--dark.png`} />
        </View>
        <View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.rowTitle}>申請表編號:</Text>
            <Text style={styles.rowText}>{data?.code}</Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.rowTitle}>合約編號:</Text>
            <Text style={styles.rowText}>{data?.contract?.code}</Text>
          </View>
        </View>
      </View>
      <View style={styles.headerTitle} wrap={false}>
        <Text style={styles.bold}>
          {title_1} {title_2}
        </Text>
      </View>
    </>
  );
}

export default PrHeader;
