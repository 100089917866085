import React from 'react';
import { Select } from 'antd';
import { isNull } from 'lodash';

function SilmSelect(props) {
  const { options, text, setText, freesolo, readonly } = props;

  const freesoloProps = {
    showSearch: true,
    optionFilterProp: 'children'
  };

  return (
    <Select
      style={{ width: '100%' }}
      value={text || null}
      disabled={readonly}
      optionFilterProp="children"
      onChange={(value) => {
        setText(isNull(value) ? '' : value);
      }}
      {...(freesolo ? freesoloProps : {})}
    >
      {options?.map(({ label, _id }) => (
        <Select.Option value={_id} key={_id}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SilmSelect;
