import { map } from 'lodash';

export const withCsvUploadTable = (WrappedComponent) => {
  return (props) => {
    const { config, data } = props;
    const { rawData } = data;
    const { headers } = config;

    const columns = map(headers, (col) => ({
      field: col,
      headerName: col
    }));

    const rows = map(rawData, (row, id) => ({
      ...row,
      id
    }));

    return <WrappedComponent {...props} columns={columns} rows={rows} />;
  };
};
