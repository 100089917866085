import React from 'react';
import { parseISODateString } from 'lib/date';
import { compact } from 'lodash';
import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';
import { isPriceFormat } from 'lib/price';

// const styles = StyleSheet.create({
// marginTop12: {
//   marginTop: 12
// },
// textUppercase: {
//   textTransform: 'uppercase'
// },
// table: {
//   // width: 550,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
//   alignContent: 'stretch',
//   flexWrap: 'nowrap',
//   alignItems: 'stretch',
//   // borderBottom: '1px solid #000',
//   fontSize: 10
// },
// tableRow: {
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'space-around',
//   alignContent: 'stretch',
//   flexWrap: 'nowrap',
//   alignItems: 'stretch',
//   flexGrow: 0,
//   flexShrink: 0,
//   borderTop: '1px solid #000',
//   verticalAlign: 'bottom',
//   alignItems: 'center',
//   justifyContent: 'center'
// },
// tableHeader: {
//   textAlign: 'center'
// },
// cell: {
//   borderRight: '1px solid #000',
//   flexGrow: 2,
//   flexShrink: 1,
//   flexBasis: 100,
//   alignSelf: 'stretch',
//   paddingVertical: 1,
//   paddingHorizontal: 2
// },
// cell1: {
//   borderLeft: '1px solid #000',
//   flexBasis: '5%'
//   // textAlign: 'right'
// },
// cell2: {
//   flexBasis: '27%'
//   // textAlign: 'right'
// },
// cell3: {
//   flexBasis: '20%'
//   // textAlign: 'right'
// },
// cell4: {
//   flexBasis: '19%',
//   textAlign: 'right'
// },
// cell5: {
//   flexBasis: '10%',
//   textAlign: 'right'
// },
// cell6: {
//   flexBasis: '19%',
//   textAlign: 'right'
// },
// totalAmountRow: {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'nowrap',
//   borderTop: '1px solid #000',
//   fontSize: 10
// },
// totalAmountCell1: {
//   flexBasis: '81%',
//   textAlign: 'right',
//   paddingVertical: 1,
//   paddingHorizontal: 2
// },
// totalAmountCell2: {
//   flexBasis: '19%',
//   textAlign: 'right',
//   borderRight: '1px solid #fff',
//   paddingVertical: 1,
//   // paddingHorizontal: 2,
//   borderBottom: '1px solid #000'
// },
// cellUnerline: {
//   borderBottom: '1px solid #000'
// },
// totalAmountCell2Text: {
//   paddingHorizontal: 2
// },
// content: {
//   marginTop: 10,
//   marginBottom: 20,
//   fontSize: 10
// },
// signatureView: {
//   display: 'flex',
//   width: '100%',
//   flexDirection: 'row',
//   justifyContent: 'space-around',
//   // justifyContent: 'space-around',
//   // alignItems: 'flex-start',
//   flexWrap: 'nowrap',
//   fontSize: 12
// },
// signature: {
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'flex-end',
//   marginTop: 20,
//   width: 250
// },
// signatureTitle: {
//   paddingRight: 10
// },
// signatureImage: {
//   width: '50%',
//   objectFit: 'contain',
//   marginHorizontal: '1%',
//   objectPosition: '100%'
// },
// signatureBox: {
//   width: '30%',
//   marginTop: 10
// },
// signatureBoxTable: {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
//   alignContent: 'stretch',
//   flexWrap: 'nowrap',
//   alignItems: 'stretch',
//   // borderBottom: '1px solid #000',
//   fontSize: 10
// },
// signatureBoxRow: {
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'space-around',
//   alignContent: 'stretch',
//   flexWrap: 'nowrap',
//   alignItems: 'stretch',
//   flexGrow: 0,
//   flexShrink: 0,
//   // borderTop: '1px solid #000',
//   verticalAlign: 'bottom',
//   alignItems: 'center',
//   justifyContent: 'center'
// },
// signatureBoxCell: {
//   // borderRight: '1px solid #000',
//   flexGrow: 2,
//   flexShrink: 1,
//   flexBasis: 100,
//   alignSelf: 'stretch',
//   paddingVertical: 1,
//   paddingHorizontal: 2
// },
// signatureBoxCol1: {
//   // borderLeft: '1px solid #000'
//   // borderTop: '1px solid #000'
// },
// signatureBoxEmpty: {
//   height: 60
// },
// signatureBoxDate: {
//   // paddingTop: 60
//   borderTop: '1px solid #000'
// },
// signatureBoxRemark: {
//   fontSize: 10,
//   // flex: 1,
//   border: '1px solid #000',
//   padding: 5,
//   marginTop: 'auto'
// }
// });

function convertNumberToWords(amount) {
  var words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  var atemp = amount.split('.');
  var number = atemp[0].split(',').join('');
  var n_length = number.length;
  var words_string = '';
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    var value = '';
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + ' ';
      }
      if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
        words_string += 'Crores ';
      }
      if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
        words_string += 'Lakhs ';
      }
      if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
        words_string += 'Thousand ';
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += 'Hundred and ';
      } else if (i == 6 && value != 0) {
        words_string += 'Hundred ';
      }
    }
    words_string = words_string.split('  ').join(' ');
  }
  return words_string;
}

function withDecimal(n) {
  var nums = n.toString().split('.');
  var whole = convertNumberToWords(nums[0]);
  if (nums.length == 2) {
    var fraction = convertNumberToWords(nums[1]);
    if (nums[1].length === 1) {
      fraction = convertNumberToWords(nums[1] + '0');
    }
    return whole + 'and ' + fraction + 'cents ';
  } else {
    return whole;
  }
}

function PoSample(props) {
  const { data, data_item, pr_number, styles } = props;

  let workorderNumbers = [];
  let totalAmount = 0;
  let itemCurrency;
  data_item?.map((item, index) => {
    if (item?.pr__item?.pr?.wo_number) {
      workorderNumbers.push(item?.pr__item?.pr?.wo_number);
    }
    if (item?.pr__item?.price) {
      totalAmount += item?.pr__item?.price * item?.pr__item?.quantity;
    }
    itemCurrency = item?.pr__item?.currency;
  });

  return (
    <>
      <View style={[styles.table, styles.paragraph, styles.marginTop12]}>
        <View fixed>
          <View>
            <Text style={[styles.textRight]}>Currency: {itemCurrency}</Text>
          </View>
          <View style={[styles.tableRow]} wrap={false}>
            <Text style={[styles.cell, styles.cell1, styles.tableHeader]}>NO.</Text>
            <Text style={[styles.cell, styles.cell2, styles.tableHeader]}>ITEM CODE / NAME</Text>
            <Text style={[styles.cell, styles.cell3, styles.tableHeader, styles.textCenter]}>UNIT PRICE</Text>
            <Text style={[styles.cell, styles.cell4, styles.tableHeader, styles.textCenter]}>QTY</Text>
            <Text style={[styles.cell, styles.cell5, styles.tableHeader, styles.textCenter]}>AMOUNT</Text>
          </View>
        </View>

        {data_item?.map((d, index) => {
          return (
            <View style={[styles.tableRow]} wrap={false} key={index}>
              <Text style={[styles.cell, styles.cell1]}>{index + 1}</Text>
              <View style={[styles.cell, styles.cell2]}>
                <Text>{d?.pr__item?.item?.code}</Text>
                <Text>{d?.pr__item?.item?.name}</Text>
                {pr_number && pr_number.length > 1 && <Text>({d?.pr__item?.pr?.code})</Text>}
              </View>

              <Text style={[styles.cell, styles.cell3]}>${isPriceFormat(d?.pr__item?.price)}</Text>
              <Text style={[styles.cell, styles.cell4]}>{d?.pr__item?.quantity}</Text>
              <Text style={[styles.cell, styles.cell5]}>
                ${isPriceFormat(d?.pr__item?.price * d?.pr__item?.quantity)}
              </Text>
            </View>
          );
        })}

        <View style={[styles.totalAmountRow]} wrap={false}>
          <Text style={[styles.totalAmountCell1]}>TOTAL AMOUNT:</Text>
          <View style={[styles.totalAmountCell2]}>
            <View style={[styles.cellUnerline]}>
              <Text style={[styles.totalAmountCell2Text]}>${isPriceFormat(totalAmount)}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={[styles.content]} wrap={false}>
        <Text style={[styles.textUppercase]}>SAY TOTAL (HKD) {withDecimal(parseFloat(totalAmount))}DOLLARS ONLY</Text>
        <Text>PAYMENT TERMS : {data?.supplier?.payment_terms}</Text>
        <Text>DELIVERY TO : {data?.delivery_address}</Text>
        <Text>
          CONTACT PERSON : {data?.delivery_contact_name} TEL: {data?.delivery_contact_phone}
        </Text>
        <Text>WORK ORDER# : {workorderNumbers.join(', ')}</Text>
        <Text>REMARKS : {data?.supplier?.remark}</Text>
      </View>
      <View style={[styles.signatureView]}>
        <View style={styles.signatureBox} wrap={false}>
          <View style={styles.signatureBoxTable}>
            <View style={[styles.signatureBoxRow]}>
              <Text style={[styles.signatureBoxCell, styles.signatureBoxCol1]}>Confirmed And Accepted By Seller </Text>
            </View>
            <View style={styles.signatureBoxRow} wrap={false}>
              <View style={[styles.signatureBoxCell, styles.signatureBoxCol1, styles.signatureBoxEmpty]}></View>
            </View>
            <View style={styles.signatureBoxRow} wrap={false}>
              <View style={[styles.signatureBoxCell, styles.signatureBoxCol1]}>
                <Text style={styles.signatureBoxDate}>Signed with company chop</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.signatureBox} wrap={false}>
          <View style={styles.signatureBoxRemark}>
            <Text>請各供應商注意:</Text>
            <Text>貴司之送貨單及發票上, 必須列明我司之柯打編號. 並請把綠色P.O. 簽署及蓋章後 寄回本司採購部收</Text>
          </View>
        </View>
        <View style={styles.signatureBox} wrap={false}>
          <View style={styles.signatureBoxTable}>
            <View style={[styles.signatureBoxRow]}>
              <Text style={[styles.signatureBoxCell, styles.signatureBoxCol1]}>
                For and on behalf of Welcome Construction Co., Ltd.
              </Text>
            </View>
            <View style={styles.signatureBoxRow} wrap={false}>
              <View style={[styles.signatureBoxCell, styles.signatureBoxCol1, styles.signatureBoxEmpty]}></View>
            </View>
            <View style={styles.signatureBoxRow} wrap={false}>
              <View style={[styles.signatureBoxCell, styles.signatureBoxCol1]}>
                <Text style={styles.signatureBoxDate}>Purchase Manager / Director</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default PoSample;
