import React from 'react';
import { useEmailTemplateVariables, withEmailTemplateIndexPage } from './hooks';
import { Divider } from '@mui/material';

import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';
import { MenuItem, Select } from '@mui/material';
import { withPage } from 'hooks/with-page';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Attachments from './components/attachments';
import Emails from './components/emails';

function EmailTemplateIndexPage(props) {
  const { data, schema, setInputs, template, setTemplate, options } = props;
  const { variables } = useEmailTemplateVariables();

  const inputEditProps = {
    mode: 'edit',
    schema,
    setInputs
  };

  return (
    <div className="flex flex-col gap-4" key={template}>
      <Section title="Email Template">
        <div className="text-sm mb-1 ml-1">
          <label>模板</label>
        </div>
        <Select value={template} onChange={(e) => setTemplate(e.target.value)} size="small">
          {options?.map(({ _id, label }) => (
            <MenuItem value={_id} key={_id}>
              {label}
            </MenuItem>
          ))}
        </Select>

        <div className="my-3">
          <Divider />
        </div>

        <div className="flex flex-col w-full gap-4">
          {/* <InputEdit {...inputEditProps} field="title" value={data?.title} className="w-full" /> */}

          <div className="flex flex-col">
            <div className="text-sm mb-1 ml-1">
              <label>Variable</label>
            </div>
            <div className="flex flex-col border border-gray-300 rounded">
              {variables.map((variable) => (
                <CopyToClipboard text={`{${variable}}`}>
                  <div className="p-2 border-b border-gray-300 flex cursor-pointer hover:underline" key={variable}>
                    <div className="grow">{variable}</div>

                    <div className="text-blue-500 text-xs ">Copy</div>
                  </div>
                </CopyToClipboard>
              ))}
            </div>
          </div>
          <div className="my-3">
            <Divider />
          </div>
          <InputEdit {...inputEditProps} field="subject" value={data?.subject} className="w-full" />
          <InputEdit {...inputEditProps} field="content" value={data?.content} className="w-full" />
          <Emails {...inputEditProps} field="from" value={data?.from} className="w-full" />
          <Emails {...inputEditProps} field="to" value={data?.to} className="w-full" />
          <Emails {...inputEditProps} field="cc" value={data?.cc} className="w-full" />
          <Emails {...inputEditProps} field="bcc" value={data?.bcc} className="w-full" />

          <Attachments {...inputEditProps} field="attachments" value={data?.attachments} className="w-full" />
        </div>
      </Section>
    </div>
  );
}

export default withEmailTemplateIndexPage(withPage(EmailTemplateIndexPage));
