import InputEdit from 'components/common/input-edit';
import React from 'react';
import { withQuantity } from './hooks';

function Quantity(props) {
  const { data, setInputs, readonly } = props;
  return (
    <InputEdit
      field="quantity"
      value={data || ''}
      className="flex w-full"
      setInputs={setInputs}
      schema={{ type: 'number', is_positive: true, input_variant: 'slim-text', readonly }}
    />
  );
}

export default withQuantity(Quantity);
