import { usePrSubmitQuoteDone } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withSubmitQuoteDone = (WrappedComponent) => {
  return (props) => {
    const { data = {}, userProfile, disabled } = props;
    const { record_status } = data || {};
    const [open, setOpen] = useState(false);
    const currentUser = useAtomValue(user);
    const { onSubmit, isLoading, result } = usePrSubmitQuoteDone({ _id: data?._id });

    const showVerifyButton = useMemo(() => {
      const { department } = userProfile || {};
      if (department === 'PD' && record_status === 20) {
        return true;
      }
      return false;
    }, [userProfile, record_status]);

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    useEffect(() => {
      if (result) {
        redirect(`/pr/profile/${data._id}`);
      }
    }, [result, data?._id]);

    if (!showVerifyButton) return null;
    return (
      <WrappedComponent
        {...props}
        open={open}
        onSubmit={onSubmit}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        disabled={disabled}
        submitted={isLoading || result}
      />
    );
  };
};
