import React from 'react';
import { useAtom } from 'jotai';
import { ContentCopy, Print, Save } from '@mui/icons-material';
import { Button } from '@mui/material';

import { topnav } from './hooks';

function TopNav() {
  const [{ copy, title, print, save, canSave, _id, element }] = useAtom(topnav);
  return (
    <div className="h-16 flex items-center text-zinc-900 mx-4 gap-2">
      <div className="text-lg capitalize">{title}</div>
      <div className="grow"></div>
      <div className="">
        {element || null}
        {print && (
          <Button onClick={print} endIcon={<Print />} variant="outlined">
            列印
          </Button>
        )}
      </div>
      <div className="">
        {copy && (
          <Button onClick={copy} endIcon={<ContentCopy />} variant="outlined">
            複製
          </Button>
        )}
      </div>
      <div className="">
        {save && (
          <Button onClick={save} disabled={!canSave} endIcon={<Save />} variant="contained">
            儲存
          </Button>
        )}
      </div>
    </div>
  );
}

export default TopNav;
