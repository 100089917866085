import { useFetch } from 'lib/fetch';
import { useCallback, useEffect, useMemo } from 'react';

export const useCollectionFieldDistinct = (config, setting) => {
  const { collection, field, optionsUrl } = config || {};
  const { fetchOnInit = true } = setting || {};
  const { fetch, result } = useFetch();

  const url = useMemo(() => {
    if (optionsUrl) return optionsUrl;
    if (!collection || !field) return;
    return `/api/collection/${collection}/field/${field}`;
  }, [collection, field, optionsUrl]);

  const fetchOptions = useCallback(() => {
    fetch('GET', url);
  }, [url]);

  useEffect(() => {
    if (fetchOnInit && url) {
      fetchOptions();
    }
  }, []);

  return {
    fetchOptions,
    data: result
  };
};
