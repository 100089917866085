import React from 'react';

function Tab({ children, hidden }) {
  return (
    <div role="tabpanel" hidden={hidden}>
      {children}
    </div>
  );
}

export default Tab;
