import { Tab } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';

export const withTabs = (WrappedComponent) => {
  return (props) => {
    const { children, setTabs, value } = props;

    const Headers = useMemo(() => {
      if (!children || isEmpty(children) || children?.props) return null;
      return children.map((child, index) => {
        const { title, id, ...rest } = child?.props || {};
        if (!title) return null;
        return <Tab key={index} label={title} id={id || index} {...rest} />;
      });
    }, [children]);

    useEffect(() => {
      if (!children || isEmpty(children) || children?.props) return;
      setTabs(children.map((child, index) => child?.props?.id || index));
    }, []);

    const customChildren = useMemo(() => {
      if (!children || isEmpty(children)) return null;
      if (children?.props) {
        return children;
      }
      return children
        .map((child, index) => {
          if (!child) return null;
          return {
            ...child,
            props: {
              ...child?.props,
              hidden: value !== index
            }
          };
        })
        .filter((child) => child);
    }, [children, value]);

    return (
      <WrappedComponent {...props} Headers={Headers}>
        {customChildren}
      </WrappedComponent>
    );
  };
};
