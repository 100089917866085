import React, { useState } from 'react';
import { useInputSelect } from './hooks';
import { Autocomplete, TextField } from '@mui/material';
import { filter, find, get, isObject, isString } from 'lodash';
import SilmSelect from './variant/slim';
import CustomAutocomplete from './variant/autocomplete';

function InputSelect({ field, value, setInputs, config }) {
  const [isFocusing, setIsFocusing] = useState(false);
  const { isTouched, text, setText, options, groupBy, errorMessage } = useInputSelect({
    defaultValue: value,
    config,
    setInputs,
    field
  });

  const { readonly, freesolo = false, input_variant } = config;

  const props = { errorMessage, isFocusing, isTouched, groupBy, freesolo, text, options, setText, readonly };

  switch (input_variant) {
    case 'slim-select':
      return <SilmSelect {...props} />;
    default:
      return <CustomAutocomplete {...props} />;
  }
}

export default InputSelect;
