import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

function BooleanToggleButton(props) {
  const { isOn, setIsOn, readonly } = props;

  return (
    <ToggleButtonGroup
      value={isOn}
      exclusive
      size="small"
      onChange={(e, v) => {
        if (readonly) return;
        setIsOn(v);
      }}
    >
      <ToggleButton
        value={true}
        sx={{
          '&.Mui-selected,&:hover': {
            bgcolor: '#dcfce7'
          },
          '&.Mui-selected:hover': {
            bgcolor: '#bbf7d0'
          }
        }}
      >
        <Check />
      </ToggleButton>
      <ToggleButton
        value={false}
        sx={{
          '&.Mui-selected,&:hover': {
            bgcolor: '#fee2e2'
          },
          '&.Mui-selected:hover': {
            bgcolor: '#fecaca'
          }
        }}
      >
        <Close />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default BooleanToggleButton;
