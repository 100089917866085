import { useFetch } from 'lib/fetch';
import { redirect } from 'lib/location';
import { useCallback, useEffect, useState } from 'react';

export const withApprovePopup = (WrappedComponent) => {
  return (props) => {
    const { _id, tiers, handlePopupClose } = props;
    const [selectedTier, setSelectedTier] = useState('');
    const [approve, setApprove] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [attachments, setAttachments] = useState([]);

    const { fetch, isLoading, result } = useFetch();

    const onSubmit = useCallback(() => {
      if (selectedTier) {
        fetch('POST', '/api/collection/pr-approval/new', {
          params: {
            is_approve: approve,
            remarks,
            attachments,
            tier: selectedTier,
            pr: _id
          }
        });
      }
    }, [_id, approve, remarks, selectedTier, attachments]);

    useEffect(() => {
      if (result) {
        redirect(`/pr/profile/${_id}#approval-status`, { reload: true });
      }
    }, [result, _id]);

    useEffect(() => {
      if (!selectedTier) {
        setSelectedTier(tiers?.[0]?.tier);
      }
    }, [selectedTier, tiers]);

    const onClose = () => {
      setApprove(null);
      setRemarks(null);
      setSelectedTier('');
      handlePopupClose();
    };

    const _props = {
      ...props,
      selectedTier,
      setSelectedTier,
      setAttachments,
      approve,
      setApprove,
      remarks,
      setRemarks,
      onSubmit,
      submitted: isLoading || result,
      onClose
    };

    return <WrappedComponent {..._props} />;
  };
};
