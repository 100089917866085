import To from 'components/common/to';
import React from 'react';

function Notfound() {
  return (
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center mb-12">
        <h1 class="text-xl tracking-tight text-gray-600 sm:text-5xl">此頁面不存在 或 您沒有訪問權限</h1>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            返回主頁
          </a>
        </div>
      </div>
    </main>
  );
}

export default Notfound;
