import { reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const withReference = (WrappedComponent) => {
  return (props) => {
    const { setInputs, data } = props;
    const { _id } = data || {};
    const field = `subcontractor__references.${_id}`;
    const [files, setFiles] = useState({
      [field]: []
    });

    const schema = useMemo(() => {
      const field_is_approve = data?.subcontractor?.is_approve ? '*' : '';
      return {
        ...props.schema,
        title: `${data?.subcontractor?.code}${field_is_approve}`
      };
    }, [props.schema, data]);

    useEffect(() => {
      const { [field]: updates, ...rest } = files;
      setInputs((v) => {
        return {
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!(key.startsWith(`$push.${field}`) || key.startsWith(`$pull.${field}`))) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          ),
          ...reduce(
            updates?.value || [],
            (prev, curr, index) => {
              prev[`$push.${field}[${index}]`] = {
                value: curr,
                touched: true
              };
              return prev;
            },
            {}
          ),
          ...rest
        };
      });
    }, [files, field, _id]);

    return <WrappedComponent {...props} schema={schema} setInputs={setFiles} field={field} value={data?.references} />;
  };
};
