import To from 'components/common/to';
import JsFileDownloader from 'js-file-downloader';
import { apikey, host } from 'lib/config';
import { startsWith } from 'lodash';
import React from 'react';

function InputFileFilename({ value }) {
  const { filename, mimetype } = value || {};
  const url = `${host}/api/collection/file/file/${filename}`;

  if (!filename) return null;
  return (
    <div className="flex ml-2 gap-1">
      <div className="break-all">{filename}</div>
      {startsWith(mimetype, 'image') ? (
        <To className="default-link shrink-0" plainHref={url} target="_blank">
          [預覽]
        </To>
      ) : (
        <div
          className="text-[#0c66e4] cursor-pointer hover:underline shrink-0"
          onClick={() => {
            new JsFileDownloader({
              url,
              filename,
              withCredentials: true,
              headers: [{ name: 'apikey', value: apikey }]
            });
          }}
        >
          [下載]
        </div>
      )}
    </div>
  );
}

export default InputFileFilename;
