import { find } from 'lodash';
import { useMemo } from 'react';
import { useCollectionFieldDistinct } from './use-collection-field-distinct';

export const withCollectionFieldDistinct = (WrappedComponent, setting) => {
  const { collection, fetchOnInit = true, optionsUrl } = setting || {};
  return (props) => {
    const { field, schema } = props;
    const { data, fetchOptions } = useCollectionFieldDistinct({ collection, field, optionsUrl }, { fetchOnInit });

    const fieldSchema = useMemo(() => {
      const existing = find(schema, { field }) || {};
      return {
        ...existing,
        selectOptions: data || [],
        fetchOptions
      };
    }, [field, schema, data]);

    return <WrappedComponent {...props} schema={fieldSchema} />;
  };
};
