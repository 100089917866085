import React from 'react';
import { withSubtotal } from './hooks';
import { isPriceFormat } from 'lib/price';

function Subtotal(props) {
  const { subtotal } = props;
  if (!subtotal) {
    return null;
  }
  return <div className="">{isPriceFormat(subtotal)}</div>;
}

export default withSubtotal(Subtotal);
