import React, { useContext } from 'react';
import { isUndefined } from 'lodash';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import To from 'components/common/to';

function NewEntry({ context }) {
  const { config } = useContext(context);
  const { componentProps, collection } = config || {};

  if (config?.Components?.NewEntry?.active === false) return null;

  if (!isUndefined(config?.Components?.NewEntry)) {
    return config?.Components?.NewEntry ? <config.Components.NewEntry {...componentProps?.NewEntry} /> : null;
  }

  return (
    <To href={componentProps?.NewEntry?.url || `/${collection}/new`}>
      <Button variant="contained" startIcon={<Add />}>
        新增紀錄
      </Button>
    </To>
  );
}

export default NewEntry;
