import React from 'react';
import { withSubcontractors } from './hooks';
import InputFileFilename from 'components/common/input-edit/file/filename';
import { parseISODateString } from 'lib/date';

function Subcontractors(props) {
  const { rows } = props;
  return (
    <div className="flex flex-col gap-2 mt-4">
      <div className="">
        <div className="text-sm text-zinc-600">訂購 (扣數)</div>
      </div>
      <div className="flex flex-col gap-2">
        {rows.map((record, index) => (
          <div className="px-4 py-2 rounded-md border border-zinc-200 flex gap-2 items-center text-xs" key={index}>
            <div className="w-40">{record?.subcontractor?.chin_name}</div>
            <div className="border h-4" />
            <div className="w-12 text-center">{record?.percentage}%</div>
            <div className="border h-4" />
            <div className="grow">
              {record?.references
                ? record?.references.map((reference, index) => (
                    <div className="flex gap-2 items-center" key={index}>
                      <div className="text-zinc-500">{index + 1}</div>
                      <div className="grow">
                        <InputFileFilename key={reference?._id} value={reference} />
                      </div>
                      <div className="text-zinc-700">
                        {reference?.updated_by?.nickname || reference?.created_by?.nickname}
                      </div>
                      <div className="text-zinc-700">
                        {parseISODateString(reference?.updated_at || reference?.created_at)}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withSubcontractors(Subcontractors);
