import React, { useState } from 'react';
import { withApprovalTimeline } from './hooks';
import { parseISODateString } from 'lib/date';
import { AccessTime, Close, Done } from '@mui/icons-material';
import { List } from 'antd';
const ApprovalTimeline = (props) => {
  const { history } = props;

  return (
    <List
      itemLayout="horizontal"
      dataSource={history}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={item?.active ? item?.is_approve || item?.skip ? <Done /> : <Close /> : <AccessTime />}
            title={item.tier}
            description={
              item?.active &&
              !item?.skip && (
                <div className="text-xs">
                  <div className="">由 {item?.user?.nickname}</div>
                  <div>於 {parseISODateString(item?.created_at)} 執行</div>
                </div>
              )
            }
          />
        </List.Item>
      )}
    />
  );
};
export default withApprovalTimeline(ApprovalTimeline);
