import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { withAmendmentButton } from './hooks';

function AmendmentButton(props) {
  const { className, open, onSubmit, handleDialogClose, handleDialogOpen, submitted } = props;
  return (
    <div className={`${className} flex justify-end items-center w-full`}>
      <Button variant="contained" color="info" endIcon={<Edit />} onClick={handleDialogOpen}>
        修正申請
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        sx={{
          '.MuiDialog-paper': {
            width: 500
          }
        }}
      >
        <DialogTitle>你確定要修正申請嗎?</DialogTitle>
        <DialogContent>
          <DialogContentText>一旦提交後將無法修改</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>取消</Button>
          <Button onClick={onSubmit} variant="contained" autoFocus disabled={submitted}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAmendmentButton(AmendmentButton);
