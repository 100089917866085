import React from 'react';
import { isPriceFormat } from 'lib/price';

function Money({ value }) {
  if (!value) return '-';
  return (
    <p className="flex justify-end pr-2">
      <span>$</span>
      <span>{isPriceFormat(value)}</span>
    </p>
  );
}

export default Money;
