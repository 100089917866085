import { useCallback, useState } from 'react';
import { saveAs } from 'file-saver';
import { Parser } from '@json2csv/plainjs';
import CsvDownloader from './index';

export const useCsvDownloader = (props) => {
  const { filename, data, url, componentProps } = props;

  const downloadCsvData = useCallback(() => {
    try {
      if (!!new Blob()) {
        const parser = new Parser({});
        const file = parser.parse(data);

        var blob = new Blob([file], { type: 'text/csv;charset=utf-8' });

        saveAs(blob, `${filename}.csv`);
      }
    } catch (e) {
      console.error(e);
    }
  }, [data, filename]);

  return {
    downloadCsvData,
    Component: <CsvDownloader download={downloadCsvData} {...componentProps} />
  };
};
