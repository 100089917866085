import React from 'react';
import { Route } from 'react-router-dom';

import IndexPage from 'pages/log/index';
import ProfilePage from 'pages/log/profile';

const collection = 'log';
const menuItem = ['SETTING', 'LOG'];

const routes = [
  <Route path={`/${collection}`} element={<IndexPage menuItem={menuItem} title="系統紀錄" />} />,
  <Route
    path={`/${collection}/profile/:_id`}
    element={<ProfilePage menuItem={menuItem} title="系統紀錄" collection={collection} editMode="edit" />}
  />
];

export default routes;
