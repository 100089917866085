import React from 'react';
import { useApprovalHistoryData, useApprovalUserData, useProfile } from './hooks';
import { withProfilePage } from 'hooks/with-profile-page';
import { useTabs } from 'components/common/tabs/hooks';
import Metadata from 'components/common/metadata';
import General from './tabs/general';
import PRItems from './tabs/pr__items';
import ContractUser from './tabs/contract-user';
import Section from 'components/common/section';
import ApprovalStatus from './tabs/approval-status';
import ApprovePopup from './components/approve-popup';
import ApproveButton from './components/approve-button';
import QuoteReferences from './tabs/quote-references';
import SubcontractorReferences from './tabs/subcontractor-references';

function PrProfilePage(pageProps) {
  const { Component, tabProps } = useTabs();
  const { Tabs, Tab } = Component;

  const {
    _id,
    data,
    body,
    metadata,
    schema,
    setInputs,
    userProfile,
    approveButtonState,
    historyData,
    contractUserData,
    prUserData,
    EntityLogs
  } = useProfile({
    pageProps,
    currentTab: tabProps.value
  });

  const { isEditMode } = pageProps;

  return (
    <div className="flex flex-col gap-4">
      {isEditMode && <Metadata metadata={metadata} />}
      <ApproveButton
        data={data}
        historyData={historyData}
        contractUserData={contractUserData}
        _id={_id}
        approveButtonState={approveButtonState}
        prUserData={prUserData}
      />
      <Tabs {...tabProps}>
        <Tab title="General" id="info">
          <General
            schema={schema}
            setInputs={setInputs}
            data={data}
            body={body}
            isEditMode={isEditMode}
            userProfile={userProfile}
            contractUserData={contractUserData}
          />
        </Tab>
        <Tab title="用戶" id="users">
          <ContractUser
            contract={data?.contract?._id || body?.contract}
            setInputs={setInputs}
            data={data?.pr__users}
            isEditMode={true}
            disabled={data?.record_status > 10 && isEditMode}
          />
        </Tab>
        <Tab title="物料" id="items">
          <PRItems schema={schema} setInputs={setInputs} data={data} userProfile={userProfile} body={body} />
        </Tab>
        <Tab title="報價參考" id="quote-references" disabled={data?.record_status < 20 || !isEditMode}>
          <QuoteReferences schema={schema} setInputs={setInputs} data={data} userProfile={userProfile} />
        </Tab>
        <Tab title="訂購(扣數)文件" id="subcontractor-references" disabled={data?.record_status < 40 || !isEditMode}>
          <SubcontractorReferences schema={schema} setInputs={setInputs} data={data} userProfile={userProfile} />
        </Tab>
        <Tab title="批核狀態" id="approval-status" disabled={data?.record_status < 40 || !isEditMode}>
          <ApprovalStatus
            _id={_id}
            historyData={historyData}
            contractUserData={contractUserData}
            data={data}
            prUserData={prUserData}
          />
        </Tab>
        {/* {isEditMode ? (
          <Tab title="Logs" id="logs">
            <Section title="Logs">{EntityLogs}</Section>
          </Tab>
        ) : null} */}
      </Tabs>
    </div>
  );
}

export default withProfilePage(PrProfilePage);
