import React from 'react';
import PoHeader from './components/po-header';
import PoSample from './components/po-sample';
import { withPrintForm } from './hooks';

import { Page, Font, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Image } from '@react-pdf/renderer';
import NotoSansTC from '../../../fonts/NotoSansTC-Regular.otf';
import NotoSansTCBold from '../../../fonts/NotoSansTC-Bold.otf';
import { ContentCopy, Print, Save } from '@mui/icons-material';
import { Button } from '@mui/material';
import { blue, red } from '@mui/material/colors';

Font.register({
  family: 'Noto Sans TC',
  src: NotoSansTC
});
Font.register({
  family: 'Noto Sans TC Bold',
  src: NotoSansTCBold
});
Font.registerHyphenationCallback((word) => {
  return [word];
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Noto Sans TC',
    // paddingTop: 35,
    paddingBottom: 65,
    fontSize: 12,
    width: '100%'
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
    // opacity: 0.5
  },
  container: {
    marginHorizontal: 35
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 70,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  headerFix: {
    paddingTop: 90,
    marginHorizontal: 35
  },
  paragraph: {
    marginBottom: 12
  },
  fontBold: {
    fontFamily: 'Noto Sans TC Bold'
  },
  textCenter: {
    textAlign: 'center'
  },
  textRight: {
    textAlign: 'right'
  },
  header: {
    // fontSize: 24,
    marginBottom: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  logoImage: {
    height: 50,
    objectFit: 'contain',
    objectPosition: '0'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'start',
    fontSize: 12
  },
  rowTitle: {
    paddingRight: 10
  },
  rowText: {
    borderBottom: '1px solid black',
    width: 140,
    height: 20,
    textAlign: 'center',
    paddingRight: 10
  },
  headerTitle: {
    fontSize: 13,
    textAlign: 'center',
    marginBottom: 14
  },
  infoLeftRight: {
    // flex: 1,
    flexDirection: 'row',
    fontSize: 10
  },
  infoLeft: {
    padding: 5,
    flexBasis: '60%'
  },
  infoRight: {
    padding: 5,
    flexBasis: '40%'
  },
  infoTable: {
    // width: 550,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  infoTableRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  infoCell: {
    flexGrow: 2,
    flexShrink: 1,
    // flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  infoCell1: {
    flexBasis: '40%'
    // textAlign: 'right'
  },
  infoCell2: {
    flexBasis: '60%'
    // borderBottom: '1px solid #000'
    // textAlign: 'right'
  },
  marginTop12: {
    marginTop: 12
  },
  textUppercase: {
    textTransform: 'uppercase'
  },
  table: {
    // width: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    // borderBottom: '1px solid #000',
    fontSize: 10
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    borderBottom: '1px solid #000',
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableHeader: {
    // textAlign: 'center',
    borderTop: '1px solid #000'
  },
  cell: {
    borderRight: '1px solid #000',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  cell1: {
    borderLeft: '1px solid #000',
    flexBasis: '5%'
    // textAlign: 'right'
  },
  cell2: {
    flexBasis: '49%'
    // textAlign: 'right'
  },
  cell3: {
    flexBasis: '18%',
    textAlign: 'right'
  },
  cell4: {
    flexBasis: '10%',
    textAlign: 'center'
  },
  cell5: {
    flexBasis: '18%',
    textAlign: 'right'
  },
  cell6: {
    flexBasis: '19%',
    textAlign: 'right'
  },
  totalAmountRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    // borderTop: '1px solid #000',
    fontSize: 10
  },
  totalAmountCell1: {
    flexBasis: '82%',
    textAlign: 'right',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  totalAmountCell2: {
    flexBasis: '18%',
    textAlign: 'right',
    borderRight: '1px solid #fff',
    paddingVertical: 1,
    // paddingHorizontal: 2,
    borderBottom: '1px solid #000'
  },
  cellUnerline: {
    borderBottom: '1px solid #000'
  },
  totalAmountCell2Text: {
    paddingHorizontal: 2
  },
  content: {
    marginTop: 10,
    marginBottom: 20,
    fontSize: 10
  },
  signatureView: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    // justifyContent: 'space-around',
    // alignItems: 'flex-start',
    flexWrap: 'nowrap',
    fontSize: 12
  },
  signature: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    width: 250
  },
  signatureTitle: {
    paddingRight: 10
  },
  signatureImage: {
    width: '50%',
    objectFit: 'contain',
    marginHorizontal: '1%',
    objectPosition: '100%'
  },
  signatureBox: {
    width: '30%',
    marginTop: 10
  },
  signatureBoxTable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    // borderBottom: '1px solid #000',
    fontSize: 10
  },
  signatureBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    // borderTop: '1px solid #000',
    verticalAlign: 'bottom',
    alignItems: 'center',
    justifyContent: 'center'
  },
  signatureBoxCell: {
    // borderRight: '1px solid #000',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    paddingVertical: 1,
    paddingHorizontal: 2
  },
  signatureBoxCol1: {
    // borderLeft: '1px solid #000'
    // borderTop: '1px solid #000'
  },
  signatureBoxEmpty: {
    height: 60
  },
  signatureBoxDate: {
    // paddingTop: 60
    borderTop: '1px solid #000'
  },
  signatureBoxRemark: {
    fontSize: 10,
    // flex: 1,
    border: '1px solid #000',
    padding: 5,
    marginTop: 'auto'
  }
});

function PoPrintForm(props) {
  // for test po pdf layout in frontend
  // (email attachment po pdf layout need update in backend)
  const { data } = props;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={'/assets/images/pdf-bg-1.jpg'} style={styles.backgroundImage} fixed />

        <View style={styles.headerFix} fixed>
          <PoHeader data={data} data_item={data?.data_item} pr_number={data?.pr_number} styles={styles} />
        </View>
        <View style={styles.container}>
          <PoSample data={data} data_item={data?.data_item} pr_number={data?.pr_number} styles={styles} />
        </View>
        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        /> */}
      </Page>
    </Document>
  );
}

function PdfDownloadButton(props) {
  const { data, print } = props;

  return (
    <PDFDownloadLink document={<PoPrintForm data={data} />} fileName={`PR (${data?.code}).pdf`}>
      {({ blob, url, loading, error }) => (
        <Button onClick={print} endIcon={<Print />} variant="outlined">
          {loading ? '載入中' : '列印'}
        </Button>
      )}
    </PDFDownloadLink>
  );
}

function PdfReview(props) {
  const { data } = props;

  return (
    <PDFViewer width="100%" height="850px">
      <PoPrintForm data={data} />
    </PDFViewer>
  );
}

export default withPrintForm(PdfReview);
