import React from 'react';
import { useRowToggle } from './hooks';
import Row from './components/row';
import { Divider, List, ListSubheader } from '@mui/material';

function RowToggle(props) {
  const { selectedOptions, notSelectedOptions, selectOption, deselectOption } = useRowToggle({ ...props });

  return (
    <div className="flex gap-4">
      <List
        subheader={<ListSubheader>已選</ListSubheader>}
        sx={{
          minWidth: 150
        }}
      >
        <Divider />
        {selectedOptions.map((option) => {
          return <Row onClick={() => deselectOption(option._id)} option={option} key={option._id} />;
        })}
      </List>
      <List
        subheader={<ListSubheader>未選</ListSubheader>}
        sx={{
          minWidth: 150
        }}
      >
        <Divider />
        {notSelectedOptions.map((option) => {
          return <Row onClick={() => selectOption(option._id)} option={option} key={option._id} />;
        })}
      </List>
    </div>
  );
}

export default RowToggle;
