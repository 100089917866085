import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withPage } from './with-page';
import { useFetch } from 'lib/fetch';
import { useCollectionConfig } from './use-collection-config';
import Notfound from 'components/pages/profile/not-found';

export const withProfilePage = (WrappedComponent) => {
  const WithPageComponent = withPage(WrappedComponent);
  return (props) => {
    const { collection, editMode } = props || {};
    const { _id } = useParams() || {};

    const pageData = useProfilePageData({ collection, _id });
    const collectionConfig = useCollectionConfig({ collection });

    const isEditMode = editMode === 'edit';
    const isCopyMode = editMode === 'copy';
    const isNewMode = editMode === 'new';
    const isAmendmentMode = editMode === 'amendment';

    if (!pageData || !collectionConfig) {
      return null;
    }
    const dataProps = {
      _id,
      mode: editMode,
      isEditMode,
      isCopyMode,
      isNewMode,
      isAmendmentMode,
      fetchPageData: pageData.refetch,
      schema: collectionConfig?.schema,
      data: pageData?.data,
      fieldsToDisplay: pageData?.fieldsToDisplay,
      metadata: pageData?.metadata,
      isLoading: pageData?.isLoading || collectionConfig?.isLoading
    };

    if (pageData?.fieldsToDisplay || isNewMode) {
      if (pageData.permission === false) {
        return <Notfound />;
      }
      if (WithPageComponent) {
        return <WithPageComponent {...props} {...dataProps} />;
      }
      return <WrappedComponent {...props} {...dataProps} />;
    }

    return null;
  };
};

export const useProfilePageData = ({ collection, _id }) => {
  const { fetch, result, status, isLoading } = useFetch();

  const fetchPageData = (collection, _id) => {
    if (collection && _id) {
      fetch('GET', `/api/collection/${collection}/get/${_id}`);
    }
  };
  const refetch = useCallback(() => {
    fetchPageData(collection, _id);
  }, [collection, _id]);

  useEffect(() => {
    fetchPageData(collection, _id);
  }, []);

  return {
    ...(result || {}),
    refetch,
    isLoading
  };
};
