import React from 'react';
import { Button } from '@mui/material';
import { TaskAlt } from '@mui/icons-material';
import { withApprovalButton } from './hooks';
import ApprovePopup from '../approve-popup';

function ApproveButton(props) {
  const { className, historyData, contractUserData, approveButtonState, _id, data } = props;
  return (
    <div className="self-center">
      <div className={`${className} flex justify-end items-center w-full`}>
        <Button variant="contained" color="info" startIcon={<TaskAlt />} onClick={approveButtonState.handlePopupOpen}>
          審批申請
        </Button>
      </div>
      <ApprovePopup
        historyData={historyData}
        contractUserData={contractUserData}
        _id={_id}
        data={data}
        {...approveButtonState}
      />
    </div>
  );
}

export default withApprovalButton(ApproveButton);
