export const withApprovalForm = (WrappedComponent) => {
  return (props) => {
    const { setAttachments } = props;
    const fileDraggerProps = {
      name: 'file',
      multiple: true,
      beforeUpload: () => false,
      onChange(info) {
        setAttachments(info.fileList);
      }
    };
    return <WrappedComponent {...props} fileDraggerProps={fileDraggerProps} />;
  };
};
