import React from 'react';
import { withRecordStatus } from './hooks';

function RecordStatus(props) {
  const { className, config } = props;
  return (
    <div className={`${className} flex flex-col justify-start`}>
      <div className="text-sm mb-1 ml-1">
        <label>申請狀況</label>
      </div>
      <div
        className={`flex min-w-[100px] h-10 px-2 items-center justify-center border-[1px] rounded ${config.className}`}
      >
        {config.title}
      </div>
    </div>
  );
}

export default withRecordStatus(RecordStatus);
