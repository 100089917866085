import React from 'react';
import { opStringMap } from './hooks';

import { Chip } from '@mui/material';

function Tag({ filter, onDelete }) {
  const { field, op, value } = filter;
  const pattern = /^\[\[(.*?)\]\]$/;
  const text = (pattern.test(value) ? value.match(pattern)?.[1] : value).replace('true', '是').replace('false', '否');
  const label = `${field} ${opStringMap[op]} ${text}`;
  return <Chip label={label} onDelete={onDelete}></Chip>;
}

export default Tag;
