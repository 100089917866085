import * as React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLogoutButton } from './hooks';
import { Logout } from '@mui/icons-material';

export default function LogoutButton() {
  const { handleClose, handleClickOpen, handleLogin, open } = useLogoutButton();

  return (
    <React.Fragment>
      <div
        className="cursor-pointer p-1 rounded w-8 h-8 text-center hover:bg-sky-100 [&>svg]:hover:fill-sky-700"
        onClick={handleClickOpen}
      >
        <Logout sx={{ width: '16px' }} className="" />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '.MuiPaper-root': { minWidth: '500px' } }}
      >
        <DialogTitle id="alert-dialog-title">登出</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">確定登出嗎？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleLogin} autoFocus variant="contained">
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
