import { createContext, useState } from 'react';
import InputBoolean from './boolean';
import InputText from './text';
import InputFile from './file';
import { isUndefined } from 'lodash';
import InputDate from './date';
import InputDateRange from './date-range';
import InputRadio from './radio';
import InputSelect from './select';
import Json from '../data-display/json';
import RowToggle from './row-toggle';
import InputRichTextEditor from './rich-text-editor';

export const useInputEdit = ({ config, value }) => {
  const { is_boolean, is_date, is_json, is_file, is_row_toggle, input_type, title } = config;

  let Component = null;
  const label = title;

  if (is_boolean) {
    Component = InputBoolean;
  } else if (is_file) {
    Component = InputFile;
  } else if (is_date) {
    Component = InputDate;
  } else if (input_type === 'radio') {
    Component = InputRadio;
  } else if (input_type === 'select') {
    Component = InputSelect;
  } else if (input_type === 'rich-text-editor') {
    Component = InputRichTextEditor;
  } else if (is_json) {
    Component = Json;
  } else if (is_row_toggle) {
    Component = RowToggle;
  } else {
    Component = InputText;
  }

  return {
    Component,
    label,
    value: !isUndefined(value) ? value : config?.default
  };
};

export const useErrorMessage = () => {};
