import { useEffect, useRef, useState } from 'react';

export const useInputRichTextEditor = ({ defaultValue, field, setInputs, config }) => {
  const [text, setText] = useState('');
  const isTouched = useRef(null);

  const { readonly } = config;

  useEffect(() => {
    if (defaultValue) {
      setText(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (readonly) return;
    setInputs((v) => {
      return {
        ...v,
        [field]: {
          value: text,
          touched: !!isTouched.current
        }
      };
    });
  }, [field, text, setInputs, readonly]);

  return {
    text,
    setText: (value) => {
      if (!isTouched.current) {
        isTouched.current = true;
      }
      setText(value);
    }
  };
};
