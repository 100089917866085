import { useEffect } from 'react';

export const withImpendingNumber = (WrappedComponent) => {
  return (props) => {
    const { value, setInputs } = props;
    const field = 'impending_number';

    useEffect(() => {
      return () => {
        setInputs((v) => ({
          ...v,
          [field]: value
            ? {
                value: '',
                touched: true
              }
            : undefined
        }));
      };
    }, [value]);

    return <WrappedComponent {...props} value={value} setInputs={setInputs} field={field} />;
  };
};
