import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />)(
  (props) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      border: '1px solid #dadde9',
      maxWidth: 'none'
    }
  })
);

export default function Tooltip({ children, ...props }) {
  return <HtmlTooltip {...props}>{children}</HtmlTooltip>;
}
