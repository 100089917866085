import { find, isEmpty, map, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withQuoteReferences = (WrappedComponent) => {
  return (props) => {
    const { _id, data, setInputs, inputs, userProfile } = props;

    const [files, setFiles] = useState({ quote_references: [] });
    const field = 'quote_references';
    const value = data?.[field];

    const currentUser = useAtomValue(user);

    const { record_status, created_by } = data || {};
    const readonly = useMemo(() => {
      if (record_status && record_status > 30) {
        return true;
      }

      if (created_by?._id === currentUser?._id || userProfile?.department === 'PD') {
        return false;
      }

      if (created_by?._id !== currentUser?._id) {
        return true;
      }

      return false;
    }, [record_status, currentUser, created_by, userProfile]);

    const schema = useMemo(() => {
      return {
        ...find(props.schema, { field }),
        readonly
      };
    }, [props.schema, readonly]);

    useEffect(() => {
      const { quote_references, ...rest } = files;
      setInputs((v) => {
        return {
          ...reduce(
            v,
            (prev, curr, key) => {
              if (!(key.startsWith(`$push.${field}`) || key.startsWith(`$pull.${field}`))) {
                prev[key] = curr;
              }
              return prev;
            },
            {}
          ),
          ...reduce(
            quote_references?.value || [],
            (prev, curr, index) => {
              prev[`$push.${field}[${index}]`] = {
                value: curr,
                touched: true
              };
              return prev;
            },
            {}
          ),
          ...rest
        };
      });
    }, [JSON.stringify(files), field]);

    return (
      <WrappedComponent
        {...props}
        defaultValue={value}
        schema={schema}
        setInputs={setFiles}
        field={field}
        readonly={readonly}
      />
    );
  };
};
