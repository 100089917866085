import { useFetch } from 'lib/fetch';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const withPrintForm = (WrappedComponent) => {
  return (props) => {
    const { _id } = props;
    const { data } = useFetchPrData(_id);

    const [isHide, setIsHide] = useState(true);
    setTimeout(() => setIsHide(false), 1000);
    if (isHide) return null;
    return <WrappedComponent {...props} data={data} />;
  };
};

export const useFetchPrData = (data_id) => {
  let { _id } = useParams();
  if (!_id && data_id) {
    _id = data_id;
  }
  const { fetch, result } = useFetch();
  useEffect(() => {
    fetch('GET', `/api/collection/pr/summary-form/${_id}`);
  }, [_id]);

  return {
    _id: _id,
    data: result?.data
  };
};
