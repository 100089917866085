import React, { useEffect } from 'react';
import { useDataEdit } from 'hooks/use-data-edit';
import { useTopNav } from 'components/common/top-nav/hooks';
import { redirect } from 'lib/location';
import { useEntityLogs } from 'components/common/entity-logs/hooks';

export const useProfile = ({ pageProps }) => {
  const { _id, mode, collection, metadata, isLoading, isEditMode, isCopyMode } = pageProps;
  const url = isEditMode
    ? `/api/collection/${collection}/edit/${_id}`
    : isCopyMode
    ? `/api/collection/${collection}/copy/${_id}`
    : `/api/collection/${collection}/new`;
  const { Component: EntityLogs, refetch: refetchLogs } = useEntityLogs({ _id });
  const { setTopNav } = useTopNav();
  const { data, config, controller, save, canSave } = useDataEdit({
    mode,
    value: pageProps,
    config: { editable: false, ...pageProps },
    url,
    refetch: () => {
      pageProps?.fetchPageData();
      refetchLogs();
    },
    success:
      mode === 'new' || mode === 'copy'
        ? (result) => {
            if (result && result?._id) {
              redirect(`/${collection}/profile/${result._id}`);
            }
          }
        : undefined
  });

  const { schema, fieldsToDisplay = [], isEdit } = config;
  const { setInputs } = controller;

  useEffect(() => {
    const copy = isEdit
      ? () => {
          redirect(`/${collection}/copy/${_id}`);
        }
      : null;
    setTopNav({ save, canSave, copy });
  }, [save, canSave, isEdit, _id]);

  useEffect(() => {
    setTopNav({ save, canSave });
  }, [save, canSave]);

  return {
    _id,
    data,
    config,
    controller,
    save,
    canSave,
    metadata,
    schema,
    fieldsToDisplay,
    setInputs,
    isLoading,
    EntityLogs
  };
};
